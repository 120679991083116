import { combineReducers } from "redux";
import user from './user';
import escuela from './escuela';

const selectedTeacherReducer = (selectedTeacher = null, action) => {
  if (action.type === "SELECTED_TEACHER") {
    return action.payload
  }
  return selectedTeacher
};

export default combineReducers({
  selectedTeacher: selectedTeacherReducer,
  user,
  escuela,
  
});
