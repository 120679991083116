import "./AltaCentroTrabajo.scss";
import { useState, useEffect } from "react";
import {
  getIdentificacionCentroTrabajo,
  getInfraestructuraCentroTrabajo,
} from "../../actions/escuela-action";
import bannerAltaCentroTrabajo from "../../images/bannerAltaCentroTrabajo.jpeg";
import moment from "moment";

const AltaCentroTrabajo = (props) => {
  const [idCentroTrabajo, setidCentroTrabajo] = useState({
    zona: "",
    nombre: "",
    cct: "",
    direccion: "",
    localidad: "",
    municipio: "",
    cp: "",
    telefono: "",
    fechaFundacion: "",
    correoElectronico: "",
    supervision: "",
    otro: "",
  });

  const {
    zona,
    nombre,
    cct,
    direccion,
    localidad,
    municipio,
    cp,
    telefono,
    fechaFundacion,
    correoElectronico,
    supervision,
    otro,
  } = idCentroTrabajo || {};

  const [infraestructuraCentroTrabajo, setInfraestructuraCentroTrabajo] =
    useState({
      cct: "",
      aulas: 0,
      talleres: 0,
      laboratorios: 0,
      redEscolar: 0,
      aulaMedios: 0,
      salaComputo: 0,
      edusat: 0,
      prefectura: 0,
      biblioteca: 0,
      trabajoSocial: 0,
      almacen: 0,
      canchas: 0,
      otros: "",
    });
  const [selectValues, setSelectValues] = useState({
    priMat: 0,
    segMat: "",
    terMat: "",
    priVes: 0,
    segVes: "",
    terVes: "",
  });
  const [inputValues, setInputValues] = useState({
    matPrimerH: 0,
    vespPrimerH: 0,
    matPrimerM: 0,
    vespPrimerM: 0,
    matSegundoH: 0,
    vespSegundoH: 0,
    matSegundoM: 0,
    vespSegundoM: 0,
    matTercerH: 0,
    vespTercerH: 0,
    matTercerM: 0,
    vespTercerM: 0,
  });

  const { priMat, segMat, terMat, priVes, segVes, terVes } = selectValues;
  const {
    matPrimerH,
    vespPrimerH,
    matPrimerM,
    vespPrimerM,
    matSegundoH,
    vespSegundoH,
    matSegundoM,
    vespSegundoM,
    matTercerH,
    vespTercerH,
    matTercerM,
    vespTercerM,
  } = inputValues;

  const [checkedValue, setCheckedValue] = useState({
    danza1: false,
    danza2: false,
    danza3: false,
    musica1: false,
    musica2: false,
    musica3: false,
    teatro1: false,
    teatro2: false,
    teatro3: false,
    visuales1: false,
    visuales2: false,
    visuales3: false,
  });

  useEffect(() => {
    setInfraestructuraCentroTrabajo(props.datosProfesores.infraestructura);
    setidCentroTrabajo(props.datosProfesores.identificacion);
    props.setisLoading(false);
    props.setIsImpresionButtonGrayedOut(false);
  }, []);

  const handleImprimir = () => {
    window.print();
  };

  const handleSelectValues = (e) => {
    const name = e.target.name;

    const value = parseInt(e.target.value);
    setSelectValues({ ...selectValues, [name]: value });
  };

  const handleInputValues = (e) => {
    const name = e.target.name;

    const value = parseInt(e.target.value);
    setInputValues({ ...inputValues, [name]: value });
  };

  const handleCheckboxValues = (e) => {
    const name = e.target.name;
    const checkedValue2 = e.target.checked;
    setCheckedValue({ ...checkedValue, [name]: checkedValue2 });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };


  const {
    cct: cctinfra,
    aulas,
    talleres,
    laboratorios,
    redEscolar,
    aulaMedios,
    salaComputo,
    edusat,
    prefectura,
    biblioteca,
    trabajoSocial,
    almacen,
    canchas,
    otros,
  } = infraestructuraCentroTrabajo || {};
  const handleIdCentroTrabajoValues = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setidCentroTrabajo({ ...idCentroTrabajo, [name]: value });
  };

  const { elaboro, autorizo, vistoBueno, dest } =
    props.datosProfesores?.identificacion || {};

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="tabla-alta-centro" id="tabla">
          <table className="tabla-alta-centro">
            <thead>
              <tr>
                <td colSpan={3}>
                  <img
                    alt="bannerCentroTrabajo"
                    src={bannerAltaCentroTrabajo}
                  />
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                {" "}
                <td>
                  <table className="tabla-alta-centro-1">
                    {/* style={{ width:"100", border:"1",margin:"30px"}}  */}
                    <th colSpan={2}>IDENTIFICACIÓN DEL CENTRO DE TRABAJO</th>

                    <tr>
                      <td>TÉCNICA</td>
                      <td>
                        <input
                          type="text"
                          name="zona"
                          value={zona}
                          onChange={handleIdCentroTrabajoValues}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>NOMBRE</td>
                      <td>
                        <input
                          type="text"
                          name="nombre"
                          value={nombre}
                          onChange={handleIdCentroTrabajoValues}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>C.C.T.</td>
                      <td>
                        <input
                          type="text"
                          name="cct"
                          value={cct}
                          onChange={handleIdCentroTrabajoValues}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>DIRECCIÓN</td>
                      <td>
                        {" "}
                        <input
                          type="text"
                          name="direccion"
                          value={direccion}
                          onChange={handleIdCentroTrabajoValues}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td>LOCALIDAD</td>
                      <td>
                        {" "}
                        <input
                          type="text"
                          name="localidad"
                          value={localidad}
                          onChange={handleIdCentroTrabajoValues}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td>MUNICIPIO</td>
                      <td>
                        <input
                          type="text"
                          name="municipio"
                          value={municipio}
                          onChange={handleIdCentroTrabajoValues}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>CODIGO POSTAL</td>
                      <td>
                        <input
                          type="text"
                          name="cp"
                          value={cp}
                          onChange={handleIdCentroTrabajoValues}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>TELÉFONO</td>
                      <td>
                        <input
                          type="text"
                          name="telefono"
                          value={telefono}
                          onChange={handleIdCentroTrabajoValues}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>FECHA DE FUNDACIÓN</td>
                      <td>
                        <input
                          type="text"
                          name="fechaFundacion"
                          value={fechaFundacion}
                          onChange={handleIdCentroTrabajoValues}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>CORREO ELÉCTRONICO</td>
                      <td>
                        <input
                          type="text"
                          name="correoElectronico"
                          value={correoElectronico}
                          onChange={handleIdCentroTrabajoValues}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>SUPERVISIÓN</td>
                      <td>
                        <input
                          type="text"
                          name="supervision"
                          value={supervision}
                          onChange={handleIdCentroTrabajoValues}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>OTRO</td>
                      <td>
                        <input
                          type="text"
                          name="otro"
                          value={otro}
                          onChange={handleIdCentroTrabajoValues}
                        />
                      </td>
                    </tr>
                  </table>

                  <table className="tabla-alta-centro-1">
                    <th colSpan={2}>INFRAESTRUCTURA</th>

                    <tr>
                      <td>AULAS</td>
                      <td>
                        <input type="text" name="aulas" value={aulas} />
                      </td>
                    </tr>
                    <tr>
                      <td>TALLERES</td>
                      <td>
                        <input type="text" name="talleres" value={talleres} />
                      </td>
                    </tr>
                    <tr>
                      <td>LABORATORIOS</td>
                      <td>
                        <input
                          type="text"
                          name="laboratorios"
                          value={laboratorios}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>RED ESCOLAR</td>
                      <td>
                        <input
                          type="text"
                          name="redEscolar"
                          value={redEscolar}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>AULA DE MEDIOS</td>
                      <td>
                        <input
                          type="text"
                          name="aulaMedios"
                          value={aulaMedios}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>SALA DE COMPUTO</td>
                      <td>
                        <input
                          type="text"
                          name="salaComputo"
                          value={salaComputo}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>EDUSAT</td>
                      <td>
                        <input type="text" name="prefectura" value={edusat} />
                      </td>
                    </tr>
                    <tr>
                      <td>PREFECTURA</td>
                      <td>
                        <input
                          type="text"
                          name="prefectura"
                          value={prefectura}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>BIBLIOTECA</td>
                      <td>
                        <input
                          type="text"
                          name="biblioteca"
                          value={biblioteca}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>TRABAJO SOCIAL</td>
                      <td>
                        <input
                          type="text"
                          name="trabajoSocial"
                          value={trabajoSocial}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>ALMACÉN</td>
                      <td>
                        <input type="text" name="almacen" value={almacen} />
                      </td>
                    </tr>
                    <tr>
                      <td>CANCHAS</td>
                      <td>
                        <input type="text" name="canchas" value={canchas} />
                      </td>
                    </tr>

                    <tr>
                      <td>OTRO</td>
                      <td>
                        <input type="text" name="otros" value={otros} />
                      </td>
                    </tr>
                  </table>

                  <table className="tabla-alta-centro-1">
                    <th>ESTRUCTURA OCUPACIONAL</th>
                    <th>MAT</th>
                    <th>VES</th>
                    <tr>
                      <td>DIRECTOR</td>
                      <td>
                        <input type="text" />
                      </td>
                      <td>
                        <input type="text" />
                      </td>
                    </tr>
                    <tr>
                      <td>SUBDIRECTOR DE GESTIÓN</td>
                      <td>
                        <input type="text" />
                      </td>
                      <td>
                        <input type="text" />
                      </td>
                    </tr>
                    <tr>
                      <td>COORD DE ACT ACADÉMICAS</td>
                      <td>
                        <input type="text" />
                      </td>
                      <td>
                        <input type="text" />
                      </td>
                    </tr>
                    <tr>
                      <td>COORD DE ACT TECNOLÓGICAS</td>
                      <td>
                        <input type="text" />
                      </td>
                      <td>
                        <input type="text" />
                      </td>
                    </tr>
                    <tr>
                      <td>MAESTRO DE ENSEÑANZA ACADÉMICA</td>
                      <td>
                        <input type="text" />
                      </td>
                      <td>
                        <input type="text" />
                      </td>
                    </tr>
                    <tr>
                      <td>MAESTRO DE INGLÉS</td>
                      <td>
                        <input type="text" />
                      </td>
                      <td>
                        <input type="text" />
                      </td>
                    </tr>
                    <tr>
                      <td>TECNOLOGÍAS</td>
                      <td>
                        <input type="text" />
                      </td>
                      <td>
                        <input type="text" />
                      </td>
                    </tr>
                    <tr>
                      <td>MAESTRO DE EDUC. FÍSICA</td>
                      <td>
                        <input type="text" />
                      </td>
                      <td>
                        <input type="text" />
                      </td>
                    </tr>
                    <tr>
                      <td>MAESTRO DE ARTÍSTICA</td>
                      <td>
                        <input type="text" />
                      </td>
                      <td>
                        <input type="text" />
                      </td>
                    </tr>
                    <tr>
                      <td>AULA DE MEDIOS</td>
                      <td>
                        <input type="text" />
                      </td>
                      <td>
                        <input type="text" />
                      </td>
                    </tr>
                    <tr>
                      <td>LECTURA Y ESCRITURA</td>
                      <td>
                        <input type="text" />
                      </td>
                      <td>
                        <input type="text" />
                      </td>
                    </tr>
                    <tr>
                      <td>MÉDICO ESCOLAR</td>
                      <td>
                        <input type="text" />
                      </td>
                      <td>
                        <input type="text" />
                      </td>
                    </tr>
                    <tr>
                      <td>PREFECTO</td>
                      <td>
                        <input type="text" />
                      </td>
                      <td>
                        <input type="text" />
                      </td>
                    </tr>
                    <tr>
                      <td>AYUDANTE DE LABORATORIO</td>
                      <td>
                        <input type="text" />
                      </td>
                      <td>
                        <input type="text" />
                      </td>
                    </tr>
                    <tr>
                      <td>TRABAJADOR SOCIAL</td>
                      <td>
                        <input type="text" />
                      </td>
                      <td>
                        <input type="text" />
                      </td>
                    </tr>
                    <tr>
                      <td>CONTRALOR</td>
                      <td>
                        <input type="text" />
                      </td>
                      <td>
                        <input type="text" />
                      </td>
                    </tr>

                    <tr>
                      <td>SECRETARIA DE APOYO</td>
                      <td>
                        <input type="text" />
                      </td>
                      <td>
                        <input type="text" />
                      </td>
                    </tr>
                    <tr>
                      <td>BIBLIOTECARIO</td>
                      <td>
                        <input type="text" />
                      </td>
                      <td>
                        <input type="text" />
                      </td>
                    </tr>
                    <tr>
                      <td>TÉCNICO DE MANTENIMIENTO</td>
                      <td>
                        <input type="text" />
                      </td>
                      <td>
                        <input type="text" />
                      </td>
                    </tr>
                    <tr>
                      <td>ASISTENTE DE ALMACÉN</td>
                      <td>
                        <input type="text" />
                      </td>
                      <td>
                        <input type="text" />
                      </td>
                    </tr>
                    <tr>
                      <td>INTENDENTE</td>
                      <td>
                        <input type="text" />
                      </td>
                      <td>
                        <input type="text" />
                      </td>
                    </tr>
                    <tr>
                      <td>VELADOR</td>
                      <td>
                        <input type="text" />
                      </td>
                      <td>
                        <input type="text" />
                      </td>
                    </tr>
                  </table>

                  <table className="tabla-alta-centro-1">
                    <thead>
                      <tr>
                        <th className="" rowSpan="3"></th>
                        <th className="" colSpan="16">
                          NÚMERO DE ALUMNOS POR SEXO, GRADO Y TURNO
                        </th>
                      </tr>
                      <tr className="tabla-alta-centro-1">
                        <th colSpan="4">PRIMER GRADO111111</th>
                        <th colSpan="4">SEGUNDO GRADO</th>
                        <th colSpan="4">TERCER GRADO</th>
                        <th colSpan="4">ALUMNOS ESCUELA</th>
                      </tr>
                      <tr>
                        <th className="">H</th>
                        <th className="">M</th>
                        <th className="" colSpan="2">
                          SUMA
                        </th>
                        <th className="">H</th>
                        <th className="">M</th>
                        <th className="" colSpan="2">
                          SUMA
                        </th>
                        <th className="">H</th>
                        <th className="">M</th>
                        <th className="" colSpan="2">
                          SUMA
                        </th>
                        <th className="">H</th>
                        <th className="">M</th>
                        <th className="" colSpan="2">
                          TOTAL
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>MATUTINO</td>
                        <td>
                          <input
                            name="matPrimerH"
                            onChange={handleInputValues}
                            type="number"
                          />
                        </td>
                        <td>
                          <input
                            name="matPrimerM"
                            onChange={handleInputValues}
                            type="number"
                          />
                        </td>
                        <td name="TotmatPrimerHmatPrimerM" colSpan="2">
                          {matPrimerH + matPrimerM}
                        </td>
                        <td>
                          <input
                            name="matSegundoH"
                            onChange={handleInputValues}
                            type="number"
                          />
                        </td>
                        <td>
                          <input
                            name="matSegundoM"
                            onChange={handleInputValues}
                            type="number"
                          />
                        </td>
                        <td name="TotmatSegundoHmatSegundoM" colSpan="2">
                          {matSegundoH + matSegundoM}
                        </td>
                        <td>
                          <input
                            name="matTercerH"
                            onChange={handleInputValues}
                            type="number"
                          />
                        </td>
                        <td>
                          <input
                            name="matTercerM"
                            onChange={handleInputValues}
                            type="number"
                          />
                        </td>
                        <td name="totmatTercerHmatTercerM" colSpan="2">
                          {matTercerH + matTercerM}
                        </td>
                        <td name="totMatHom">
                          {matPrimerH + matSegundoH + matTercerH}
                        </td>
                        <td name="totMatMuj">
                          {matPrimerM + matSegundoM + matTercerM}
                        </td>
                        <td name="totMatHomMuj" colSpan="2">
                          {matPrimerH +
                            matSegundoH +
                            matTercerH +
                            matPrimerM +
                            matSegundoM +
                            matTercerM}
                        </td>
                      </tr>
                      <tr>
                        <td name="">VESPERTINO</td>
                        <td>
                          <input
                            name="vespPrimerH"
                            onChange={handleInputValues}
                            type="number"
                          />
                        </td>
                        <td>
                          <input
                            name="vespPrimerM"
                            onChange={handleInputValues}
                            type="number"
                          />
                        </td>
                        <td name="TotvespPrimerHvespPrimerM" colSpan="2">
                          {vespPrimerH + vespPrimerM}
                        </td>
                        <td>
                          <input
                            name="vespSegundoH"
                            onChange={handleInputValues}
                            type="number"
                          />
                        </td>
                        <td>
                          <input
                            name="vespSegundoM"
                            onChange={handleInputValues}
                            type="number"
                          />
                        </td>
                        <td name="TotvespSegundoHvespSegundoM" colSpan="2">
                          {vespSegundoH + vespSegundoM}
                        </td>
                        <td>
                          <input
                            name="vespTercerH"
                            onChange={handleInputValues}
                            type="number"
                          />
                        </td>
                        <td>
                          <input
                            name="vespTercerM"
                            onChange={handleInputValues}
                            type="number"
                          />
                        </td>
                        <td name="totvespTercerHvespTercerM" colSpan="2">
                          {vespTercerH + vespTercerM}
                        </td>
                        <td name="totVesHom">
                          {vespPrimerH + vespSegundoH + vespTercerH}
                        </td>
                        <td name="totVesMuj">
                          {vespPrimerM + vespSegundoM + vespTercerM}
                        </td>
                        <td name="totVesHomMuj" colSpan="2">
                          {vespPrimerH +
                            vespSegundoH +
                            vespTercerH +
                            vespPrimerM +
                            vespSegundoM +
                            vespTercerM}
                        </td>
                      </tr>
                      <tr>
                        <td name="">TOTAL</td>
                        <td name="totPriMatVesHom">
                          {matPrimerH + vespPrimerH}
                        </td>
                        <td name="totPriMatVesMuj">
                          {matPrimerM + vespPrimerM}
                        </td>
                        <td name="totPriMatVesHomMuj" colSpan="2">
                          {matPrimerH + vespPrimerH + matPrimerM + vespPrimerM}
                        </td>
                        <td name="totSegMatVesHom">
                          {matSegundoH + vespSegundoH}
                        </td>
                        <td name="totSegMatVesMuj">
                          {matSegundoM + vespSegundoM}
                        </td>
                        <td name="totSegMatVesHomMuj" colSpan="2">
                          {matSegundoH +
                            vespSegundoH +
                            matSegundoM +
                            vespSegundoM}
                        </td>
                        <td name="totTerMatVesHom">
                          {matTercerH + vespTercerH}
                        </td>
                        <td name="totTerMatVesMuj">
                          {matTercerM + vespTercerM}
                        </td>
                        <td name="totTerMatVesHomMuj" colSpan="2">
                          {matTercerH + matTercerM + vespTercerH + vespTercerM}
                        </td>
                        <td name="totMatVesHom">
                          {matPrimerH +
                            vespPrimerH +
                            matSegundoH +
                            vespSegundoH +
                            matTercerH +
                            vespTercerH}
                        </td>
                        <td name="totMatVesMuj">
                          {matPrimerM +
                            matSegundoM +
                            matTercerM +
                            vespPrimerM +
                            vespSegundoM +
                            vespTercerM}
                        </td>
                        <td name="totHomMuj" colSpan="2">
                          {matPrimerH +
                            matSegundoH +
                            matTercerH +
                            matPrimerM +
                            matSegundoM +
                            matTercerM +
                            vespPrimerH +
                            vespSegundoH +
                            vespTercerH +
                            vespPrimerM +
                            vespSegundoM +
                            vespTercerM}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="tabla-alta-centro-1">
                    <thead>
                      <tr>
                        <th className="tg-0lax" rowSpan="2"></th>
                        <th colSpan={4} className="tg-0lax">
                          GRUPOS
                        </th>
                      </tr>
                      <tr>
                        <th className="tg-0lax">1ro</th>
                        <th className="tg-0lax">2do</th>
                        <th className="tg-0lax">3ro</th>
                        <th className="tg-0lax">TOTAL</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="tg-0lax">MATUTINO</td>
                        <td className="tg-0lax">
                          {" "}
                          <select
                            value={selectValues.priMat}
                            onChange={handleSelectValues}
                            name="priMat"
                            id="priMat"
                          >
                            <option value="">0</option>

                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                          </select>
                        </td>
                        <td className="tg-0lax">
                          {" "}
                          <select
                            value={selectValues.segMat}
                            onChange={handleSelectValues}
                            name="segMat"
                            id="2m"
                          >
                            <option value="0">0</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                          </select>
                        </td>
                        <td className="tg-0lax">
                          {" "}
                          <select
                            value={selectValues.terMat}
                            onChange={handleSelectValues}
                            name="terMat"
                            id="3m"
                          >
                            <option value="0">0</option>

                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                          </select>
                        </td>
                        <td className="tg-0lax">{priMat + segMat + terMat}</td>
                      </tr>
                      <tr>
                        <td className="tg-0lax">VESPERTINO</td>
                        <td className="tg-0lax">
                          {" "}
                          <select
                            value={selectValues.priVes}
                            onChange={handleSelectValues}
                            name="priVes"
                            id="1v"
                          >
                            <option value="0">0</option>

                            <option value={1}>1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                          </select>
                        </td>
                        <td className="tg-0lax">
                          {" "}
                          <select
                            value={selectValues.segVes}
                            onChange={handleSelectValues}
                            name="segVes"
                            id="2v"
                          >
                            <option value="0">0</option>

                            <option value="1">1</option>

                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                          </select>
                        </td>
                        <td className="tg-0lax">
                          {" "}
                          <select
                            value={selectValues.terVes}
                            onChange={handleSelectValues}
                            name="terVes"
                            id="3v"
                          >
                            <option value="0">0</option>

                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                          </select>
                        </td>
                        <td className="tg-0lax">{priVes + segVes + terVes} </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>{priMat + priVes}</td>
                        <td>{segMat + segVes}</td>
                        <td>{terMat + terVes}</td>
                        <td>
                          {priMat + priVes + segMat + segVes + terMat + terVes}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <table className="tabla-alta-centro-1">
                    <thead>
                      <tr>
                        <th className="tg-0lax">TECNOLOGÍAS</th>
                        <th className="tg-0lax">MATUTINO</th>
                        <th className="tg-0lax">VESPERTINO</th>
                        <th className="tg-0lax">TOTAL</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="tg-0lax">ADMINISTRACIÓN CONTABLE</td>
                        <td className="tg-0lax"></td>
                        <td className="tg-0lax"></td>
                        <td className="tg-0lax"></td>
                      </tr>
                      <tr>
                        <td className="tg-0lax">TURISMO</td>
                        <td className="tg-0lax"></td>
                        <td className="tg-0lax"></td>
                        <td className="tg-0lax"></td>
                      </tr>
                      <tr>
                        <td className="tg-0lax">OFIMÁTICA</td>
                        <td className="tg-0lax"></td>
                        <td className="tg-0lax"></td>
                        <td className="tg-0lax"></td>
                      </tr>
                      <tr>
                        <td className="tg-0lax">INFORMÁTICA</td>
                        <td className="tg-0lax"></td>
                        <td className="tg-0lax"></td>
                        <td className="tg-0lax"></td>
                      </tr>
                      <tr>
                        <td className="tg-0lax">AGRICULTURA</td>
                        <td className="tg-0lax"></td>
                        <td className="tg-0lax"></td>
                        <td className="tg-0lax"></td>
                      </tr>
                      <tr>
                        <td className="tg-0lax">
                          CONFECCIÓN DEL VESTIDO E INDUSTRIA T
                        </td>
                        <td className="tg-0lax"></td>
                        <td className="tg-0lax"></td>
                        <td className="tg-0lax"></td>
                      </tr>
                      <tr>
                        <td className="tg-0lax">TOTALES</td>
                        <td className="tg-0lax"></td>
                        <td className="tg-0lax"></td>
                        <td className="tg-0lax"></td>
                      </tr>
                    </tbody>
                  </table>

                  <table className="tabla-alta-centro-1">
                    <thead>
                      <tr>
                        <th colSpan="5">ARTÍSTICAS</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>GRADO</td>
                        <td>DANZA</td>
                        <td>MÚSICA</td>
                        <td>TEATRO</td>
                        <td>VISUALES</td>
                      </tr>
                      <tr>
                        <td>1</td>
                        <td>
                          <input
                            className="arte"
                            onChange={handleCheckboxValues}
                            type="checkbox"
                            id="danza1"
                            name="danza1"
                            checked={checkedValue.danza}
                          />
                          <label htmlFor="artisticas"></label>
                        </td>
                        <td>
                          {" "}
                          <input
                            className="arte"
                            onChange={handleCheckboxValues}
                            type="checkbox"
                            id="musica1"
                            name="musica1"
                            checked={checkedValue.musica}
                          />
                          <label htmlFor="artisticas"></label>
                        </td>
                        <td>
                          {" "}
                          <input
                            className="arte"
                            onChange={handleCheckboxValues}
                            type="checkbox"
                            id="teatro1"
                            name="teatro1"
                            checked={checkedValue.teatro}
                          />
                          <label htmlFor="artisticas"></label>
                        </td>
                        <td>
                          <input
                            className="arte"
                            onChange={handleCheckboxValues}
                            type="checkbox"
                            id="visuales1"
                            name="visuales1"
                            checked={checkedValue.visuales}
                          />
                          <label htmlFor="artisticas"></label>
                        </td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>
                          <input
                            className="arte"
                            onChange={handleCheckboxValues}
                            type="checkbox"
                            id="danza2"
                            name="danza2"
                            checked={checkedValue.danza}
                          />
                          <label htmlFor="artisticas"></label>
                        </td>
                        <td>
                          <input
                            className="arte"
                            onChange={handleCheckboxValues}
                            type="checkbox"
                            id="musica2"
                            name="musica2"
                            checked={checkedValue.musica}
                          />
                          <label htmlFor="artisticas"></label>
                        </td>
                        <td>
                          {" "}
                          <input
                            className="arte"
                            onChange={handleCheckboxValues}
                            type="checkbox"
                            id="teatro2"
                            name="teatro2"
                            checked={checkedValue.teatro}
                          />
                          <label htmlFor="artisticas"></label>
                        </td>
                        <td>
                          {" "}
                          <input
                            className="arte"
                            onChange={handleCheckboxValues}
                            type="checkbox"
                            id="visuales2"
                            name="visuales2"
                            checked={checkedValue.visuales}
                          />
                          <label htmlFor="artisticas"></label>
                        </td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>
                          <input
                            className="arte"
                            onChange={handleCheckboxValues}
                            type="checkbox"
                            id="danza3"
                            name="danza3"
                            checked={checkedValue.danza}
                          />
                          <label htmlFor="artisticas"></label>
                        </td>
                        <td>
                          <input
                            className="arte"
                            onChange={handleCheckboxValues}
                            type="checkbox"
                            id="musica3"
                            name="musica3"
                            checked={checkedValue.musica}
                          />
                          <label htmlFor="artisticas"></label>
                        </td>
                        <td>
                          {" "}
                          <input
                            className="arte"
                            onChange={handleCheckboxValues}
                            type="checkbox"
                            id="teatro3"
                            name="teatro3"
                            checked={checkedValue.teatro}
                          />
                          <label htmlFor="artisticas"></label>
                        </td>
                        <td>
                          {" "}
                          <input
                            className="arte"
                            onChange={handleCheckboxValues}
                            type="checkbox"
                            id="visuales3"
                            name="visuales3"
                            checked={checkedValue.visuales}
                          />
                          <label htmlFor="artisticas"></label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
            <tr>
              <td colSpan={3}>
                <table className="tabla-alta-centro-firmas">
                  <thead>
                    <tr>
                      <th colSpan={4}>Fecha de elaboración: {moment().lang("es").format('LL').toString()}.</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr height="60 px">
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>

                    <tr>
                      <td className="tg-0lax">{elaboro}</td>
                      <td className="tg-0lax">{autorizo}</td>
                      <td className="tg-0lax">{vistoBueno}</td>
                      <td className="tg-0lax">{dest}</td>
                    </tr>
                    <tr>
                      <td className="tg-0lax">ELABORÓ</td>
                      <td className="tg-0lax">AUTORIZÓ</td>
                      <td className="tg-0lax">VO.BO.</td>
                      <td className="tg-0lax">
                        DEPTO. DE SECUNDARIAS TÉCNICAS
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </table>
        </div>
      </form>
    </>
  );
};

export default AltaCentroTrabajo;
