import React from 'react';
import './home.scss';
import portada from "../../images/portada.jpg";
const Home = () => {
  return (
    <div className='home-wrapper'>
      <div className="card-body1 img_portada_c">
        <img className='portada img_portada_2'  alt='portada' src={portada} /> {/*Aqui se agrega la imagen*/}
      </div>      
     
      <div className="card card-outline-secondary my-4">     
      </div>

    </div>
  );
};

export default Home;


