import React, { useState, useEffect } from "react";
import {
  ConfigProvider,
  Form,
  Input,
  Button,
  Radio,
  Select,
  Col,
  Row,
  Divider,
  DatePicker,
  Modal,
  Tooltip,
  InputNumber,
} from "antd";
import RegExp from "./RegExp";
import {
  CheckOutlined,
  EditTwoTone,
  DeleteTwoTone,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import "antd/dist/antd.css";
import "./personal.scss";
import Asignaturas from "./Asignaturas";
import { toast } from "react-toastify";
import { validateFechas2, validateFechas } from "../../helpers";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import "moment/locale/es";
// import zhCN from 'antd/lib/locale/zh_CN';
//ROMR840825HMSDRB08
import locale from "antd/lib/locale/es_ES";
// import { getIncentivo } from "../../actions/escuela-action";
import ModalConfirm from "./ModalConfirm";
import plantillaPersonalEscuela from "../../images/PlantillaPersonalEscuela.jpeg";

moment.locale("en");

const Asignatura = (props) => {
  const [form] = Form.useForm();

  const {
    init,
    materias,
    puestos,
    grupos,
    horas,
    categorias,
    clavesCentroTrabajo,
    tipoLicencia,
    estadoCivil,
    motivos,
    gruposMap,
    altaProfesor,
    bajaProfesor,
    actualizarProfesor,
    datosProfesor = {},
    asignaturas = {},
    validaCURP,
    carreraMagisterial,
    zonas,
    subZonas,
    getSubZonas,
    forte,
    titulo,
    setDatosProfesor,
    quinquenio,
    incentivo,
  } = props;
  const [tipoLicenciaSelect, setTipoLicenciaSelect] = useState(
    datosProfesor.situacionLaboralTipo || 0
  );
  const [goceSueldo, setGoceSueldo] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [llevaAsignaturas, setllevaAsignaturas] = useState(
    datosProfesor?.llevaAsignaturas || false
  );
  const [llevaHoras, setllevaHoras] = useState(
    datosProfesor?.llevaHoras || false
  );
  const [formValues, setformValues] = useState({});
  const [altaFormValues, setAltaFormValues] = useState({
    // nombre: "",
    apellidoMaterno: "",
    apellidoPaterno: "",
    calle: "",
    cargo: "",
    categoria: "",
    claveCentroDeTrabajo: "",
    codigoFone: "",
    codigoPostal: "",
    colonia: "",
    curp: "",
    email: "",
    estado: "",
    forte: "",
    idCarreraMagisterial: "",
    idClaveZona: "",
    idClaveZonaCentroTrabajo: "",
    idEstadoCivil: 0,
    incentivo: "",
    ingresoCentroTrabajo: "",
    ingresoSep: "",
    ingresoTecnicas: "",
    lugarNacimiento: "",
    motivo: "",
    motivoClave: "",
    municipio: "",
    nivelAcademico: "",
    nombre: "",
    numExterior: "",
    numInterior: "",
    numeroHoras: "",
    numeroHorasClaves: "",
    numeroPlaza: "",
    observaciones: "",
    quinquenio: "",
    rfc: "",
    sexo: "",
    situacionLaboralTipo: "",
    subUnidad: "",
    telefono: "",
    titulo: "",
    turno: "",
    unidad: "",
  });
  const [claves, setClaves] = useState({ codigoFone: "07" });
  const [asignatura, setAsignatura] = useState({});

  const [totalHoras, setTotalHoras] = useState(
    datosProfesor.numeroHoras - 1 || 0
  );
  const totalHorasAgregadasDatosProfesor =
    (asignaturas?.materias &&
      JSON.parse(asignaturas?.materias).reduce(
        (a, { totalTurnos }) => a + totalTurnos,
        0
      )) ||
    0;
  const [totalHorasAgregadas, setTotalHorasAgregadas] = useState(
    totalHorasAgregadasDatosProfesor
  );

  const [materiasIdsAgregadas, setMateriasIdsAgregadas] = useState(
    (asignaturas?.materias &&
      JSON.parse(asignaturas?.materias).map((materia) => materia.asignatura)) ||
    []
  );

  const [isClavesButtonDisable, setIsClavesButtonDisable] = useState(true);

  const [resultadoClaves, setResultadoClaves] = useState(
    (datosProfesor?.clavesPago && JSON.parse(datosProfesor?.clavesPago)) || []
  );
  const [resultadoAsignaturas, setResultadoAsignaturas] = useState(
    (asignaturas?.materias && JSON.parse(asignaturas?.materias)) || []
  );
  // const [word, setWordValue] = useState("")
  const [isClavesEnabled, setIsClavesEnabled] = useState(true);
  const [isClavesButtonVisible, setIsClavesButtonVisible] = useState(0);
  const puestosNoValidos = [
    "COORDINADOR DE ACTIVIDADES ACADÉMICAS",
    "COORDINADOR DE ACTIVIDADES TECNOLÓGICAS",
  ];
  //props.n === "/alta"
  useEffect(() => {
    if (datosProfesor.situacionLaboralTipo)
      displayMenuLicencia({
        target: { value: datosProfesor.situacionLaboralTipo },
      });
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setIsClavesEnabled(props.path !== "/actualizaciones");
  }, []);

  //MODAL Dar de baja profesor
  const showDeleteModal = () => {
    setIsDeleteModalVisible(true);
  };

  const handleEnableClaves = () => {
    setIsClavesEnabled();
  };
  // const picker = document.getElementsByClassName("ant-picker-header-view");
  // picker[0].style.visibility='hidden'
  // const handleOk = () => {
  //   const { idClaveZonaCentroTrabajo } = datosProfesor;
  //   setIsModalVisible(false);
  //   bajaProfesor(datosProfesor.curp, openToast, idClaveZonaCentroTrabajo);
  //   setDatosProfesor({});
  // };

  // const handleCancel = () => {
  //   setIsModalVisible(false);
  // };
  const handleDeleteOk = () => {
    const { idClaveZonaCentroTrabajo } = datosProfesor;
    setIsDeleteModalVisible(false);
    bajaProfesor(datosProfesor.curp, datosProfesor.cargo, openToast, idClaveZonaCentroTrabajo);
    setDatosProfesor({});
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalVisible(false);
  };

  // Word or number input handlers
  const handleNumberInput = (e) => {
    const re = /^\d+$/;
    const { name, value } = e.target;

    if (value === "" || re.test(value)) {
      setAltaFormValues((prevState) => {
        return { ...prevState, [name]: value };
      });
    }
  };
  const handleWordInput = (e) => {
    const regExp = e.target.attributes.regExpType.value;
    const regExpTypes = {
      alphabet: /^[a-zÁÉÍÓÚáéíóúüñÑç,A-Z\s]*$/,
      alphabetNombre: /^[a-zÁÉÍÓÚáéíóúüñÑç,.A-Z\s]*$/,
      numbers: /^\d+$/,
      numAlphaCalleIntExt: /^[a-z/A-Z0-9\s]/,
      any: /^[a-zÁÉÍÓÚáéíóúüñÑç,A-Z0-9\s]*$/,
    };
    const { value, id } = e.target;

    const re = regExpTypes[regExp];

    if (!re.test(value)) {
      const editedText = value.slice(0, -1);
      form.setFieldsValue({ [id]: editedText });
    }
  };
  // ModalConfirmation functions

  const showModalConfirm = () => {
    setIsModalVisible(false);
  };

  const handleOkModalConfirm = () => {
    const { claveCentroDeTrabajo } = formValues;
    setIsModalVisible(false);
    enviarDatos(
      validateFechas2({
        ...formValues,
        claveCentroDeTrabajo: claveCentroDeTrabajo?.toString(),
      })
    );
  };

  const handleCancelModalConfirm = () => {
    setIsModalVisible(false);
  };

  const matutinoOptions = (name) =>
    grupos.map(({ nombre, idGrupo }) => (
      <Select.Option key={idGrupo} name={name} value={idGrupo}>
        {nombre}
      </Select.Option>
    ));

  const deleteClaveFinal = (id) =>
    setResultadoClaves(
      resultadoClaves.filter(({ resultado }) => resultado !== id)
    );

  let clavesFinalesImprimir = [];
  for (let x = 0; x < resultadoClaves.length; x = x + 2) {
    const nuevaClave = (
      <tr>
        <td>{resultadoClaves[x].numeroHorasClaves}</td>
        <td>{resultadoClaves[x].resultado}</td>
        <td>{resultadoClaves[x + 1]?.numeroHorasClaves}</td>
        <td>{resultadoClaves[x + 1]?.resultado}</td>
      </tr>
    );
    clavesFinalesImprimir = [...clavesFinalesImprimir, nuevaClave];
  }

  const colSize = props.path !== "/imprimir/personal" ? 24 : 16;

  const clavesFinales = resultadoClaves.map((resultadoClave) => {
    const {
      codigoFone,
      unidad,
      subUnidad,
      categoria,
      numeroPlaza,
      numeroHorasClaves,
      motivoClave,
      resultado,
    } = resultadoClave;
    return (
      <tr>
        <td>{codigoFone}</td>
        <td>{unidad}</td>
        <td>{subUnidad}</td>
        <td>{categoria}</td>

        <td>{numeroHorasClaves}</td>
        <td>{numeroPlaza}</td>
        <td>{motivoClave}</td>

        <td>{resultado}</td>
        {/* <td>
          {datosProfesor?.idProfesor && (
            <>
              {props.path !== "/imprimir/personal" && (
                <div
                  className="agregareditarbuttons"
                  onClick={() => editaClave(resultadoClave)}
                >
                  <EditTwoTone />
                  Editar
                </div>
              )}
            </>
          )}
        </td> */}

        {/* <td>
          {" "}
          {props.path !== "/imprimir/personal" && (
            <div
              className="agregareditarbuttons"
              onClick={() => deleteClaveFinal(resultado)}
            >
              <DeleteTwoTone twoToneColor="#eb2f96" />
              Borrar
            </div>
          )}
        </td> */}
      </tr>
    );
  });

  const editaClave = (rc) => {
    form.setFieldsValue(rc);
    setClaves(rc);
    setIsClavesEnabled(true);
    setIsClavesButtonVisible(1);
  };

  const editaAsignatura = (asignatura) => {
    setAsignatura({ loading: true });
    setTotalHorasAgregadas((prev) => prev - asignatura.totalTurnos);
    setTimeout(() => {
      setAsignatura(asignatura);
    }, 50);
  };

  const handleDeleteAsignatura = (resultadoAsignatura) => {
    const { asignatura, totalTurnos } = resultadoAsignatura;
    setMateriasIdsAgregadas(
      materiasIdsAgregadas.filter(
        (materiasIdsAgregada) =>
          materiasIdsAgregada !== resultadoAsignatura.asignatura
      )
    );
    setResultadoAsignaturas(
      resultadoAsignaturas.filter(
        (resultadoAsignatura) => resultadoAsignatura.asignatura !== asignatura
      )
    );
    setTotalHorasAgregadas((prev) => prev - totalTurnos);
  };

  const resultadosTotales = {
    GM: 0,
    GV: 0,
    TGMV: 0,
    THM: 0,
    THV: 0,
    THMV: 0,
    TH: 0,
  };

  const asignaturasFinales = resultadoAsignaturas.map(
    (resultadoAsignatura, index) => {
      const totalMat =
        (resultadoAsignatura["1oMat"]?.length || 0) +
        (resultadoAsignatura["2oMat"]?.length || 0) +
        (resultadoAsignatura["3oMat"]?.length || 0);
      const totalVesp =
        (resultadoAsignatura["1oVesp"]?.length || 0) +
        (resultadoAsignatura["2oVesp"]?.length || 0) +
        (resultadoAsignatura["3oVesp"]?.length || 0);

      resultadosTotales.GM = resultadosTotales.GM + totalMat;
      resultadosTotales.GV = resultadosTotales.GV + totalVesp;
      resultadosTotales.TGMV = resultadosTotales.TGMV + totalMat + totalVesp;
      resultadosTotales.THM =
        resultadosTotales.THM + resultadoAsignatura.matutino;
      resultadosTotales.THV =
        resultadosTotales.THV + resultadoAsignatura.vespertino;
      resultadosTotales.THMV =
        resultadosTotales.THMV +
        resultadoAsignatura.matutino +
        resultadoAsignatura.vespertino;

      resultadosTotales.TH =
        resultadosTotales.TH + resultadoAsignatura.totalTurnos;

      return (
        <tr>
          <td>
            {
              materias.find(
                (m) => m.idMateria === resultadoAsignatura.asignatura
              )?.nombre
            }
          </td>
          <td>
            {resultadoAsignatura["1oMat"]
              ?.map((primeroMatutino) => gruposMap[primeroMatutino])
              ?.toString()}
          </td>
          <td>
            {resultadoAsignatura["2oMat"]
              ?.map((segundoMatutino) => gruposMap[segundoMatutino])
              ?.toString()}
          </td>
          <td>
            {resultadoAsignatura["3oMat"]
              ?.map((terceroMatutino) => gruposMap[terceroMatutino])
              ?.toString()}
          </td>
          <td>
            {resultadoAsignatura["1oVesp"]
              ?.map((primeroVespertino) => gruposMap[primeroVespertino])
              ?.toString()}
          </td>
          <td>
            {resultadoAsignatura["2oVesp"]
              ?.map((segundoVespertino) => gruposMap[segundoVespertino])
              ?.toString()}
          </td>
          <td>
            {resultadoAsignatura["3oVesp"]
              ?.map((terceroVespertino) => gruposMap[terceroVespertino])
              ?.toString()}
          </td>
          <td>{totalMat}</td>
          <td>{totalVesp}</td>
          <td>{totalMat + totalVesp}</td>
          <td>{resultadoAsignatura.matutino}</td>
          <td>{resultadoAsignatura.vespertino}</td>
          <td>{resultadoAsignatura.totalTurnos}</td>
          {props.path !== "/imprimir/personal" ? (
            <>
              <td></td>
              {/* <td>
                {datosProfesor?.idProfesor && (
                  <div>
                    {props.path !== "/imprimir/personal" && (
                      <div
                        className="agregareditarbuttons"
                        onClick={() =>
                          editaAsignatura(resultadoAsignatura, index)
                        }
                      >
                        <EditTwoTone />
                        Editar
                      </div>
                    )}
                  </div>
                )}
              </td> */}
              <td></td>
              {/* <td>
                <div>
                  {props.path !== "/imprimir/personal" && (
                    <div
                      className="agregareditarbuttons"
                      onClick={() =>
                        handleDeleteAsignatura(resultadoAsignatura, index)
                      }
                    >
                      <DeleteTwoTone twoToneColor="#eb2f96" />
                      Borrar
                    </div>
                  )}
                </div>
              </td> */}
            </>
          ) : null}
        </tr>
      );
    }
  );
  const agregarClave = ({ agregaEnActualizar }) => {
    const { id } = claves;
    const datosForm = form.getFieldsValue();
    const {
      codigoFone = "07",
      unidad,
      subUnidad,
      categoria,
      numeroPlaza,
      numeroHorasClaves,
      motivoClave,
      turno,
    } = datosForm;

    const newClave = {
      codigoFone,
      unidad,
      subUnidad,
      categoria,
      numeroPlaza,
      numeroHorasClaves,
      motivoClave,
      turno,
      resultado: `${codigoFone}${unidad}${subUnidad}${categoria}${
        numeroHorasClaves || ""
        }0${numeroPlaza}`,
    };

    if (datosProfesor.idProfesor) {
      if (agregaEnActualizar) {
        setResultadoClaves((prev) => [...prev, newClave]);
        setIsClavesButtonDisable(true);
        form.setFieldsValue({
          unidad: "",
          subUnidad: "",
          categoria: "",
          numeroPlaza: "",
          numeroHorasClaves: "",
          motivoClave: "",
          turno: "",
        });
      } else {
        setResultadoClaves(
          resultadoClaves.map((resultadoClave) =>
            resultadoClave.id === id ? newClave : resultadoClave
          )
        );
        setIsClavesButtonDisable(true);
        form.setFieldsValue({
          unidad: "",
          subUnidad: "",
          categoria: "",
          numeroPlaza: "",
          numeroHorasClaves: "",
          motivoClave: "",
          turno: "",
        });
      }
    } else {
      setResultadoClaves((prev) => [...prev, newClave]);
      setIsClavesButtonDisable(true);
      form.setFieldsValue({
        unidad: "",
        subUnidad: "",
        categoria: "",
        numeroPlaza: "",
        numeroHorasClaves: "",
        motivoClave: "",
        turno: "",
      });
    }
  };

  const handlellevaAsignaturas = (v, w) => {
    console.log('winAsign571', w)
    const llevaAsignaturas = w.llevaAsignaturas;
    const llevaHoras = w.llevaHoras;
    setllevaAsignaturas(!!llevaAsignaturas);
    setllevaHoras(llevaHoras);
  };

  const asignaturaHorasAsignadas = () => {
    const asignaturaId = asignatura?.asignatura;

    const asignaturaValues = materias.find(
      (materia) => materia.idMateria === asignaturaId
    );

    const primerGrado =
      ((asignatura["1oMat"]?.length || 0) +
        (asignatura["1oVesp"]?.length || 0)) *
      asignaturaValues?.primerGrado;
    const segundoGrado =
      ((asignatura["2oMat"]?.length || 0) +
        (asignatura["2oVesp"]?.length || 0)) *
      asignaturaValues?.segundoGrado;
    const tercerGrado =
      ((asignatura["3oMat"]?.length || 0) +
        (asignatura["3oVesp"]?.length || 0)) *
      asignaturaValues?.tercerGrado;

    return { primerGrado, segundoGrado, tercerGrado };
  };

  const asignaturaHorasAsignadasTurnos = () => {
    const asignaturaId = asignatura?.asignatura;

    const asignaturaValues = materias.find(
      (materia) => materia.idMateria === asignaturaId
    );

    const matutino =
      (asignatura["1oMat"]?.length || 0) * asignaturaValues?.primerGrado +
      (asignatura["2oMat"]?.length || 0) * asignaturaValues?.segundoGrado +
      (asignatura["3oMat"]?.length || 0) * asignaturaValues?.tercerGrado;
    const vespertino =
      (asignatura["1oVesp"]?.length || 0) * asignaturaValues?.primerGrado +
      (asignatura["2oVesp"]?.length || 0) * asignaturaValues?.segundoGrado +
      (asignatura["3oVesp"]?.length || 0) * asignaturaValues?.tercerGrado;

    return { matutino, vespertino, totalTurnos: matutino + vespertino };
  };

  const { primerGrado, segundoGrado, tercerGrado } = asignaturaHorasAsignadas();

  const totalHorasAcumuladas = asignatura?.asignatura
    ? primerGrado + segundoGrado + tercerGrado
    : 0;

  const horasExcedidas =
    totalHoras - (totalHorasAcumuladas + totalHorasAgregadas);

  const agregaEditaAsignatura = () => {
    const { matutino, vespertino, totalTurnos } =
      asignaturaHorasAsignadasTurnos();

    if (datosProfesor?.idProfesor) {
      let updateAsignatura = [];
      if (asignatura.hasOwnProperty("id")) {
        updateAsignatura = resultadoAsignaturas.map((prevAsignatura) =>
          prevAsignatura.id === asignatura.id
            ? { ...asignatura, matutino, vespertino, totalTurnos }
            : prevAsignatura
        );
      } else {
        updateAsignatura = [
          ...resultadoAsignaturas,
          { id: uuidv4(), ...asignatura, matutino, vespertino, totalTurnos },
        ];
      }
      setResultadoAsignaturas(updateAsignatura);
    } else {
      setResultadoAsignaturas((prevAsignaturas) => [
        ...prevAsignaturas,
        { ...asignatura, matutino, vespertino, totalTurnos },
      ]);
      setMateriasIdsAgregadas((prev) => [...prev, asignatura.asignatura]);
    }
    setTotalHorasAgregadas((prev) => prev + totalHorasAcumuladas);
    setAsignatura({ asignatura: null });
  };

  const handleSetSubZonas = async (a, { key }) => {
    form.setFieldsValue({ subZona: "" });

    await getSubZonas(key);
  };

  // Creacion de asignaturas al escoger el grado y grupo 
  const handleSetAsignaturas = (nameAsignatura, a) => {

    setAsignatura((prevAsign) => {
      return { ...prevAsign, [nameAsignatura]: a };
    });
  };

  const handleAgregarClave = () => {
    form.setFieldsValue({
      unidad: "",
      subUnidad: "",
      categoria: "",
      numeroPlaza: "",
      numeroHorasClaves: "",
      motivoClave: "",
    });
    setIsClavesEnabled(true);
    setIsClavesButtonVisible(2);
  };
  const isAsignaturasButtonDisable =
    !(
      asignatura.asignatura &&
      (asignatura["1oMat"]?.length ||
        asignatura["2oMat"]?.length ||
        asignatura["3oMat"]?.length ||
        asignatura["1oVesp"]?.length ||
        asignatura["2oVesp"]?.length ||
        asignatura["3oVesp"]?.length)
    ) || totalHoras < totalHorasAcumuladas + totalHorasAgregadas;

  useEffect(() => {
    if (
      totalHorasAcumuladas &&
      totalHoras < totalHorasAcumuladas + totalHorasAgregadas
    ) {
      openToast("Horas Asignadas Excedidas");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalHorasAcumuladas]);

  const enviarDatos = (formValues2) => {

    const { cargo } = formValues2;
    const id_puesto = puestos.find(({ nombre }) => nombre === cargo).idPuesto;
    const clavesPago = resultadoClaves.map((data) => {
      const {
        categoria,
        numeroHorasClaves,
        motivoClave,
        numeroPlaza,
        turno,
        resultado,
        codigoFone,
        subUnidad,
        unidad,
      } = data;

      const myObject = new Map([
        ["id", uuidv4()],
        ["categoria", categoria],
        ["numeroHorasClaves", numeroHorasClaves],
        ["motivoClave", motivoClave],
        ["id_puesto", id_puesto],
        ["turno", turno],
        ["separador", 0],
        ["numeroPlaza", numeroPlaza],
        ["resultado", resultado],
        ["codigoFone", codigoFone],
        ["unidad", unidad],
        ["subUnidad", subUnidad],
      ]);

      return Object.fromEntries(myObject);
    });

    if (datosProfesor?.idProfesor) {
      actualizarProfesor(
        {
          situacionLaboral: "3,0",
          ...formValues2,
          idProfesor: datosProfesor.idProfesor,
          horasExcedidas: !puestosNoValidos.includes(formValues.cargo)
            ? horasExcedidas
            : 0,
          clavesPago,
          urlFoto: "/url/ejemplo/",
          urlFirma: "/url/ejemplo/",
        },
        resultadoAsignaturas
      ).then(({ error, info }) => {
        if (!error) {
          openToast("Datos Actualizados !");
        } else {
          openToast(info);
          // setIsSending(false);
        }
      });
      return null;
    }
    const agregadoIdResultadoAsignaturas = resultadoAsignaturas.map(
      (asignatura) => {
        return {
          id: uuidv4(),
          ...asignatura,
        };
      }
    );

    altaProfesor(
      {
        situacionLaboral: "3,0",
        ...formValues2,
        horasExcedidas: !puestosNoValidos.includes(formValues2.cargo)
          ? horasExcedidas
          : 0,
        clavesPago,
      },
      agregadoIdResultadoAsignaturas
    ).then((data) => {
      const { error, info } = data;
      if (!error) {
        openToast();
        resetData();
      } else {
        openToast(info || "Error al Enviar datos.");
      }
    });
  };

  const resetData = () => {
    // setResultadoClaves([]);
    // setResultadoAsignaturas([]);
    /// setFormValues({});
    // setTotalHoras(0);
    // setTotalHorasAgregadas(0);
    // setAsignatura({});
    /// setIsSending(false);
    // form.resetFields();
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  const openToast = (msg = "Datos enviados !") => {
    toast.dark(msg);
  };

  const validateCURP = async (curp) => {
    const data = await validaCURP(curp);

    if (data.idProfesor) {
      toast.dark("CURP ya existe !");
      // setCURPExist(true);
      return true;
    } else {
      // setCURPExist(false);
      return false;
    }
  };
  const onFinish = async (values) => {
    const trimProperties = [
      "apellidoPaterno",
      "apellidoMaterno",
      "colonia",
      "curp",
      "calle",
      "estado",
      "lugarNacimiento",
      "municipio",
      "nombre",
      "nivelAcademico",
      "numInterior",
      "numExterior",
      "observaciones",
      "rfc",
    ];
    const trimmedValues = Object.keys(values).reduce((acc, value) => {
      const acumulator = {
        ...acc,
        [value]: trimProperties.includes(value)
          ? values[value]?.trim()
          : values[value],
      };
      return acumulator;
    }, {});


    const existeCURP = datosProfesor?.idProfesor
      ? false
      : await validateCURP(trimmedValues.curp);
    if (existeCURP) return;

    if (
      !puestosNoValidos.includes(trimmedValues.cargo) &&
      totalHoras - (totalHorasAcumuladas + totalHorasAgregadas) > 0
    ) {
      setformValues(trimmedValues);
      setIsModalVisible(true);
    } else {
      enviarDatos(
        validateFechas2({
          ...trimmedValues,
          claveCentroDeTrabajo: trimmedValues.claveCentroDeTrabajo?.toString(),
        })
      );
    }
  };

  const onValuesChange = (fieldSelected, fieldsForm) => {

    const {
      unidad,
      subUnidad,
      categoria,
      numeroPlaza,
      numeroHorasClaves,
      motivoClave,
      numeroHoras,
      turno,
    } = fieldsForm;
    if (
      unidad &&
      subUnidad &&
      categoria &&
      numeroPlaza &&
      motivoClave &&
      turno
    ) {
      setIsClavesButtonDisable(false);
    } else {
      setIsClavesButtonDisable(true);
    }
    setTotalHoras(
      horas.find((h) => numeroHoras === h.idHoras)?.numeroHoras || 0
    );
  };

  const displayMenuLicencia = async ({ target }) => {
    const value = target.value || tipoLicencia;
    setTipoLicenciaSelect(value);
    const json = JSON.stringify([{ idTipoLicencia: value }]);
    const menuGoce = await axios.get(
      `https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/getLicencias?json=${json}`
    );
    setGoceSueldo(menuGoce.data);
  };

  const modoBotones = (type) => {
    switch (type) {
      case 1:
        return (
          <span
            id="check"
            className={isClavesButtonDisable ? "iconDisable" : " iconDisable"}
            onClick={isClavesButtonDisable ? () => { } : agregarClave}
          >
            <CheckOutlined />
          </span>
        );

      case 2:
        return (
          <div>
            <span
              className={isClavesButtonDisable ? "iconDisable" : " iconDisable"}
              onClick={
                isClavesButtonDisable
                  ? () => { }
                  : () => {
                    agregarClave({ agregaEnActualizar: true });
                  }
              }
            >
              Guardar Clave
            </span>
          </div>
        );
      default:
        return null;
    }
  };

  const editable = props.path === "/baja" || props.path === "/imprimir";
  return (
    <>
      {/* <img style={props.path === "/imprimir/personal" ? {padding:"0px 100px"}: {padding:"0px"}}src={plantillaPersonalEscuela} alt="" /> */}
      <div style={editable ? { pointerEvents: "none", opacity: "0.9" } : {}}>
        <Form
          className="formulario"
          onFinish={onFinish}
          initialValues={validateFechas(datosProfesor)}
          form={form}
          onValuesChange={onValuesChange}
        >
          <div className="titulo-header">
            Datos Personales:
            {props.path === "/imprimir/personal" && (
              <div className="num-pagina">Pag {props.pageNum}</div>
            )}
          </div>

          {/* justify="space-between" */}
          <Row justify={"space-between"}>
            <Col flex="400px">
              <Form.Item
                name="nombre"
                label="Nombre(s)"
                rules={[
                  { required: false, message: "Nombre del profesor" },
                  {
                    pattern: new RegExp(/^[a-záéíóúüñçA-Z]*$/i),
                    message: "No se aceptan números",
                  },
                ]}
              >
                <Input onChange={handleWordInput} regExpType="alphabetNombre" />
              </Form.Item>
            </Col>
            <Col flex="auto">
              <Form.Item
                name={"apellidoPaterno"}
                label="Apellido Paterno"
                rules={[{ required: false }]}
              >
                <Input onChange={handleWordInput} regExpType="alphabet" />
              </Form.Item>
            </Col>
            <Col flex="auto">
              <Form.Item
                name={"apellidoMaterno"}
                label="Apellido Materno"
                rules={[{ required: false }]}
              >
                <Input onChange={handleWordInput} regExpType="alphabet" />
              </Form.Item>
            </Col>
          </Row>{/* 
            <Row justify={"space-between"} > */}
          {/* <Col flex="230px">
              <Form.Item label="Sexo" name="sexo" rules={[{ required: false }]}>
                <Radio.Group name="sexo">
                  <Radio.Button key="Masculino" value="Masculino">
                    Hombre
                  </Radio.Button>
                  <Radio.Button key="Femenino" value="Femenino">
                    Mujer
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col> */}


          {/* <Col flex="">
              <Form.Item label="Sexo" name="sexo" rules={[{ required: false }]}>
                <Select placeholder="Selecciona una opción">
                  <Select.Option key="Masculino" value="Masculino">
                    Masculino
                  </Select.Option>
                  <Select.Option key="Femenino" value="Femenino">
                    Femenino
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col flex="250px">
              <Form.Item
                label="Estado Civil"
                name="idEstadoCivil"
                rules={[{ required: false }]}
              >
                <Select
                  placeholder="Selecciona una opción"
                  onChange={(e) =>
                    setAltaFormValues((prev) => {
                      return { ...prev, idEstadoCivil: e };
                    })
                  }
                >
                  {estadoCivil.map(({ idEstadoCivil, nombre }) => (
                    <Select.Option key={idEstadoCivil} value={idEstadoCivil}>
                      {nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col> 
          </Row>
          <Row justify={"space-between"}>
            <Col flex="250px">
              <Form.Item label="RFC" name="rfc" rules={[{ required: false }]}>
                <Input />
              </Form.Item>
            </Col>

            <Col flex="270px">
              <Form.Item label="CURP" name="curp" rules={[{ required: false }]}>
                <Input
                  style={
                    props.path === "/imprimir/personal"
                      ? { pointerEvents: "none", opacity: "0.9" }
                      : {}
                  }
                  onBlur={validateCURP}
                />
              </Form.Item>
            </Col>
            <Col flex="200px">
              <Form.Item
                name="telefono"
                label="Teléfono"
                rules={[
                  {
                    required: false,
                    message: "Por favor introducir número de teléfono!",
                  },
                ]}
              >
                <Input
                  onChange={handleWordInput}
                  regExpType="numbers"
                  placeholder="10 dígitos"
                  maxLength={10}
                />
              </Form.Item>
            </Col>
            <Col flex="400px">
              <Form.Item
                name="email"
                label="E-mail"
                rules={[
                  {
                    type: "email",
                    message: "Este no es un correo electrónico válido",
                  },
                  {
                    required: false,
                    message: "Por favor escribe un correo electronico!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col flex="500px">
              <Form.Item
                label="Lugar de Nacimiento"
                name="lugarNacimiento"
                rules={[{ required: false }]}
              >
                <Input
                  placeholder="Municipio, Estado"
                  onChange={handleWordInput}
                  regExpType="alphabet"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row justify={"space-between"}>
            <Col flex="400px">
              <Form.Item
                label="Calle:"
                name="calle"
                rules={[{ required: false }]}
              >
                <Input onChange={handleWordInput} regExpType="any" />
              </Form.Item>
            </Col>
            <Col flex="210px">
              <Form.Item
                label="Número Exterior"
                name="numExterior"
                rules={[{ required: false }]}
              >
                <Input
                  onChange={handleWordInput}
                  maxLength={4}
                  regExpType="numAlphaCalleIntExt"
                  placeholder="3, 7A, S/N"
                />
              </Form.Item>
            </Col>
            <Col flex="210px">
              <Form.Item label="Número Interior" name="numInterior">
                <Input
                  onChange={handleWordInput}
                  maxLength={3}
                  regExpType="numAlphaCalleIntExt"
                  placeholder="3, 7A, S/N"
                />
              </Form.Item>
            </Col>
            <Col flex="450px">
              <Form.Item
                label="Colonia"
                name="colonia"
                rules={[{ required: false }]}
              >
                <Input onChange={handleWordInput} regExpType="any" />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col flex="300px">
              <Form.Item
                label="Municipio"
                name="municipio"
                rules={[{ required: false }]}
              >
                <Input onChange={handleWordInput} regExpType="alphabet" />
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item
                label="Estado"
                name="estado"
                rules={[{ required: false }]}
              >
                <Input onChange={handleWordInput} regExpType="alphabet" />
              </Form.Item>
            </Col>
            <Col flex="200px">
              <Form.Item
                label="Código postal"
                name="codigoPostal"
                rules={[{ required: false }]}
              >
                <Input
                  onChange={handleWordInput}
                  regExpType="numbers"
                  maxLength={5}
                />
              </Form.Item>
            </Col>
          </Row>

          <div className="titulo-header ">Datos Laborales:</div>
//algoee123
          <Row justify="space-between">
            <Col flex="450px">
              <Form.Item name="cargo" label="Puesto:">
                <Select onChange={handlellevaAsignaturas}>
                  {puestos.map(
                    ({ idPuesto, nombre, llevaAsignaturas, llevaHoras }) => (
                      <Select.Option
                        key={idPuesto}
                        llevaAsignaturas={llevaAsignaturas}
                        llevaHoras={llevaHoras}
                        value={nombre}
                      >
                        {nombre}
                      </Select.Option>
                    )
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col flex="450px">
              <Form.Item name="idClaveZona" label="Zona Escolar :">
                <Select onChange={handleSetSubZonas}>
                  {zonas.map(({ idClaveZona, claveZona }) => (
                    <Select.Option key={idClaveZona} value={idClaveZona}>
                      Zona {idClaveZona}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col flex="450px">
              <Form.Item name="idClaveZonaCentroTrabajo" label="Escuela:">
                <Select>
                  {subZonas.map(({ idClaveZonaCentroTrabajo, etiqueta }) => (
                    <Select.Option
                      key={idClaveZonaCentroTrabajo}
                      value={idClaveZonaCentroTrabajo}
                    >
                      {etiqueta}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col flex="310px">
              <Form.Item
                label="Clave Centro de Trabajo"
                name="claveCentroDeTrabajo"
              >
                <Select mode="multiple" allowClear>
                  {clavesCentroTrabajo.map(({ idClave, clave }) => (
                    <Select.Option key={idClave} value={idClave}>
                      {clave}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col flex="190px">
              <Form.Item
                label="Número de horas"
                name="numeroHoras"
                rules={[{ required: false }]}
              >
                <Select>
                  {horas.map(({ idHoras, numeroHoras }) => (
                    <Select.Option
                      key={idHoras}
                      value={idHoras}
                    >{`${numeroHoras}`}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            
            <Col flex="800px">
              <Form.Item
                label="Nivel académico"
                name="nivelAcademico"
                rules={[{ required: false }]}
              >
                <Input onChange={handleWordInput} regExpType="alphabet" />
              </Form.Item>
            </Col>

            <Col span="10">
              <Form.Item label="Situación Laboral " name="situacionLaboralTipo">
                <Radio.Group
                  rules={[{ required: false }]}
                  onChange={displayMenuLicencia}
                >
                  {tipoLicencia.map(({ idTipoLicencia, descripcion }) => (
                    <Radio key={idTipoLicencia} value={idTipoLicencia}>
                      {descripcion}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col flex="240px">
              <Form.Item name="motivo" label="Motivo">
                <Select>
                  {motivos.map(({ idMotivo, descripcion }) => (
                    <Select.Option key={idMotivo} value={idMotivo}>
                      {descripcion}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span="10">
              {(tipoLicenciaSelect === 1 || tipoLicenciaSelect === 2) && (
                <Form.Item
                  label={
                    tipoLicenciaSelect === 1
                      ? "Licencia con Goce de Sueldo"
                      : "Licencia sin Goce de Sueldo "
                  }
                  name="situacionLaboral"
                  rules={[{ required: true }]}
                >
                  <Select
                    placeholder={
                      tipoLicenciaSelect === 1
                        ? "Con Goce de Sueldo"
                        : "Sin Goce de Sueldo "
                    }
                  >
                    {goceSueldo.map((obj) => (
                      <Select.Option
                        value={`${obj.idTipoLicencia},${obj.idLicencia}`}
                        key={obj.idLicencia}
                      >
                        {obj.descripcion}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </Col>
          </Row>

          <Row
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            justify={"space-between"}
          >
            
         
            
            <Col flex="auto">
              <ConfigProvider locale={locale}>
                <Form.Item
                  name="ingresoSep"
                  label="Ingreso a la SEP"
                  rules={[
                    {
                      required: false,
                      message: "Por favor introducir ING SEP!",
                    },
                  ]}
                >
                  <DatePicker placeholder="DD/MM/AAAA" format="DD/MM/YYYY" />
                </Form.Item>
              </ConfigProvider>
            </Col>
            <Tooltip title="Selecciona Año=>Mes=>Dia">
              <QuestionCircleOutlined />
            </Tooltip>
            <Col flex="auto">
              <ConfigProvider locale={locale}>
                <Form.Item
                  name="ingresoTecnicas"
                  label="Ingreso a técnicas"
                  rules={[
                    {
                      required: false,
                      message: "Por favor introducir INGR TEC!",
                    },
                  ]}
                >
                  <DatePicker format="DD/MM/YYYY" placeholder="Año>Mes>Dia" />
                </Form.Item>
              </ConfigProvider>
            </Col>
            <Col flex="auto">
              <ConfigProvider locale={locale}>
                <Form.Item
                  name="ingresoCentroTrabajo"
                  label="Ingreso al CT"
                  rules={[
                    {
                      required: false,
                      message: "Por favor introducir ING CT!",
                    },
                  ]}
                >
                  <DatePicker format="DD/MM/YYYY" placeholder="DD/MM/AAAA" />
                </Form.Item>
              </ConfigProvider>
            </Col>
            <Col flex="230px">
              <Form.Item
                name="quinquenio"
                label="Quinquenio:"
                rules={[
                  {
                    required: false,
                    message: "Por favor introducir Quinquenio!",
                  },
                ]}
              >
                <Select placeholder="Selecciona una opción">
                  {quinquenio.map(({ idQuinquenio, quinquenio }) => (
                    <Select.Option key={idQuinquenio} value={idQuinquenio}>
                      {quinquenio}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col flex="210px">
              <Form.Item
                name="incentivo"
                label="Incentivo"
                rules={[
                  {
                    required: false,
                    message: "Por favor introducir Nivel de CM incentivo!",
                  },
                ]}
              >
                <Select>
                  {incentivo.map(({ idIncentivo, incentivo }) => (
                    <Select.Option
                      key={idIncentivo}
                      value={idIncentivo}
                      name={idIncentivo}
                    >
                      {incentivo}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            
            
            <Col flex="210px">
              <Form.Item
                name="titulo"
                label="Título:"
                rules={[
                  { required: false, message: "Por favor introducir Tit!" },
                ]}
              >
                <Select>
                  {titulo.map(({ idTitulo, descripcion }) => (
                    <Select.Option key={idTitulo} value={idTitulo}>
                      {descripcion}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col flex="210px">
              <Form.Item
                name="forte"
            w    label="Forte:"
                rules={[
                  { required: false, message: "Por favor introducir Forte!" },
                ]}
              >
                <Select>
                  {forte.map(({ idForte, descripcion }) => (
                    <Select.Option key={idForte} value={idForte}>
                      {descripcion}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col flex="300px">
              <Form.Item
                name="idCarreraMagisterial"
                label="Nivel Carrera Magisterial:"
                rules={[
                  {
                    required: false,
                    message: "Por favor introducir Nivel Carrera Magisterial!",
                  },
                ]}
              >
                
                
                <Select>
                  {carreraMagisterial.map(({ idCarreraMagisterial, valor }) => (
                    <Select.Option
                      key={idCarreraMagisterial}
                      value={idCarreraMagisterial}
                    >
                      {valor}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col flex="1000px">
              <Form.Item
                name="observaciones"
                label="Observaciones:"
                rules={[
                  { required: false, message: "Por favor introducir OBS!" },
                ]}
              >
                <Input.TextArea />
              </Form.Item>
            </Col>
          </Row>

          <div
            style={
              props.path === "/baja" || props.path === "/busqueda"
                ? { display: "none" }
                : {}
            }
          >
            {llevaAsignaturas && (
              <>
                <div className="titulo-header">Asignaturas:</div>
                <div>
                  <Divider orientation="middle">
                    {`Horas Asignadas (${totalHoras}) - Restantes(${
                      totalHoras - (totalHorasAcumuladas + totalHorasAgregadas)
                    })`}
                  </Divider>

                  <div>
                   
                    
                  </div>
                </div>
                
             
              </>
            )}
          </div>*/}
          <Row>
            {llevaAsignaturas && (
              <Divider orientation="left">Asignaturas agregadas:</Divider>
            )}

            {
              <Col
                span={colSize}
                style={{
                  visibility: resultadoAsignaturas.length === 0 && "hidden",
                }}
              >
                <table width="100%">
                  <thead>
                    <tr>
                      <th>Asignatura</th>
                      <th>1o M</th>
                      <th>2o M</th>
                      <th>3o M</th>
                      <th>1o V</th>
                      <th>2o V</th>
                      <th>3o V</th>
                      <th>GM</th>
                      <th>GV</th>
                      <th>GMV</th>
                      <th>HM</th>
                      <th>HV</th>
                      <th>HMV</th>
                      <th></th>
                      {props.path !== "/imprimir/personal" ? (
                        <>
                          <th></th>
                          <th></th>
                          <th></th>
                        </>
                      ) : null}
                    </tr>
                  </thead>
                  <tbody>{asignaturasFinales}</tbody>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th className="totales-totales">TGM</th>
                    <th className="totales-totales">TGV</th>
                    <th className="totales-totales">TGMV</th>
                    <th className="totales-totales">THM</th>
                    <th className="totales-totales">THV</th>
                    <th className="totales-totales">THMV</th>
                  </tr>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th className="totales-totales">{resultadosTotales.GM}</th>
                    <th className="totales-totales">{resultadosTotales.GV}</th>
                    <th className="totales-totales">
                      {resultadosTotales.TGMV}
                    </th>
                    <th className="totales-totales">{resultadosTotales.THM}</th>
                    <th className="totales-totales">{resultadosTotales.THV}</th>
                    <th className="totales-totales">
                      {resultadosTotales.THMV}
                    </th>
                  </tr>
                </table>{" "}
              </Col>
            }
            {props.path !== "/imprimir/personal" ? null : (
              <Col>
                <table>
                  <thead>
                    <tr>
                      <th>HORAS</th>
                      <th>CLAVE</th>
                      <th>HORAS</th>
                      <th>CLAVE</th>
                    </tr>
                  </thead>
                  <tbody>{clavesFinalesImprimir}</tbody>
                </table>
              </Col>
            )}
            {props.path !== "/imprimir/personal" ? null : (
              <Row>
                <Col>
                  <table className="tabla-firma">
                    <thead>
                    </thead>
                    <tbody>
                      <tr>
                      </tr>
                      <tr>
                        <td>FIRMA</td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
            )}
          </Row>
          {/*
          {props.path !== "/imprimir/personal" &&
          props.path !== "/baja" &&
          props.path !== "/busqueda" ? (
            <div>
              <div className="titulo-header">Claves:</div>
              <Row justify="space-between">
                <Col span={23}>
                  <div
                    style={
                      isClavesEnabled
                        ? {}
                        : { pointerEvents: "none", opacity: "0.4" }
                    }
                  >
                    <Row justify="space-between">
                      <Col flex="200px">
                        <Form.Item name="codigoFone" label="Código Fone">
                          <Input defaultValue="07" disabled />
                        </Form.Item>
                      </Col>
                      <Col flex="200px">
                        <Form.Item name="unidad" label="Unidad">
                          <Input
                            onChange={handleWordInput}
                            regExpType="numbers"
                          />
                        </Form.Item>
                      </Col>
                      <Col flex="200px">
                        <Form.Item name="subUnidad" label="Sub Unidad">
                          <Input
                            name="subUnidad"
                            onChange={handleWordInput}
                            regExpType="numbers"
                          />
                        </Form.Item>
                      </Col>
                      <Col flex="220px">
                        <Form.Item name="categoria" label="Puesto/Función">
                          <Select>
                            {categorias.map(({ idCategoria, categoria }) => (
                              <Select.Option
                                key={idCategoria}
                                value={categoria}
                              >
                                {categoria}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row justify="space-between">
                      <Col flex="200px">
                        <Form.Item
                          name="numeroHorasClaves"
                          label="Número de Horas"
                        >
                          <Input
                            onChange={handleWordInput}
                            regExpType="numbers"
                          />
                        </Form.Item>
                      </Col>
                      <Col flex="200px">
                        <Form.Item name="numeroPlaza" label="Número de Plaza">
                          <Input
                            name="numeroPlaza"
                            onChange={handleWordInput}
                            regExpType="numbers"
                          />
                        </Form.Item>
                      </Col>

                      <Col flex="150px">
                        <Form.Item name="motivoClave" label="Motivo">
                          <Select>
                            {motivos.map(({ idMotivo, motivo }) => (
                              <Select.Option key={idMotivo} value={motivo}>
                                {motivo}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col flex="250px">
                        <Form.Item name="turno" label="Turno">
                          <Radio.Group>
                            <Radio.Button key="ma" value="m">
                              Matutino
                            </Radio.Button>
                            <Radio.Button key="ve" value="v">
                              Vespertino
                            </Radio.Button>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      {datosProfesor?.idProfesor ? (
                        <>{modoBotones(isClavesButtonVisible)} </>
                      ) : (
                        <div>
                          <span
                            className={
                              isClavesButtonDisable
                                ? "iconDisable"
                                : " iconEnable"
                            }
                            onClick={
                              isClavesButtonDisable ? () => {} : agregarClave
                            }
                          >
                            {datosProfesor?.idProfesor ? (
                              <CheckOutlined />
                            ) : (
                              "Guardar Clave"
                            )}
                          </span>
                        </div>
                      )}
                      <Col span={8}></Col>
                    </Row>
                  </div>
                </Col>

                
                  
                
                
              </Row>
            </div>
          ) : null}*/}

          {/* {datosProfesor.idProfesor && props.path !== "/imprimir/personal" ? (
            <div class="button button1" onClick={handleAgregarClave}>
              Agregar Clave
            </div>
          ) : null} */}
          {resultadoClaves.length > 0 && (
            <div>
              {props.path !== "/imprimir/personal" ? (
                <>
                  <Divider orientation="left">Claves agregadas:</Divider>

                  <Row>
                    <table width="100%">
                      <thead>
                        <tr>
                          {props.path !== "/imprimir/personal" ? (
                            <>
                              <th>Codigo Fone</th>
                              <th>Unidad</th>
                              <th>Sub Unidad</th>
                              <th>Categoria</th>
                            </>
                          ) : null}

                          <th>Numero de Horas</th>
                          <th>Numero de Plaza</th>
                          {props.path !== "/imprimir/personal" ? (
                            <>
                              {" "}
                              <th>Motivo</th>
                            </>
                          ) : null}

                          <th>Clave Final</th>
                          <th></th>

                          <th></th>
                        </tr>
                      </thead>
                      <tbody>{clavesFinales}</tbody>
                    </table>
                  </Row>
                </>
              ) : null}
            </div>
          )}
          <br />
          <br />
          <Row justify="center">

            <Col span={1}>
              <Form.Item
                style={
                  props.path === "/baja" || props.path === "/busqueda"
                    ? { display: "none" }
                    : {}
                }
              >
                {/*                 
                {props.path !== "/imprimir/personal" ? (
                  <Button
                    disabled={clavesFinales.length >= 1 ? false : true}
                    htmlType="submit"
                  >
                    {datosProfesor?.idProfesor ? "Actualizar" : "Enviar"}
                  </Button>
                ) : null} */}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      {props.path === "/baja" && (
        <Button type="primary" danger="true" onClick={showDeleteModal}>
          Eliminar
        </Button>
      )}
      <Modal
        title="Confirmación de baja"
        visible={isDeleteModalVisible}
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
      >
        <p>
          Al presionar Aceptar se eliminará permanentemente el trabajador
          elegido.¿Estás seguro de querer eliminarlo?{" "}
        </p>
      </Modal>
      <ModalConfirm
        horasRestantes={
          totalHoras - (totalHorasAcumuladas + totalHorasAgregadas)
        }
        modalVisible={isModalVisible}
        handleOkMC={handleOkModalConfirm}
        handleCancelMC={handleCancelModalConfirm}
        showMC={showModalConfirm}
      />
    </>
  );
};

export default Asignatura;
