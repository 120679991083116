import axios from "axios";
import { toast } from "react-toastify";

import {
  SET_MATERIAS,
  SET_PUESTOS,
  SET_TURNOS,
  SET_GRUPOS,
  SET_TIPO_LICENCIA,
  SET_HORAS,
  SET_CATEGORIAS,
  SET_CLAVESCENTROTRABAJO,
  SET_MOTIVOS,
  SET_ESTADO_CIVIL,
  SET_NUMERO_ESCUELA,
  SET_CARRERA_MAGISTERIAL,
  SET_ZONAS,
  SET_FORTE,
  SET_TITULO,
  SET_ASIGNATURA_HORAS,
  SET_QUINQUENIO,
  SET_INCENTIVO,
  GET_IDENTIFICACION_CENTRO_TRABAJO,
  SET_SUB_ZONAS,
  SET_ASIGNATURA_NOMBRAMIENTO,
  SET_ARTES,
  SET_TALLERES,
  SET_TURNOS_LIST,
} from "../constants/action-types";


const userData = sessionStorage.getItem("userAccessData");
const userAccessDataObj = JSON.parse(userData)
  // console.log('userAccessDataRG', userAccessDataObj)
  // const {idClaveZona,escuela} = userAccessDataObj;
   const userIdClaveZonasCentroTrabajo = userAccessDataObj?.idClaveZonaCentroTrabajo;

const setMaterias = (materias) => ({
  type: SET_MATERIAS,
  materias,
});

const setPuestos = (puestos) => ({
  type: SET_PUESTOS,
  puestos,
});


const setAsignaturaNombramiento = (asignaturaNombramientos) => ({
  type: SET_ASIGNATURA_NOMBRAMIENTO,
  asignaturaNombramientos,
});

const setArtes = (artes) => ({
  type: SET_ARTES,
  artes,
});

const setTurnosList = (turnosList) => ({
  type: SET_TURNOS_LIST,
  turnosList,
});

const setTurnos = (turnos) => ({
  type: SET_TURNOS,
  turnos,
});

const setGrupos = (grupos) => ({
  type: SET_GRUPOS,
  grupos,
});

const setHoras = (horas) => ({
  type: SET_HORAS,
  horas,
});

const setTipoLicencia = (tipoLicencia) => ({
  type: SET_TIPO_LICENCIA,
  tipoLicencia,
});

const setEstadoCivil = (estadoCivil) => ({
  type: SET_ESTADO_CIVIL,
  estadoCivil,
});

const setCategorias = (categorias) => ({
  type: SET_CATEGORIAS,
  categorias,
});

const setMotivos = (motivos) => ({
  type: SET_MOTIVOS,
  motivos,
});

const setNumeroEscuela = (numeroEscuela) => ({
  type: SET_NUMERO_ESCUELA,
  numeroEscuela,
});

const setCarreraMagisterial = (carreraMagisterial) => ({
  type: SET_CARRERA_MAGISTERIAL,
  carreraMagisterial,
});

const setClaveCentroTrabajo = (clavesCentroTrabajo) => ({
  type: SET_CLAVESCENTROTRABAJO,
  clavesCentroTrabajo,
});

const setZonas = (zonas) => ({
  type: SET_ZONAS,
  zonas,
});

const setSubZonas = (subZonas) => ({
  type: SET_SUB_ZONAS,
  subZonas,
});

const setForte = (forte) => ({
  type: SET_FORTE,
  forte,
});

const setAsignaturaHoras = (asignaturaHoras) => ({
  type: SET_ASIGNATURA_HORAS,
  asignaturaHoras,
});

const setTitulo = (titulo) => ({
  type: SET_TITULO,
  titulo,
});
const setQuinquenio = (quinquenio) => ({
  type: SET_QUINQUENIO,
  quinquenio,
});
const setIncentivo = (incentivo) => ({
  type: SET_INCENTIVO,
  incentivo,
});

export const getMaterias = () => async (dispatch, getState) => {
  const {
    escuela: { materias },
  } = getState();
  if (materias.length > 0) return dispatch(setMaterias(materias));

  try {
    const prueba = await axios.get(
      "https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/getMaterias"
    );

    dispatch(setMaterias(prueba.data));
  } catch (error) {
    console.log("error-getMaterias", error);
  }
};

export const getPuestos = () => async (dispatch, getState) => {
  const {
    escuela: { puestos },
  } = getState();
  if (puestos.length > 0) return dispatch(setPuestos(puestos));

  try {
    const { data } = await axios.get(
      "https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/getPuestos"
    );

    dispatch(setPuestos(data));
  } catch (error) {
    console.log("error-getPuestos", error);
  }
};

export const getAsignaturaNombramiento = () => async (dispatch, ) => {

  try {
    const { data } = await axios.get(
      "https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/getAsignaturaNombramiento"
    );

    dispatch(setAsignaturaNombramiento(data));
  } catch (error) {
    console.log("error-getAsignaturaNombramiento", error);
  }
};

export const getArtes = () => async (dispatch, ) => {

  try {
    const { data } = await axios.get(
      "https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/getArtes"
    );

    dispatch(setArtes(data));
  } catch (error) {
    console.log("error-getArtes", error);
  }
};

export const getTurnos = () => async (dispatch, ) => {

  try {
    const { data } = await axios.get(
      "https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/getTurno"
    );

    dispatch(setTurnosList(data));
  } catch (error) {
    console.log("error-getTurnos", error);
  }
};

export const getQuinquenio = () => async (dispatch, getState) => {
  const {
    escuela: { quinquenio },
  } = getState();
  if (quinquenio.length > 0) return dispatch(setQuinquenio(quinquenio));

  try {
    const { data } = await axios.get(
      "https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/getQuinquenio"
    );
    dispatch(setQuinquenio(data));
  } catch (error) {
    console.log("error-getQuinquenio", error);
  }
};

export const getIncentivo = () => async (dispatch, getState) => {
  const {
    escuela: { incentivo },
  } = getState();
  if (incentivo.length > 0) return dispatch(setIncentivo(incentivo));

  try {
    const { data } = await axios.get(
      "https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/getIncentivo"
    );
    dispatch(setIncentivo(data));
  } catch (error) {
    console.log(`error-getIncentivo`, error);
  }
};

export const getTurno = () => async (dispatch, getState) => {
  const {
    escuela: { turnos },
  } = getState();
  if (turnos.length > 0) return dispatch(setTurnos(turnos));

  try {
    const { data } = await axios.get(
      "https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/getTurnos"
    );

    dispatch(setTurnos(data));
  } catch (error) {
    console.log("error-getTurno", error);
  }
};

export const getGrupos = () => async (dispatch, getState) => {
  const {
    escuela: { grupos },
  } = getState();
  if (grupos.length > 0) return dispatch(setGrupos(grupos));

  try {
    const { data } = await axios.get(
      "https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/getGrupos"
    );

    dispatch(setGrupos(data));
  } catch (error) {
    console.log("error-getGrupos", error);
  }
};

export const getCategorias = () => async (dispatch, getState) => {
  const {
    escuela: { categorias },
  } = getState();
  if (categorias.length > 0) return dispatch(setCategorias(categorias));

  try {
    const { data } = await axios.get(
      "https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/getCategoria"
    );

    dispatch(setCategorias(data));
  } catch (error) {
    console.log("error-getCategorias", error);
  }
};

export const getMotivos = () => async (dispatch, getState) => {
  const {
    escuela: { motivos },
  } = getState();
  if (motivos.length > 0) return dispatch(setMotivos(motivos));

  try {
    const { data } = await axios.get(
      "https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/getMotivo"
    );

    dispatch(setMotivos(data));
  } catch (error) {
    console.log("error-getCategorias", error);
  }
};

export const getNumeroEscuela = () => async (dispatch, getState) => {
  const {
    escuela: { numeroEscuela },
  } = getState();
  if (numeroEscuela.length > 0)
    return dispatch(setNumeroEscuela(numeroEscuela));

  try {
    const { data } = await axios.get(
      "https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/getNumeroEscuela"
    );

    dispatch(setNumeroEscuela(data));
  } catch (error) {
    console.log("error-getNumeroEscuela", error);
  }
};

export const getCarreraMagisterial = () => async (dispatch, getState) => {
  const {
    escuela: { carreraMagisterial },
  } = getState();
  if (carreraMagisterial.length > 0)
    return dispatch(setCarreraMagisterial(carreraMagisterial));

  try {
    const { data } = await axios.get(
      "https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/getNivelCarreraMagisterial"
    );

    dispatch(setCarreraMagisterial(data));
  } catch (error) {
    console.log("error-getCarreraMagisterial", error);
  }
};

export const getHoras = () => async (dispatch, getState) => {
  const {
    escuela: { horas },
  } = getState();
  if (horas.length > 0) return dispatch(setHoras(horas));

  try {
    const { data } = await axios.get(
      "https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/getHoras"
    );
    dispatch(setHoras(data));
  } catch (error) {
    console.log("error-horas", error);
  }
};

export const getTipoLicencia = () => async (dispatch, getState) => {
  const {
    escuela: { tipoLicencia },
  } = getState();
  if (tipoLicencia.length > 0) return dispatch(setTipoLicencia(tipoLicencia));

  try {
    const { data } = await axios.get(
      "https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/getTipoLicencia"
    );
    dispatch(setTipoLicencia(data));
  } catch (error) {
    console.log("error-tipoLicencia", error);
  }
};

export const getEstadoCivil = () => async (dispatch, getState) => {
  const {
    escuela: { estadoCivil },
  } = getState();
  if (estadoCivil.length > 0) return dispatch(setEstadoCivil(estadoCivil));

  try {
    const { data } = await axios.get(
      "https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/getEstadoCivil"
    );
    dispatch(setEstadoCivil(data));
  } catch (error) {
    console.log("error-getEstadoCivil", error);
  }
};

export const getClaveCentroTrabajo = () => async (dispatch, getState) => {
  const {
    escuela: { clavesCentroTrabajo },
  } = getState();
  if (clavesCentroTrabajo.length > 0)
    return dispatch(setClaveCentroTrabajo(clavesCentroTrabajo));

  try {
    const { data } = await axios.get(
      "https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/getClaveCentroTrabajo"
    );
    dispatch(setClaveCentroTrabajo(data));
  } catch (error) {
    console.log("error-getClaveCentroTrabajo", error);
  }
};

export const getZonas = () => async (dispatch, getState) => {
  const {
    escuela: { zonas },
  } = getState();
  if (zonas.length > 0) return dispatch(setZonas(zonas));

  try {
    const { data } = await axios.get(
      "https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/getClavesZonas"
    );
    dispatch(setZonas(data));
  } catch (error) {
    console.log("error-getZonas", error);
  }
};

export const getSubZonas = (idClaveZona) => async (dispatch) => {
  try {
    const claveZona = JSON.stringify([{ idClaveZona }]);
    const { data } = await axios.get(
      `https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/getClavesZonasCentroTrabajo?json=${claveZona}`
    );
    dispatch(setSubZonas(data));
    return data;
  } catch (error) {
    console.log("error-getSubZonas", error);
  }
};

export const getProfesoresByZonasCentroTrabajo =
  (idClaveZonaCentroTrabajo) => async () => {
    console.log('getProfesores')
    try {

      const claveCentroTrabajo = JSON.stringify([{ idClaveZonaCentroTrabajo }]);
      const { data } = await axios.get(
        `https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/getProfesoresByClaveZonasCentroTrabajo?json=${claveCentroTrabajo}`
      );
      const filteredData = data.filter((d) => {
        return d.estatusRegistro !== "Eliminado"
      })
      return filteredData;
    } catch (error) {
      console.log(
        `error-getProfesoresByZonasCentroTrabajo`,
        error - getProfesoresByZonasCentroTrabajo
      );
    }
  };
export const getZonaCentroTrabajo = (idClaveZonaCentroTrabajo) => async () => {
  try {
    const claveCentroTrabajo = JSON.stringify([{ idClaveZonaCentroTrabajo }]);
    const { data } = await axios.get(
      `https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/getProfesoresByClaveZonasCentroTrabajo?json=${claveCentroTrabajo}`
    );
    return data;
  } catch (error) {
    console.log(`error-getZonaCentroTrabajo`, error - getZonaCentroTrabajo);
  }
};


// export const getClaveZonas = () =>{

//   try 
//     const {data} = await axios.get(``)
//   }
// }

export const altaProfesor =
  (json, resultadoAsignaturas) => async (dispatch) => {
    const test = JSON.stringify([json]);
    try {
      const { data } = await axios.post(
        `https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/insertProfesor?json=${test}`
      );
      const insertaAsignaturas = [
        {
          idProfesor: data?.idProfesor,
          materias: resultadoAsignaturas,
        },
      ];
      await axios.post(
        `https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/insertAsignaturasProfesor?json=${JSON.stringify(
          insertaAsignaturas
        )}`
      );

      return data;
    } catch (error) {
      console.log("error-altaProfesor", error);
    }
  };


export const bajaProfesor =
  (curp,cargo, openToast, idClaveZonaCentroTrabajo) => async (dispatch) => {
    debugger
    try {
      await axios.post(
        `https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/deleteProfesor?json=[{"curp": ${curp},"cargo": ${cargo},"idClaveZonaCentroTrabajo": ${idClaveZonaCentroTrabajo}}]`
      );

      openToast("trabajador dado de baja");
    } catch (error) {
      console.log("error-bajaProfesor", error);
    }
  };

export const validaCURP = (curp) => async () => {
  try {
    const { data } = await axios.get(
      // `https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/getProfesoresByCURP?json=[{"curp": ${curp}}]`
      `https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/getProfesoresByCURP?json=[{"curp":${curp},"idClaveZonaCentroTrabajo":"${userIdClaveZonasCentroTrabajo}"}]`
    );

    return data;
  } catch (error) {
    console.log("error-validaCURP", error);
  }
};

export const actualizarProfesor =
  (json, resultadoAsignaturas) => async (dispatch) => {
    const test = JSON.stringify([json]);
    try {
      const { data } = await axios.post(
        `https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/updateProfesor?json=${test}`
      );
      const insertaAsignaturas = [
        {
          idProfesor: data?.idProfesor,
          materias: resultadoAsignaturas,
        },
      ];

      await axios.get(
        `https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/updateAsignaturasProfesor?json=${JSON.stringify(
          insertaAsignaturas
        )}`
      );

      return data;
    } catch (error) {
      console.log("error-altaProfesor", error);
    }
  };

export const getTipoTitulo = () => async (dispatch, getState) => {
  const {
    escuela: { titulo },
  } = getState();
  if (titulo.length > 0) return dispatch(setTitulo(titulo));

  try {
    const { data } = await axios.get(
      "https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/getTipoTitulo"
    );
    dispatch(setTitulo(data));
  } catch (error) {
    console.log("error-getTipoTitulo", error);
  }
};

export const getTipoForte = () => async (dispatch) => {
  try {
    const { data } = await axios.get(
      "https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/getTipoForte"
    );
    dispatch(setForte(data));
  } catch (error) {
    console.log("error-getTipoForte", error);
  }
};

export const getAsignaturaHoras = () => async (dispatch) => {
  try {
    const { data } = await axios.get(
      "https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/getAsignaturaHoras"
    );
    dispatch(setAsignaturaHoras(data));
  } catch (error) {
    console.log("error-getAsignaturaHoras", error);
  }
};

export const getInspeccionData = (idClaveZonaCentroTrabajo, numeroEscuela) =>
  async () => {
    try {
      const identificacion = await getElaboracionCentroTrabajo(
        idClaveZonaCentroTrabajo
      );
      const clavesZonasCentroTrabajoById =
        await getClavesZonasCentroTrabajoById(idClaveZonaCentroTrabajo);
      const personalSupervisionInspeccion = await getPersonalSupervisionInspeccion(idClaveZonaCentroTrabajo, numeroEscuela);

      const concentradoPersonalInspeccion = await getConcentradoPersonalInspeccion(idClaveZonaCentroTrabajo, numeroEscuela);
      return { personalSupervisionInspeccion, concentradoPersonalInspeccion, identificacion, clavesZonasCentroTrabajoById };

    } catch (error) {
      console.log('first', error)
    }
  }


export const getCentroTrabajoData =
  (idClaveZonaCentroTrabajo, numeroEscuela) =>
    async (dispatch) => {
      try {
        const infraestructura = await getInfraestructuraCentroTrabajo(idClaveZonaCentroTrabajo);
        const identificacion = await getElaboracionCentroTrabajo(
          idClaveZonaCentroTrabajo
        );
        const clavesZonasCentroTrabajoById =
          await getClavesZonasCentroTrabajoById(idClaveZonaCentroTrabajo);
        const estructuraOcupacional = await getEstructuraOcupacional(
          idClaveZonaCentroTrabajo
        );
        const numeroDeAlumnos = await getNumeroDeAlumnnos(
          idClaveZonaCentroTrabajo
        );
        const talleresCentrosTrabajo = await getTalleresCentrosTrabajo(
          idClaveZonaCentroTrabajo
        );
        const gruposCentroTrabajo = await getGruposCentroTrabajo(
          idClaveZonaCentroTrabajo
        );
        const artisticas = await getArtisticas(
          idClaveZonaCentroTrabajo
        );
        //  const estructuraOcupacionalInspeccion = await getEstructuraOcupacionalInspeccion(idClaveZonaCentroTrabajo,numeroEscuela)


        return {
          identificacion,
          clavesZonasCentroTrabajoById,
          infraestructura,
          estructuraOcupacional,
          numeroDeAlumnos,
          talleresCentrosTrabajo,
          gruposCentroTrabajo,
          artisticas,
        };
      } catch (error) {
        console.log("error-getIdentificacionCentroTrabajo", error);
      }
    };

// INSPECCION

export const getPersonalSupervisionInspeccion = async (idClaveZonaCentroTrabajo) => {
  try {
    const { data } = await axios.get(
      `https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/getEstructuraOcupacionalInspeccionByClaveZonasCentroTrabajo2?json=[{idClaveZonaCentroTrabajo:${idClaveZonaCentroTrabajo},"supervision":1}]`

      
    );
    return data;
  } catch (error) {
    console.log("error-getPersonalSupervision", error);
  }
};

export const getConcentradoPersonalInspeccion = async (idClaveZonaCentroTrabajo, numeroEscuela) => {
  try {
    const { data } = await axios.get(
      `https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/getEstructuraOcupacionalInspeccionByClaveZonasCentroTrabajo2?json=[{idClaveZonaCentroTrabajo:${idClaveZonaCentroTrabajo},"supervision":0}]`
    );
    console.log('ConcentradoPersonalInspeccion', data)
    return data;
  } catch (error) {
    console.log("error-getConcentradoPersonalInspeccion", error);
  }
};

// PORTADACENTROTRABAJO
export const getInfraestructuraCentroTrabajo = async (idClaveZonaCentroTrabajo) => {
  try {
    const { data } = await axios.get(
      `https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/getInfraestructura?json=[{idClaveZonaCentroTrabajo:${idClaveZonaCentroTrabajo}}]`
    );
    return data;
  } catch (error) {
    console.log("error-getInfraestructuraCentroTrabajo", error);
  }
};

export const getElaboracionCentroTrabajo = async (id) => {
  try {
    const { data } = await axios.get(
      `https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/getIdentificacionCentroTrabajo?json=[{idClaveZonaCentroTrabajo:${id}}]`
    );
    console.log("dataInfraestructura", data);

    return data;
  } catch (error) {
    console.log("error-getElaboracionCentroTrabajo", error);
  }
};
export const getClavesZonasCentroTrabajoById = async (id) => {
  try {
    const { data } = await axios.get(
      `https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/getClavesZonasCentroTrabajoByIdClaveZonaCentroTrabajo?json=[{"idClaveZonaCentroTrabajo":${id}}]`
    );

    return data[0];
  } catch (error) {
    console.log("error-getClavesZonasCentroTrabajoById", error);
  }
};

export const getEstructuraOcupacional = async (idClaveZonaCentroTrabajo) => {
  try {
    const { data } = await axios.get(
      `https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/getEstructuraOcupacionalByClaveZonasCentroTrabajo2?json=[{idClaveZonaCentroTrabajo:${idClaveZonaCentroTrabajo}}]`



      // `https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/getEstructuraOcupacionalByClaveZonasCentroTrabajo?json=[{idClaveZonaCentroTrabajo:${idClaveZonaCentroTrabajo}}]`
    );
    return data;
  } catch (error) {
    console.log("error-getEstructuraOcupacional", error);
  }
};

export const getNumeroDeAlumnnos = async (idClaveZonaCentroTrabajo) => {
  try {
    const { data } = await axios.get(
      `https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/getNumeroAlumnosSexoGradoTurno?json=[{idClaveZonaCentroTrabajo:${idClaveZonaCentroTrabajo}}]`
    );
    return data;
  } catch (error) {
    console.log("error-getNumeroDeAlumnnos", error);
  }
};
export const getTalleresCentrosTrabajo = async (idClaveZonaCentroTrabajo) => {
  try {
    const { data: talleres } = await axios.get(
      `https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/getTalleres`
    );

    const { data } = await axios.get(
      `https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/getTallererCentrosTrabajo?json=[{"idClaveZonaCentroTrabajo":${idClaveZonaCentroTrabajo}}]`
    );
    const resultado = data.map((d) => {
      return {
        ...d,
        nombre: talleres.find((taller) => taller.idTaller === d.idTaller)
          .nombre,
      };
    });
    return resultado;
  } catch (error) {
    console.log("error-getTalleresCentrosTrabajo", error);
  }
};

export const getGruposCentroTrabajo = async (idClaveZonaCentroTrabajo) => {
  try {
    const { data } = await axios.get(
      `https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/getGrupoCentroTrabajo?json=[{"id_clave_zona_centro_trabajo":${idClaveZonaCentroTrabajo}}]`
    );
    return data;
  } catch (error) {
    console.log("error-getGruposCentroTrabajo", error);
  }
};
export const getArtisticas = async (idClaveZonaCentroTrabajo) => {
  try {
    const { data } = await axios.get(
      `https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/getArtisticas?json=[{"idClaveZonaCentroTrabajo":${idClaveZonaCentroTrabajo}}]`
    );
    return data;
  } catch (error) {
    console.log("error-Artisticas", error);
  }
};

export const altaNumeroAlumnos = (json) => async (dispatch) => {

  const numAlumnos = JSON.stringify(json);
  try {
    const { data } = await axios.post(
      `https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/insertNumeroAlumnosSexoGradoTurno?
      json=[${numAlumnos}]`
    );
    toast.dark("Numero Alumnos Insertados...");
    return data;
  } catch (error) {
    console.log("error-altaProfesor", error);
  }
};

export const getTalleres = () => async (dispatch, getState) => {
  try {
    const {
      escuela: { talleres },
    } = getState();
    // console.log('talleres', talleres)
    if (talleres.length > 0) return dispatch(setTalleres(talleres));

    const { data } = await axios.get(
      `https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/getTalleres`
    );
    // return data;
    dispatch(setTalleres(data));
  } catch (error) {
    console.log("error-Artisticas", error);
  }
};

const setTalleres = (talleres) => ({
  type: SET_TALLERES,
  talleres,
});
