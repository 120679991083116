import { Button,Form, Input, Layout, Typography } from 'antd';
import loginBanner from "../../images/login-banner2.png"
import axios from "axios";
import md5 from "md5"
import "./Login.css"


// const options = {
//   method: 'GET',
//   url: 'https://www.plantillasdestmorelos.gob.mx:8080/PlantillaPersonalWS/webresources/Maestros/getRegistro',
//   params: {
//     json: '[{"email":"a3b93a06c7d7c7f15462a4f1295bac30","password":"0a48ec95fe297445a2d301cba1fdce10"}]'
//   }
// };


const Login = ({ setuser }) => {
  const { Title } = Typography;
  const { Content, Header, Footer } = Layout;
  const handleSubmit = (a, b, c) => {
  }
  const onFinish = async (values) => {
    // values.preventDefault();
    const modEmail = md5(values.username);
    const modPass = md5(values.password)


    const userjson = JSON.stringify([{ email: modEmail, password: modPass }])
    setuser(userjson);

    
    
    const options = {
      mode:"no-cors",
      method: 'GET',
      url: 'https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/getRegistro',
      params: {
        json: `${userjson}`
      }
    };
    
    axios.request(options).then(function (response) {
      console.log('response', response)
      const { sesion,idClaveZonaCentroTrabajo, idClaveZona,escuela,id } = response.data
const userAccessData = {idClaveZonaCentroTrabajo:idClaveZonaCentroTrabajo, idClaveZona: idClaveZona, escuela:escuela, id:id,sesion:sesion, numeroEscuela:escuela}
//+",2" Revisar numeroEscuela:escuela en caso de error
const userAccessDataStr = JSON.stringify(userAccessData);

      console.log('userAccessDataLogin', userAccessDataStr)
      if (sesion === "true") {
        setuser(response.data);
        sessionStorage.setItem("isSessionActive", sesion);
        sessionStorage.setItem("userId", response.data.id);
        sessionStorage.setItem("userAccessData",userAccessDataStr );
       
      } else {
        setuser("");
        alert("Usuario no existe")
      }

    }).catch(function (error) {
      console.error(error);
    });
    

  };

 
  
  const onFinishFailed = (errorInfo) => {
    // errorInfo.preventDefault();
    console.log('Failed:', errorInfo);
  };
  return (
    <Layout>
      <Header id='header'><img src={loginBanner} alt="login-banner" height="300px" /></Header>
      <Layout id='layout'>
        <Content
        >
          <Title className='title'
            level={5} >Introduce tus datos para iniciar sesión</Title>
          <Form
            name="basic"
            labelCol={{
              offset: 6,
              span: 4,
            }}
            wrapperCol={{
              // offset:4,
              span: 4,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            onSubmit={handleSubmit}
          >


            <Form.Item
              label="Usuario"
              name="username"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: 'Por favor introduce correo electrónico!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Contraseña"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Por favor escribe tu contraseña!',
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            {/* 
      <Form.Item
        name="remember"
        valuePropName="checked"
        wrapperCol={{
          offset: 10,
          span: 16,
        }}
      >
        <Checkbox>Recuerdame</Checkbox>
      </Form.Item> */}

            <Form.Item
              wrapperCol={{
                offset: 10,
                span: 16,
              }}
            >
              <Button type="primary" htmlType="submit">
                Acceder
        </Button>
            </Form.Item>
          </Form>
        </Content>
      </Layout>
      <Footer>   Copyright: Quedan Reservados todos los derechos  para el  Departamento de Secundarias
              Técnicas Morelos.</Footer>
    </Layout>

  );
};
export default Login;
