import AltaPersonal from "../../containers/altaContainer";
import axios from "axios";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { procesaDatosProfesor } from "./Actualizaciones";
import { getPuestos, getClaveCentroTrabajo } from "../../actions/escuela-action";
import { validateFechas } from "../../helpers";
import "./ListaBusqueda.scss"

const ListaBusqueda = (props) => {
  // const [isProfesores, setIsProfesores] = useState(false);
  const [datosProfesor, setDatosProfesor] = useState({});
  const [asignaturas, setAsignaturas] = useState({});
  const [isLoading, setisLoading] = useState(false);
  const [editable, setEditable] = useState(true)
  const puestos = useSelector((state) => state.escuela.puestos);
  const clavesCentroTrabajo = useSelector((state => state.escuela.clavesCentroTrabajo))

  const dispatch = useDispatch();
  const path = window.location.pathname;
  useEffect(() => {
    dispatch(getPuestos());
    dispatch(getClaveCentroTrabajo())
  }, []);
  const { datosProfesores } = props;


  const procesaProfesor = async (profesorData) => {
    setisLoading(true)
    const { idProfesor, error, info, cargo } = profesorData;

    const llevaAsignaturas = puestos.find(
      (puesto) => puesto.nombre === cargo
    )?.llevaAsignaturas;
    const procesa = procesaDatosProfesor({ ...profesorData, llevaAsignaturas });
    setDatosProfesor(validateFechas(procesa));
    const asignaturas = await axios.get(
      `https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/getAsignaturaProfesoresByIdProfesor?json=[{"idProfesor":${idProfesor}}]`
    );
    setAsignaturas(asignaturas.data);
    setisLoading(false)

  };
  const listaFinal = datosProfesores?.map((datosProfesor) => {
    const {
      nombre,
      apellidoPaterno,
      apellidoMaterno,
      claveCentroDeTrabajo,
      estatusRegistro
    } = datosProfesor;
    return (<>





      <tr>
        <td>{`${nombre} ${apellidoPaterno} ${apellidoMaterno} `}</td>
        <td>{clavesCentroTrabajo.find((cct) => cct.idClave == claveCentroDeTrabajo)?.clave}</td>
        <td>
          <button
            onClick={() => {
              procesaProfesor(datosProfesor);
              // setIsProfesores(false);
              setDatosProfesor(datosProfesor)
            }}
          >
            Ver
                        </button>
        </td>
        <td><button className={estatusRegistro}>{estatusRegistro}</button></td>
      </tr>


    </>)

  })
  return <>
    <table>
      {datosProfesores.length >= 1 &&
        <tr>
          <th>Nombre</th>
          <th>Número de Escuela</th>
          <th></th>
        </tr>
      }

      <tbody>{listaFinal}</tbody>
    </table><div style={editable ? { pointerEvents: "none", opacity: "0.9" } : {}}>

      {Object.keys(datosProfesor).length > 0 &&
        Object.keys(asignaturas).length > 0 &&
        !isLoading && (
          <AltaPersonal
            path={path}
            setDatosProfesor={setDatosProfesor}
            datosProfesor={
              {
                ...datosProfesor,
                claveCentroDeTrabajo: datosProfesor.claveCentroDeTrabajo
                  ?.split(",")
                  .map((m) => parseInt(m)),
              }}
            asignaturas={asignaturas}
          />)}
    </div>
  </>

};

export default ListaBusqueda;
