import {
  SET_MATERIAS,
  SET_PUESTOS,
  SET_ASIGNATURA_NOMBRAMIENTO,
  SET_ARTES,
  SET_TALLERES,
  SET_TURNOS_LIST,
  SET_TURNOS,
  SET_GRUPOS,
  SET_HORAS,
  SET_CATEGORIAS,
  SET_CLAVESCENTROTRABAJO,
  SET_TIPO_LICENCIA,
  SET_MOTIVOS,
  SET_ESTADO_CIVIL,
  SET_NUMERO_ESCUELA,
  SET_CARRERA_MAGISTERIAL,
  SET_ZONAS,
  SET_FORTE,
  SET_TITULO,
  SET_ASIGNATURA_HORAS,
  SET_QUINQUENIO,
  SET_INCENTIVO,
  SET_SUB_ZONAS,
} from "../constants/action-types";

const initialState = {
  materias: [],
  materiasMap: [],
  puestos: [],
  asignaturaNombramientos: [],
  artes: [],
  turnos: [],
  grupos: [],
  gruposMap: [],
  horas: [],
  categorias: [],
  clavesCentroTrabajo: [],
  tipoLicencia: [],
  motivos: [],
  estadoCivil: [],
  numeroEscuela: [],
  carreraMagisterial: [],
  zonas: [],
  subZonas: [],
  forte: [],
  titulo: [],
  asignaturaHoras: [],
  quinquenio: [],
  incentivo: [],
  turnosList: [],
  talleres: [],
};
//reducer id y valor
const escuela = (state = initialState, action) => {
  switch (action.type) {



    case SET_MATERIAS:
      // {
      //   "idMateria": 1,
      //   "nombre": "Lengua Materna",
      //   "primerGrado": 5,
      //   "segundoGrado": 5,
      //   "tercerGrado": 5
      // }
      var materiasMap = action.materias?.reduce((acc, { idMateria, nombre }) => {
        return { ...acc, [idMateria]: nombre };
      }, {});

      return {
        ...state,
        materias: action.materias,
        materiasMap,
      };
    case SET_PUESTOS:
      return {
        ...state,
        puestos: action.puestos,
      };
    case SET_ASIGNATURA_NOMBRAMIENTO:
      return {
        ...state,
        asignaturaNombramientos: action.asignaturaNombramientos,
      };
    case SET_TALLERES:
      return {
        ...state,
        talleres: action.talleres,
      };
    case SET_ARTES:
      return {
        ...state,
        artes: action.artes,
      };
    case SET_TURNOS_LIST:
      return {
        ...state,
        turnosList: action.turnosList,
      };
    case SET_TURNOS:
      return {
        ...state,
        turnos: action.turnos,
      };
    case SET_GRUPOS:
      var gruposMap = action.grupos.reduce((acc, { idGrupo, nombre }) => {
        return { ...acc, [idGrupo]: nombre };
      }, {});
      return {
        ...state,
        grupos: action.grupos,
        gruposMap,
      };
    case SET_HORAS:
      return {
        ...state,
        horas: action.horas,
      };
    case SET_CATEGORIAS:
      return {
        ...state,
        categorias: action.categorias,
      };
    case SET_CLAVESCENTROTRABAJO:
      return {
        ...state,
        clavesCentroTrabajo: action.clavesCentroTrabajo,
      };
    case SET_TIPO_LICENCIA:
      return {
        ...state,
        tipoLicencia: action.tipoLicencia,
      };
    case SET_ESTADO_CIVIL:
      return {
        ...state,
        estadoCivil: action.estadoCivil,
      };
    case SET_MOTIVOS:
      return {
        ...state,
        motivos: action.motivos,
      };
    case SET_NUMERO_ESCUELA:
      return {
        ...state,
        numeroEscuela: action.numeroEscuela,
      };
    case SET_CARRERA_MAGISTERIAL:
      return {
        ...state,
        carreraMagisterial: action.carreraMagisterial,
      };
    case SET_ZONAS:
      return {
        ...state,
        zonas: action.zonas,
      };
    case SET_SUB_ZONAS:
      return {
        ...state,
        subZonas: action.subZonas,
      };

    case SET_FORTE:
      return {
        ...state,
        forte: action.forte,
      };
    case SET_ASIGNATURA_HORAS:
      return {
        ...state,
        asignaturaHoras: action.asignaturaHoras,
      };
    case SET_TITULO:
      return {
        ...state,
        titulo: action.titulo,
      };
    case SET_QUINQUENIO:
      return {
        ...state,
        quinquenio: action.quinquenio,
      };
    case SET_INCENTIVO:
      return {
        ...state,
        incentivo: action.incentivo,
      };
    default:
      return state;
  }
};

export default escuela;
