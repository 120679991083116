import { connect } from "react-redux";
import { actualizarProfesor, altaProfesor, bajaProfesor, getCarreraMagisterial, getCategorias, getClaveCentroTrabajo, getEstadoCivil, getGrupos, getHoras, getMaterias, getMotivos, getNumeroEscuela, getPuestos, getTipoLicencia, getTurno, validaCURP, getZonas, getSubZonas, getTipoTitulo, getTipoForte, getQuinquenio, getIncentivo, getAsignaturaNombramiento, getArtes, getTurnos, getTalleres } from "../actions/escuela-action";
import AltaPersonal from "../page/personal";

// const userData = sessionStorage.getItem("userAccessData");
// const userAccessDataObj = JSON.parse(userData)
//   console.log('userAccessDataRG', userAccessDataObj)
//   const {idClaveZona,escuela} = userAccessDataObj;
//    const userIdClaveZonasCentroTrabajo = userAccessDataObj.idClaveZonaCentroTrabajo;

const mapStateToProps = (state) => ({
  materias: state.escuela.materias,
  materiasMap: state.escuela.materiasMap,
  puestos: state.escuela.puestos,
  asignaturaNombramientos: state.escuela.asignaturaNombramientos,
  artes: state.escuela.artes,
  turnos: state.escuela.turnos,
  turnosList: state.escuela.turnosList,
  grupos: state.escuela.grupos,
  horas: state.escuela.horas,
  motivos: state.escuela.motivos,
  clavesCentroTrabajo: state.escuela.clavesCentroTrabajo,
  tipoLicencia: state.escuela.tipoLicencia,
  estadoCivil: state.escuela.estadoCivil,
  categorias: state.escuela.categorias,
  gruposMap: state.escuela.gruposMap,
  numeroEscuela: state.escuela.numeroEscuela,
  carreraMagisterial: state.escuela.carreraMagisterial,
  zonas: state.escuela.zonas,
  subZonas: state.escuela.subZonas,
  forte: state.escuela.forte,
  titulo: state.escuela.titulo,
  quinquenio: state.escuela.quinquenio,
  incentivo: state.escuela.incentivo,
  talleres: state.escuela.talleres
});

const mapDispatchToProps = (dispatch, { datosProfesor }) => {
  return ({
    init: () => {
      dispatch(getMaterias());
      dispatch(getPuestos());
      dispatch(getAsignaturaNombramiento());
      dispatch(getArtes());
      dispatch(getTurnos());
      dispatch(getTurno());
      dispatch(getGrupos());
      dispatch(getHoras());
      dispatch(getCategorias());
      dispatch(getClaveCentroTrabajo());
      dispatch(getTipoLicencia());
      dispatch(getEstadoCivil());
      dispatch(getMotivos());
      dispatch(getNumeroEscuela());
      dispatch(getCarreraMagisterial());
      dispatch(getZonas());
      if (datosProfesor?.idProfesor)
        dispatch(getSubZonas(datosProfesor.idClaveZona));
      dispatch(getTipoTitulo());
      dispatch(getTipoForte());
      dispatch(getQuinquenio());
      dispatch(getIncentivo());
      dispatch(getTalleres());
    },
    altaProfesor: (json, resultadoAsignaturas) => dispatch(altaProfesor(json, resultadoAsignaturas)),
    bajaProfesor: (curp,cargo, openToast, idClaveZonaCentroTrabajo) => dispatch(bajaProfesor(curp,cargo, openToast, idClaveZonaCentroTrabajo)),
    actualizarProfesor: (json, resultadoAsignaturas) => dispatch(actualizarProfesor(json, resultadoAsignaturas)),
    validaCURP: (curp) => dispatch(validaCURP(curp)),
    getSubZonas: (idClaveZona) => dispatch(getSubZonas(idClaveZona))
  })
};


export default connect(mapStateToProps, mapDispatchToProps)(AltaPersonal);