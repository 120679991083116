import React, { useState } from 'react'
import BusquedaMenu from './BusquedaMenu'
import ListaBusqueda from './ListaBusqueda'
const Busqueda = (props) => {


  const [datosBusqueda, setDatosBusqueda] = useState([])
  return (
    <><BusquedaMenu setDatosBusqueda={setDatosBusqueda} />

      <ListaBusqueda datosProfesores={datosBusqueda} />
    </>

  )
}
export default Busqueda;