import React, { useEffect } from "react";
import axios from "axios";
import locale from "antd/lib/locale/es_ES";
import {
  Layout,
  Menu,
  Select,
  Divider,
  Checkbox,
  DatePicker,
  Space,
  Card,
  Radio,
  Row,
  Col,
  Form,
  ConfigProvider,
  Button,
} from "antd";

import { CloseOutlined } from "@ant-design/icons";
const { Option } = Select;
const { Header, Footer, Sider, Content } = Layout;

export default function RGFSeleccionados({ filteredDataObj, handleResetFields }) {

  return (
    <>
      <Row>
        <Col flex="100%">
          <Card
            // title="Filtros seleccionados"
            size="small" align="center">
            <Button type="danger" onClick={handleResetFields}>Resetear Filtros</Button>
            {Object.keys(filteredDataObj).map((item) => {
              return (
                <Button key={item} type="primary" size="small">
                  {item}: {filteredDataObj[item]}
                  <CloseOutlined />
                </Button>
              );
            })}
          </Card>
        </Col>
      </Row>
    </>
  );
}
