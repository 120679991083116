import React, {useState, useEffect} from "react";
import axios from "axios";
import "./RepGenTablaDisDoc.scss"
import { notification, Spin} from 'antd';
const RepGenTablaDistDoc = ({claveCentroTrabajoSelected,asignaturasIds,materiasMap,profsConAsigsArr,numeroEscuela, profsSinHorasResult,puestos, profsSinHorasArr}) => {
  console.log('profsSinHorasResult', profsSinHorasResult)
  const _ = require("lodash");
const [color, setColor] = useState("")
  console.log("asignaturasIds", asignaturasIds);
  console.log('materiasMap', materiasMap)
const [asignaturasHoras, setasignaturasHoras] = useState([])

// AntD Notification function
const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement, id,name,horasExcedidas) => {
    api.info({
      message: `Id: ${id}`,
      style: {
        width: 600,
        // fontSize:40
      }, 
      duration:10,
      description:
        (<> Profesor/a : {name}<br/> Horas excedidas : {horasExcedidas}</>),placement,
    });
  };
console.log('profsConAsigsArrDistDoc', profsConAsigsArr)
  useEffect(() => {
    const options = {
      method: 'GET',
      url: 'https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/getMaterias'
    };
    
    axios.request(options).then(function (response) {
      console.log("responseaAsignaturasHoras",response.data);
      setasignaturasHoras(response.data)
    }).catch(function (error) {
      console.error(error);
    });
  
  }, [])
  

  const array1 = (asignaturasIds) => {
    // create an empty array to store the values
    let array = [];
    // loop through the keys of the object
    for (let key in asignaturasIds) {
      // push the value of each key to the array
      array.push(asignaturasIds[key]);
    }
    // return the array
    return array;
  };
  
  const array2 = array1(asignaturasIds);
  console.log("array2", array2);
  // console.log(array(asignaturasIds))
  let columsSetup = {};
  const myKeys = ["1oMat", "1oVesp", "2oMat", "2oVesp", "3oMat", "3oVesp"];

  array2.forEach((obj) => {
    myKeys.forEach((myKey) => {
      const arrayValues = obj[myKey];
      const newValues = columsSetup[myKey] || [];
      arrayValues.forEach((av, index) => {
        newValues[index] = av || newValues[index] ? 1 : av;
      });
      columsSetup = { ...columsSetup, [myKey]: newValues };
    });
  });
  console.log("colSetAntes1", JSON.stringify(columsSetup));
  let deepCopy = _.cloneDeep(columsSetup);
  console.log("deepCopy", deepCopy);

  myKeys.forEach((col) => {
    columsSetup[col] = _.compact(columsSetup[col]);
  });
  console.log("columsSetupCompact", columsSetup);

  function getNullIndexes(arr) {
    console.log('arr', arr)
    const indexes = [];

    arr?.forEach((element, index) => {
      if (element === null) {
        indexes.push(index);
      }
    });

    return indexes;
  }

  console.log("columsSetup3", deepCopy);
  const removeIndexes = {};
  console.log('myKeys', myKeys)
  myKeys.forEach((myKey) => {
    console.log('myKey', myKey)
    const arrayValues = deepCopy[myKey];
    console.log("arrayValues", arrayValues);
    const test = getNullIndexes(arrayValues);
    console.log("test", test);
    removeIndexes[myKey] = test;
  });

  console.log("removeIndexes", removeIndexes);
console.log('array2', array2)
  const result = array2.map((obj) => {
    let newObj = {};
    myKeys.forEach((myKey) => {
      const arrayValues = obj[myKey];
      //     console.log("arrayValues",arrayValues)b
      const removeInd = removeIndexes[myKey];

      const newArray = arrayValues.filter(
        (_, index) => !removeInd.includes(index)
      );
      console.log("newArray", newArray);
      newObj = { ...newObj, [myKey]: newArray };
    });
    //   console.log("newObj",newObj)
    return newObj;
  });

  console.log("result", result);

  //
  const letras = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L"];

  const result2 = result.map((obj) => {
    let newObj = {};
    //   let lastInd = 0;

    myKeys.forEach((myKey) => {
      const arrayValues = obj[myKey];

      const newArray = arrayValues.map((av, indx) => letras[indx]);
      newObj = { ...newObj, [myKey]: newArray };
    });
    //   console.log("lastInd:::",lastInd)
    console.log("newObj:::", newObj);
    return obj;
  });

  console.log("result2", result2);

  function replaceValues(obj) {
    // Initialize a variable to store the current letter
    let letter = "A";
    // Loop through the keys of the object
    for (let key of Object.keys(obj)) {
      // Check if the key ends with Mat
      if (key.endsWith("Mat")) {
        // Reset the letter to A
        letter = "A";
      }
      // Check if the key ends with Mat or Vesp
      if (key.endsWith("Mat") || key.endsWith("Vesp")) {
        // Loop through the values of the array
        for (let i = 0; i < obj[key].length; i++) {
          // Replace the value with the current letter
          obj[key][i] = letter;
          // Increment the letter to the next one in the alphabet
          letter = String.fromCharCode(letter.charCodeAt(0) + 1);
        }
      }
    }
    // Return the modified object
    return obj;
  }
console.log('columsSetup4', columsSetup)
  const arrAlphabet = replaceValues(columsSetup);
  console.log("arrAlphabet", arrAlphabet);

  // This function takes an object and returns an array with table data elements
  // using the letters inside all the arrays, without using the keys of the arrays
  // This function takes an object and returns an array with table data elements
  // using the letters inside all the arrays, without using the keys of the arrays
  function createTableData(obj) {
    // Initialize an empty array
    let arr = [];
    // Loop through the values of the object
    for (let letters of Object.values(obj)) {
      // Loop through the letters in the array
      for (let letter of letters) {
        // Create a table data element with the letter
        let td = <td>{letter}</td>;
        // Append the table data element to the array
        arr.push(td);
      }
    }
    // Return the array
    return arr;
  }
  const gruposTds = createTableData(arrAlphabet);

  console.log("gruposTds", gruposTds);
  console.log("arrAlphabet", arrAlphabet);

// Crear un arreglo de objetos con llaves 1o,2o,3o que contengan el length de salones para ser multiplicados por el numero de horas con respecto a servicio  getAsignaturasHoras





// function organizeArrays(inputArray) {
//   const resultArray = [];

//   for (const obj of inputArray) {
//       const newObject = { '1o': 0, '2o': 0, '3o': 0 };

//       for (const [key, value] of Object.entries(obj)) {
//           // Extract the number and letter from the key
//           const [number, letter] = key.split('o');

//           // Check if the key starts with a valid number and letter
//           if (!isNaN(number)  && letter.match(/[a-zA-Z]/)) {
//               // Exclude null values
//               if (value !== null) {
//                   newObject[`${number}o`] += value.length;
//               }
//           }
//       }

//       resultArray.push(newObject);
//   }

//   return resultArray;
// }

function createUniqueValuesArrays(data) {
  // Map over each object in the data array to create a new array of unique values
  return data.map(obj => {
    // Combine all values from all keys into a single array
    const combinedValues = Object.values(obj).flat();
    // Create a Set from the combined values to ensure uniqueness
    const uniqueValuesSet = new Set(combinedValues);
    // Convert the Set back into an array and filter out null values
    return Array.from(uniqueValuesSet).filter(value => value != null);
  });
}



const uniqueValuesArrays = createUniqueValuesArrays(result);
console.log('uniqueValuesArrays',uniqueValuesArrays);

function sumHorasExcedidas(profsConAsigsArr, uniqueValuesArrays) {
  console.log('sumHorasExcedidas', uniqueValuesArrays)
  return uniqueValuesArrays.map(subArray => {
    let sum = 0;
    subArray.forEach(id => {
      const prof = profsConAsigsArr.find(prof => prof.idProfesor === id);
      if (prof) {
        sum += prof.horasExcedidas;
      }
    });
    return sum;
  });
}

// Example usage:
const sumsResult = sumHorasExcedidas(profsConAsigsArr, uniqueValuesArrays);
console.log('sumsResult',sumsResult);
function organizeArrays(arr) {
  const result = [];

  // Iterate through each object in the array
  arr.forEach((obj) => {
    console.log(obj);

    const objCounts = {};

    // Iterate through the keys (e.g., "1oMat", "1oVesp", etc.)
    Object.keys(obj).forEach((key) => {
      // Extract the first two letters from the key
      const prefix = key.slice(0, 2);
      console.log("key", key);
      console.log("objkey", obj[key]);
      // Count the non-null and non-empty string values
      const validValuesCount = obj[key].filter((value) =>
        Number.isInteger(value)
      ).length;
      console.log("validValuesCount", validValuesCount);
      // Store the count in the object
      console.log("objprefix", objCounts[prefix]);
      console.log("prefix", prefix);
      objCounts[prefix] = (objCounts[prefix] || 0) + validValuesCount;
    });

    // Add the object with counts to the result array
    result.push(objCounts);
  });

  return result;
}
const gradosLength = organizeArrays(result);
console.log("gradosLength",gradosLength);

// funcion para  obtener columna de horas totales basado en el grado y numero de salones 
function calculateProductSum(asignaturasHoras, gradosLength) {
  console.log('asignaturasHorasCal', asignaturasHoras)
  console.log('gradosLengthCal', gradosLength)
  const resultArray = [];

  for (let i = 0; i < asignaturasHoras.length; i++) {
    if (gradosLength && gradosLength[i]) {
      const productSum = (asignaturasHoras[i].primerGrado * gradosLength[i]['1o']) +
          (asignaturasHoras[i].segundoGrado * gradosLength[i]['2o']) +
          (asignaturasHoras[i].tercerGrado * gradosLength[i]['3o']);
      resultArray.push(productSum);
  }
}
  return resultArray;
}

const totalHorasAsignatura = calculateProductSum(asignaturasHoras, gradosLength);
console.log('totalHorasAsignatura',totalHorasAsignatura); 
console.log('asignaturasHoras', asignaturasHoras)

  const primeroLength =
    arrAlphabet["1oMat"].length + arrAlphabet["1oVesp"].length;
  const segundoLength =
    arrAlphabet["2oMat"].length + arrAlphabet["2oVesp"].length;
  const terceroLength =
    arrAlphabet["3oMat"].length + arrAlphabet["3oVesp"].length;


    // Define a function that takes an array of objects as a parameter
function sumLengths(result) {
  // Initialize an empty array to store the results
  let results = [];
  // Loop through each object in the array
  for (let obj of result) {
    // Initialize a variable to store the sum of lengths for the current object
    let sum = 0;
    // Loop through each key-value pair in the object
    for (let [key, value] of Object.entries(obj)) {
      // Check if the value is an array
      if (Array.isArray(value)) {
        // Add the length of the array to the sum
        sum += value.length;
      }
    }
    // Push the sum to the results array
    results.push(sum);
  }
  // Return the results array
  return results;
}
// Total de horas por Materia YA NO SE USA 
const asignaturasHorasTotal =sumLengths(result);
console.log('asignaturasHorasTotal', asignaturasHorasTotal)
     // Esta función toma un array de objetos como argumento y devuelve el mismo array pero con claves numeradas añadidas a cada objeto, empezando por el número 1
function addNumberedKeys(array) {
  // Creamos una copia del array para no modificar el original
  let newArray = [...array];
  // Recorremos el array con un bucle for
  for (let i = 0; i < newArray.length; i++) {
    // Obtenemos el objeto actual
    let obj = newArray[i];
    // Añadimos una clave numerada al objeto con el valor i + 1
    obj.number = i + 1;
    // Reemplazamos el objeto en el array con el nuevo objeto modificado
    newArray[i] = obj;
  }
  // Devolvemos el nuevo array
  return newArray;
}


// Define a function that counts the number of unique values in an array, excluding null and duplicates in the same object
function uniq(a, seen) {
  // Use a Set to store the values without duplicates
  let set = new Set(a);
  // Remove null from the set if it exists
  set.delete(null);
  // Loop through the set and check if the value has been seen before
  for (let value of set) {
    if (seen.has(value)) {
      // If yes, remove it from the set
      set.delete(value);
    } else {
      // If no, add it to the seen set
      seen.add(value);
    }
  }
  // Return the size of the set
  return set.size;
}



// Define an empty array to store the results
let resultProfesPorAsignatura = [];

// Loop through each object in the input array
for (let obj of result) {
  // Initialize a variable to store the sum of unique values for each object
  let sum = 0;
  // Initialize a set to store the values that have been seen in the current object
  let seen = new Set();
  // Loop through each key-value pair in the object
  for (let [key, value] of Object.entries(obj)) {
    // Add the number of unique values in the value array to the sum, passing the seen set as an argument
    sum += uniq(value, seen);
  }
  // Push the sum to the result array
  resultProfesPorAsignatura.push(sum);
}

// Print the result array
console.log("resultProfesPorAsignatura",resultProfesPorAsignatura); // [3, 2, 5]

// const resultadoMateriasYIds = addNumberedKeys(result);

    // Funcion para cambiar de color los tds 

    const changeColor = (value) => {
      const cells = document.getElementsByTagName('td');
      const newColor = { ...color };
      for (let i = 0; i < cells.length; i++) {
        if (cells[i].innerHTML === value) {
          cells[i].style.backgroundColor = 'yellow';
          newColor[value] = 'yellow';
        } else {
          cells[i].style.backgroundColor = '';
          delete newColor[cells[i].innerHTML];
        }
      }
      setColor(newColor);
    };
  
  const handleTableDataClick = (item) => { 

console.log('item', item)
if(item !== null){
const selectedProfObj = profsConAsigsArr.filter(prof => prof.idProfesor === item )
console.log('selectedProfObj', selectedProfObj)
const {nombre, apellidoPaterno, apellidoMaterno, horasExcedidas}=selectedProfObj[0]
const nombreCompleto = nombre +' '+ apellidoPaterno +' '+ apellidoMaterno;

openNotification('topRight',item,nombreCompleto, horasExcedidas)}
   }
  
  
  
  
    // Functions
    const handleClick = (value) => {
      console.log('first', value);
      changeColor(value)
    };
  
console.log('resultFilas', result)
// Creacion de columnas para Materias y los ids de cada grupo
    const filas = result.map((asig, index) => {
      const columnasMaterias = Object.values(asig).map((arreglo) => {
        return arreglo.map((item) => {
          return <td    onClick={()=>handleTableDataClick(item)}>{item}</td>
  
        })
      });
  console.log('columnasMaterias', columnasMaterias)
      return <tr onClick={e => handleClick(e.target.innerHTML)}>
        <td>{materiasMap[index + 1]}</td>
        {columnasMaterias}
        {/* <td></td> */}
        <td>{totalHorasAsignatura[index]}</td>
        <td>{sumsResult[index]}</td>
        <td>{resultProfesPorAsignatura[index]}</td>
      </tr>;
    });
console.log('filas', filas)

  const totalHorasAsigs = totalHorasAsignatura.reduce((total, valor,i) => { 
return total + valor
  },0)
  const totalsumResult = sumsResult.reduce((total, valor,i) => { 
return total + valor
  },0)
  const totalresultProfesPorAsig = resultProfesPorAsignatura.reduce((total, valor,i) => { 
return total + valor
  },0)

  function addNombreToProfs(profsSinHorasResult, puestos) {
    const resultWithNames = profsSinHorasResult.map((prof) => {
      const matchingPuesto = puestos.find((puesto) => puesto.idPuesto === prof.cargo);
      if (matchingPuesto) {
        return { ...prof, nombrePuesto: convertToTitleCase(matchingPuesto.nombre) };
      }
      return prof;
    });
  
    return resultWithNames;
  }
 

  // Asignaturas to lowercase except initial letter of words 
  function convertToTitleCase(inputString) {
    // Split the input string into an array of words
    const words = inputString.split(" ");
  
    // Capitalize the first letter of each word and make the rest lowercase
    const titleCaseWords = words.map((word) => {
      const firstLetter = word.charAt(0).toUpperCase();
      const restOfWord = word.slice(1).toLowerCase();
      return firstLetter + restOfWord;
    });
  
    // Join the title case words back into a single string
    const titleCaseString = titleCaseWords.join(" ");
    return titleCaseString;
  }
  
  // Example usage:
  const input = "hello world react function";
  const titleCaseOutput = convertToTitleCase(input);
  console.log(titleCaseOutput); // Output: "Hello World React Function"



  
  const resultNombreToProfs = addNombreToProfs(profsSinHorasResult, puestos);
  console.log("resultNombre",resultNombreToProfs)
// console.log('resultadoMateriasYIds', resultadoMateriasYIds)
// console.log('nombreAsig', nombreAsig)
  return (
    <div>
      {contextHolder}
      {filas.length >= 1?<>
      <table className="tabla-dis-doc">
        <thead>
          <tr>
            <th colSpan={4+primeroLength+segundoLength+terceroLength}>DIRECCIÓN DE EDUCACIÓN SECUNDARIA</th>
          </tr>
        </thead>
        <tbody>
         
          <tr>
            <td colSpan={4+primeroLength+segundoLength+terceroLength}>
              AJUSTE DE PERSONAL PARA EL CICLO ESCOLAR 2023-2024
            </td>
          </tr>
          <tr>
            <td>SECUNDARIA: </td>
            <td colSpan={primeroLength+segundoLength+terceroLength}>TÉCNICA {numeroEscuela}</td>
            <td colSpan="3">{claveCentroTrabajoSelected}</td>
            
          </tr>
          <tr>
            
            <td colSpan={4+primeroLength+segundoLength+terceroLength}>DISTRIBUCIÓN DE DOCENTES POR ASIGNATURA</td>
          </tr>
          <tr>
            <td rowSpan="2">ASIGNATURA</td>
            <td colSpan={primeroLength}>PRIMERO</td>
            <td colSpan={segundoLength}>SEGUNDO</td>
            <td colSpan={terceroLength}>TERCERO</td>
            <td rowSpan="3">
              TOTAL <br />
              DE
              <br />
              HORAS
            </td>
            <td rowSpan="3">
              TOTAL <br />
              HORAS
              <br />
              EXCE.
            </td>
            <td rowSpan="3">
              TOTAL
              <br />
              DE
              <br />
              DOCENTES
              <br />X ASIGNAT
            </td>
          </tr>
          <tr>
            <td colSpan={arrAlphabet["1oMat"].length}>MAT</td>
            { !!arrAlphabet["1oVesp"]?.length && <td >VES</td> }
            <td colSpan={arrAlphabet["2oMat"].length}>MAT</td>
            { !!arrAlphabet["2oVesp"]?.length && <td >VES</td> }
            <td colSpan={arrAlphabet["3oMat"].length}>MAT</td>
            { !!arrAlphabet["3oVesp"]?.length && <td >VES</td> }
          </tr>
          <tr>
            <td>grupos</td>
            {gruposTds}
          </tr>


          {filas}
          <tr><td>Total</td><td colSpan={primeroLength+segundoLength+terceroLength}></td>
          <td>{totalHorasAsigs}</td>
          <td>{totalsumResult}</td>
          <td>{totalresultProfesPorAsig}</td>
          </tr>
        </tbody>
      </table>
      <table className="tabla-dis-doc">
        <thead>
            <tr>
                <th colSpan={4+primeroLength+segundoLength+terceroLength}>Column 1</th>
             
            </tr>
        </thead>
        <tbody>
<tr><td>Asignatura</td>
<td>Id Docente</td>
<td>Horas Excedidas</td>
</tr>
          {resultNombreToProfs.map((prof,i)=>{
            console.log('profsSinHorasArr', profsSinHorasArr)
            console.log('prof', prof,i)


            return (
              <tr>
              <td>{prof.nombrePuesto}</td>
              <td >{prof.idProfesor}</td>
              <td>{prof.horasExcedidas}</td>
             
          </tr>
            )
          })}
           
           
        </tbody>
    </table>
      </>
      : <></>}
    </div>
  );
};

export default RepGenTablaDistDoc;
