import { connect } from "react-redux";
import {
  actualizarProfesor,
  altaProfesor,
  bajaProfesor,
  getClaveCentroTrabajo,
  getGrupos,
  getMaterias,
  getMotivos,
  getPuestos,
  getTipoLicencia,
  validaCURP,
  getZonas,
  getSubZonas,
} from "../actions/escuela-action";
import ReporteGeneral from "../page/personal/ReporteGeneral";

const mapStateToProps = (state) => ({
  materias: state.escuela.materias,
  materiasMap: state.escuela.materiasMap,
  puestos: state.escuela.puestos,
  grupos: state.escuela.grupos,
  motivos: state.escuela.motivos,
  clavesCentroTrabajo: state.escuela.clavesCentroTrabajo,
  tipoLicencia: state.escuela.tipoLicencia,
  gruposMap: state.escuela.gruposMap,
});

const mapDispatchToProps = (dispatch, { datosProfesor }) => {
  return {
    init: () => {
      dispatch(getMaterias());
      dispatch(getPuestos());
      // dispatch(getTurno());
      dispatch(getGrupos());
      // dispatch(getHoras());
      // dispatch(getCategorias());
      dispatch(getClaveCentroTrabajo());
      dispatch(getTipoLicencia());
      // dispatch(getEstadoCivil());
      dispatch(getMotivos());
      // dispatch(getNumeroEscuela());
      // dispatch(getCarreraMagisterial());
      // dispatch(getZonas());
      // if (datosProfesor?.idProfesor)
      //   dispatch(getSubZonas(datosProfesor.idClaveZona));
      // dispatch(getTipoTitulo());
      // dispatch(getTipoForte());
      // dispatch(getQuinquenio());
      // dispatch(getIncentivo());
    },
    altaProfesor: (json, resultadoAsignaturas) =>
      dispatch(altaProfesor(json, resultadoAsignaturas)),
    bajaProfesor: (curp, cargo, openToast, idClaveZonaCentroTrabajo) =>
      dispatch(bajaProfesor(curp, cargo, openToast, idClaveZonaCentroTrabajo)),
    actualizarProfesor: (json, resultadoAsignaturas) =>
      dispatch(actualizarProfesor(json, resultadoAsignaturas)),
    validaCURP: (curp) => dispatch(validaCURP(curp)),
    getSubZonas: (idClaveZona) => dispatch(getSubZonas(idClaveZona)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReporteGeneral);
