import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import AltaPersonal from "../../containers/altaContainer";
import { validateFechas } from "../../helpers";
import { getPuestos } from "../../actions/escuela-action";
import { Input, Col, Row, Space } from "antd";

const { Search } = Input;
export const procesaDatosProfesor = (data) => {
  const [situacionLaboralTipo] = data.situacionLaboral?.split(",") || [];

  return {
    ...data,
    claveCentroDeTrabajo: data.claveCentroDeTrabajo
      ?.split(",")
      .map((m) => parseInt(m)),
    situacionLaboralTipo: parseInt(situacionLaboralTipo),
  };
};

const Actualizaciones = (props) => {
  // const [search, setsearch] = useState('');
  const [isProfesores, setIsProfesores] = useState(false);
  const [datosProfesor, setDatosProfesor] = useState({});
  const [asignaturas, setAsignaturas] = useState({});
  const [isLoading, setisLoading] = useState(false);
  const puestos = useSelector((state) => state.escuela.puestos);
  const dispatch = useDispatch();




  //        COLT790406MMSRNR00 mercedes flores leon
  const userData = sessionStorage.getItem("userAccessData");
const userAccessDataObj = JSON.parse(userData)
  console.log('userAccessDataRG', userAccessDataObj)
  // const {idClaveZona,escuela} = userAccessDataObj;
   const userIdClaveZonasCentroTrabajo = userAccessDataObj.idClaveZonaCentroTrabajo;
  // const userIdClaveZonasCentroTrabajo = "1,2";
  
  console.log('userIdClaveZonasCentroTrabajo', typeof userIdClaveZonasCentroTrabajo);

  useEffect(() => {
    dispatch(getPuestos());
  }, []);

  const procesaProfesor = async (profesorData) => {
    const { idProfesor, error, info, cargo } = profesorData;

    const llevaAsignaturas = puestos.find(
      (puesto) => puesto.idPuesto === cargo
    )?.llevaAsignaturas;
    console.log('llevaAsignaturasnombre', llevaAsignaturas)
    const procesa = procesaDatosProfesor({ ...profesorData, llevaAsignaturas });
    setDatosProfesor(validateFechas(procesa));
    const asignaturas = await axios.get(
      `https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/getAsignaturaProfesoresByIdProfesor?json=[{"idProfesor":${idProfesor}}]`
    );
    setAsignaturas(asignaturas.data);
  };

  const handleSearch = async (search) => {
    console.log('search', search,userIdClaveZonasCentroTrabajo);
    setAsignaturas({});
    setisLoading(true);
    const profesorData = await axios.get(
      `https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/getProfesoresByCURP?json=[{"curp":${search},"idClaveZonaCentroTrabajo":"${userIdClaveZonasCentroTrabajo}"}]`
    );

    // const filteredData= profesorData.filter((d) => {
    //   return d.estatusRegistro  !== "Eliminado" 
    // })

    const { idProfesor, error, info, cargo } =
      profesorData?.data?.length === 1
        ? profesorData?.data[0]
        : profesorData?.data;
    if (profesorData.data.length === 0) { notify('CURP no existe') }
    if (error) {
      notify(`${info}: ${search}`);
      setDatosProfesor({});
      setisLoading(false);
      return null;
    }

    if (idProfesor) {
      console.log('puestosa', idProfesor)
      const llevaAsignaturas = puestos.find(
        
        (puesto) => {
          console.log('puestob', puesto.nombre,cargo)
         return puesto.idPuesto === cargo}//nombre por idPuesto
      )?.llevaAsignaturas;

      console.log('llevaAsignaturas', llevaAsignaturas)
      const procesa = procesaDatosProfesor({
        ...profesorData.data[0],
        llevaAsignaturas,
      });
      setDatosProfesor(validateFechas(procesa));
      const asignaturas = await axios.get(
        `https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/getAsignaturaProfesoresByIdProfesor?json=[{"idProfesor":${idProfesor}}]`
      );
      setAsignaturas(asignaturas.data);
    } else if (profesorData?.data.length > 1) {
      setDatosProfesor(profesorData?.data);
      setIsProfesores(true);
    }
    setisLoading(false);
  };
  const notify = (info) => toast.dark(info);
  return (
    <Row>
      <Space>
        {/* <h1>CURP :  </h1> */}

        <Col flex="470px">
          {/* <input value={search} onChange={({ target }) => setsearch(target.value)} /> */}
          {/* <button type='button' onClick={handleSearch}>Buscar</button> */}
          <Search
            placeholder="Inserta CURP"
            allowClear
            enterButton="Buscar"
            size="large"
            onSearch={handleSearch}
          />
          {Object.keys(datosProfesor).length > 0 &&
            Object.keys(asignaturas).length > 0 &&
            !isLoading && (
              <AltaPersonal
                path={props.location.pathname}
                setDatosProfesor={setDatosProfesor}
                datosProfesor={datosProfesor}
                asignaturas={asignaturas}
              />
            )}
          {isProfesores && (
            <table width="100%">
              <tr>
                <th>Nombre</th>
                <th>Clave Centro de Trabajo</th>
                <th></th>
              </tr>

              <tbody>
                {datosProfesor?.map((profe) => {
                  const {
                    nombre,
                    apellidoPaterno,
                    apellidoMaterno,
                    // idClaveZonaCentroTrabajo,
                    claveCentroDeTrabajo,estatusRegistro
                  } = profe;

                  return (
                    <tr>
                      <td>{`${nombre} ${apellidoPaterno} ${apellidoMaterno} `}</td>
                      <td>{claveCentroDeTrabajo}</td>
                      <td>
                        <button
                          onClick={() => {
                            procesaProfesor(profe);
                            setIsProfesores(false);
                          }}
                        >
                          Seleccionar
                        </button>
                      </td>
                      <td><button className={estatusRegistro}>{estatusRegistro}</button></td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </Col>
      </Space>
    </Row>
  );
};

export default Actualizaciones;
