import React from 'react'

function ReporteInspeccion() {
  return (
    <div>
      report inspection
    </div>
  )
}

export default ReporteInspeccion
