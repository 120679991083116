import { connect } from "react-redux";
import { actualizarProfesor, altaProfesor, bajaProfesor, getCarreraMagisterial, getCategorias, getClaveCentroTrabajo, getEstadoCivil, getGrupos, getHoras, getMaterias, getMotivos, getNumeroEscuela, getPuestos, getTipoLicencia, getTurno, validaCURP, getZonas, getSubZonas, getTipoTitulo, getTipoForte, getQuinquenio, getIncentivo } from "../actions/escuela-action";
import Asignatura from "../page/personal/Asignatura";



const mapStateToProps = (state) => ({
  materias: state.escuela.materias,
  puestos: state.escuela.puestos,
  turnos: state.escuela.turnos,
  grupos: state.escuela.grupos,
  horas: state.escuela.horas,
  motivos: state.escuela.motivos,
  clavesCentroTrabajo: state.escuela.clavesCentroTrabajo,
  tipoLicencia: state.escuela.tipoLicencia,
  estadoCivil: state.escuela.estadoCivil,
  categorias: state.escuela.categorias,
  gruposMap: state.escuela.grupos.reduce((a, { idGrupo, nombre }) => ({ ...a, [idGrupo]: nombre }), {}),
  numeroEscuela: state.escuela.numeroEscuela,
  carreraMagisterial: state.escuela.carreraMagisterial,
  zonas: state.escuela.zonas,
  subZonas: state.escuela.subZonas,
  forte: state.escuela.forte,
  titulo: state.escuela.titulo,
  quinquenio: state.escuela.quinquenio,
  incentivo: state.escuela.incentivo
});

const mapDispatchToProps = (dispatch, { datosProfesor }) => {
  return ({
    init: () => {
      dispatch(getMaterias());
      dispatch(getPuestos());
      dispatch(getTurno());
      dispatch(getGrupos());
      dispatch(getHoras());
      dispatch(getCategorias());
      dispatch(getClaveCentroTrabajo());
      dispatch(getTipoLicencia());
      dispatch(getEstadoCivil());
      dispatch(getMotivos());
      dispatch(getNumeroEscuela());
      dispatch(getCarreraMagisterial());
      dispatch(getZonas());
      if (datosProfesor?.idProfesor)
        dispatch(getSubZonas(datosProfesor.idClaveZona));
      dispatch(getTipoTitulo());
      dispatch(getTipoForte());
      dispatch(getQuinquenio());
      dispatch(getIncentivo());
    },
    altaProfesor: (json, resultadoAsignaturas) => dispatch(altaProfesor(json, resultadoAsignaturas)),
    bajaProfesor: (curp,cargo, openToast, idClaveZonaCentroTrabajo) => dispatch(bajaProfesor(curp,cargo, openToast, idClaveZonaCentroTrabajo)),
    actualizarProfesor: (json, resultadoAsignaturas) => dispatch(actualizarProfesor(json, resultadoAsignaturas)),
    validaCURP: (curp) => dispatch(validaCURP(curp)),
    getSubZonas: (idClaveZona) => dispatch(getSubZonas(idClaveZona))
  })
};


export default connect(mapStateToProps, mapDispatchToProps)(Asignatura);