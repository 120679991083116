import "./AltaCentroTrabajo.scss";
import { useState, useEffect } from "react";
import moment from "moment";
import {
  getIdentificacionCentroTrabajo,
  getInfraestructuraCentroTrabajo,
} from "../../actions/escuela-action";
import plantillaPersonalEscuela from "../../images/PlantillaPersonalEscuela.jpeg";
import axios from "axios";
import { toast } from "react-toastify";
import Supervision from "./Supervision";
import { Form } from "antd";
const AltaCentroTrabajo = (props) => {
  const { datosProfesores, insertaNumeroAlumnos } = props;
  const {
    estructuraOcupacional,
    numeroDeAlumnos,
    identificacion,
    clavesZonasCentroTrabajoById,
    infraestructura,
    talleresCentrosTrabajo,
    gruposCentroTrabajo,
    artisticas,
  } = datosProfesores;

  const [identificacionValues, setidentificacionValues] =
    useState(identificacion);
  const [infraestructuraValues, setinfraestructuraValues] =
    useState(infraestructura)

  const [selectValues, setSelectValues] = useState({
    priMat: 0,
    segMat: "",
    terMat: "",
    priVes: 0,
    segVes: "",
    terVes: "",
  });

  const [numeroAlumnosValues, setNumeroAlumnosValues] = useState(
    numeroDeAlumnos?.estatus
      ? numeroDeAlumnos
      : {
        matPrimerH: 0,
        vespPrimerH: 0,
        matPrimerM: 0,
        vespPrimerM: 0,
        matSegundoH: 0,
        vespSegundoH: 0,
        matSegundoM: 0,
        vespSegundoM: 0,
        matTercerH: 0,
        vespTercerH: 0,
        matTercerM: 0,
        vespTercerM: 0,
      }
  );
  const [editableGruposCentroTrabajo, setEditableGruposCentroTrabajo] =
    useState(false);
  const [editableIdentificacion, setEditableIdentificacion] = useState(false);
  const [editableInfraestructura, setEditableInfraestructura] = useState(false);
  const [editableArtisticas, setEditableArtisticas] = useState(false);
  const [editableTalleres, setEditableTalleres] = useState(false);
  const [editableTalleresInit, setEditableTalleresInit] = useState(false);
  const [editableNumAlumInit, setEditableNumAlumInit] = useState(false);
  const [editableNumAlum, setEditableNumAlum] = useState(false);

  const [editable, setEditable] = useState(false);
  const { priMat, segMat, terMat, priVes, segVes, terVes } = selectValues;
  const {
    matPrimerH,
    vespPrimerH,
    matPrimerM,
    vespPrimerM,
    matSegundoH,
    vespSegundoH,
    matSegundoM,
    vespSegundoM,
    matTercerH,
    vespTercerH,
    matTercerM,
    vespTercerM,
    idClaveZonaCentroTrabajo,
  } = numeroAlumnosValues;

  const [artValues, setArtValues] = useState(artisticas);
  const [estructuraOcupacionalValues, setestructuraOcupacionalValues] =
    useState(estructuraOcupacional || []);
  const [talleresValues, setTalleresValues] = useState([]);
  const [gruposCentroTrabajoValues, setGruposCentroTrabajoValues] = useState(
    gruposCentroTrabajo?.length === 1
      ? gruposCentroTrabajo
      : [
        {
          matutino_1: 0,
          matutino_2: 0,
          matutino_3: 0,
          vespertino_1: 0,
          vespertino_2: 0,
          vespertino_3: 0,
          total_1: 0,
          total_2: 0,
          total_3: 0,
        },
      ]
  );
  const [
    supervisor,
    jefeDeEnsenanza,
    asesorTecnicoPedagogico,
    director,
    subDirectorDeGestion,
    subdirectorAcademico,
    coordinadorAcademicas,
    coordinadorTecnologicas,
    maestroEnsenanza,
    maestroIngles,
    maestroTecnologia,
    maestroEduFisica,
    maestroArtistica,
    maestroAula,
    maestroTaller,
    medicoEscolar,
    prefecto,
    ayudanteLab,
    trabajadoraSocial,
    contralor,
    secretariaApoyo,
    bibliotecario,
    tecnicoMantenimiento,
    asistenteAlmacen,
    intendente,
    velador,
    tecnicoMedio,
  ] = estructuraOcupacionalValues;
  const [objGruposCT] = gruposCentroTrabajoValues;
  const {
    matutino_1,
    matutino_2,
    matutino_3,
    vespertino_1,
    vespertino_2,
    vespertino_3,
    total_1,
    total_2,
    total_3,
  } = objGruposCT;
  const handleWordInput = (e) => {
    const regExp = e.target.attributes.regExpType.value;
    const regExpTypes = {
      alphabet: /^[a-záéíóúüñÑÁÉÍÓÚçA-Z\s]*$/,
      numbers: /^\d+$/,
    };
    const re = regExpTypes[regExp];

    const { value } = e.target;
    const { turno, name } = e.target.attributes;
    let editedText = value;
    if (!re.test(value)) {
      editedText = editedText.slice(0, -1);
    }
    const parsedValue = editedText === "" ? editedText : parseInt(editedText)
    const newTallerObj = talleresValues.map((obj) => {
      if (obj.nombre === name.value) {
        return { ...obj, [turno.value]: parsedValue };
      }

      return obj;
    });
    setEditableTalleresInit(true);
    setTalleresValues(newTallerObj);
  };

  const handleTalleresSubmit = async (e) => {
    e.preventDefault();

    const result = talleresValues.map(({ nombre, total, ...rest }) => ({
      ...rest,
    }));

    const test = JSON.stringify(result);
    axios
      .get(
        `https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/updateTallererCentrosTrabajo?json=${test}`
      )
      .then(function (response) {
        // handle success
        notify("¡Datos Actualizados!");
        console.log(response);
        setEditableNumAlum(false);
      })
      .catch(function (error) {
        // handle error
        notify("¡Datos no actualizados!");
        console.log(error);
      });
  };

  useEffect(() => {
    setNumeroAlumnosValues(numeroDeAlumnos);
    setTalleresValues(talleresCentrosTrabajo);
    // setidentificacionValues(identificacion)
    props.setisLoading(false);
    props.setIsImpresionButtonGrayedOut(false);
  }, []);

  useEffect(() => {
    if (editableNumAlumInit) {
      const numeroArray = Object.values(numeroAlumnosValues);
      const canEdit = numeroArray.every((cv) => {
      });
      // cv !== 0 && cv === cv
      // const isNotZeroOrEmptyValue = (currentValue)=>
      // const isZero= value === 0 ? false : true;
      setEditableNumAlum(canEdit);
    }
  }, [numeroAlumnosValues]);

  useEffect(() => {
    if (editableTalleresInit) {
      const canEdit = talleresValues.every((cv) => {
        const isValidMatutino =
          !isNaN(cv.matutino) && cv.matutino >= 0 && cv.matutino !== "";
        const isValidVespertino =
          !isNaN(cv.vespertino) && cv.vespertino >= 0 && cv.vespertino !== "";

        return isValidMatutino && isValidVespertino;
      });
      // cv !== 0 && cv === cv
      // const isNotZeroOrEmptyValue = (currentValue)=>
      // const isZero= value === 0 ? false : true;
      setEditableTalleres(canEdit);
    }
  }, [talleresValues]);

  const handleImprimir = () => {
    window.print();
  };
  useEffect(
    (e) => {
      const priMat = matutino_1;
      const priVes = vespertino_1;
      let nombreTotalTurno = "";
      let valueTotal = 0;

      if (priMat || priVes) {
        nombreTotalTurno = "total_1";
        valueTotal = priMat + priVes;
      }

      const objTotal = nombreTotalTurno
        ? { [nombreTotalTurno]: valueTotal }
        : {};
      setGruposCentroTrabajoValues([
        { ...gruposCentroTrabajoValues[0], ...objTotal },
      ]);
    },
    [matutino_1, vespertino_1]
  );
  useEffect(
    (e) => {
      const priMat = matutino_2;
      const priVes = vespertino_2;
      let nombreTotalTurno = "";
      let valueTotal = 0;

      if (priMat || priVes) {
        nombreTotalTurno = "total_2";
        valueTotal = priMat + priVes;
      }

      const objTotal = nombreTotalTurno
        ? { [nombreTotalTurno]: valueTotal }
        : {};
      setGruposCentroTrabajoValues([
        { ...gruposCentroTrabajoValues[0], ...objTotal },
      ]);
    },
    [matutino_2, vespertino_2]
  );
  useEffect(
    (e) => {
      const priMat = matutino_3;
      const priVes = vespertino_3;
      let nombreTotalTurno = "";
      let valueTotal = 0;

      if (priMat || priVes) {
        nombreTotalTurno = "total_3";
        valueTotal = priMat + priVes;
      }

      const objTotal = nombreTotalTurno
        ? { [nombreTotalTurno]: valueTotal }
        : {};
      setGruposCentroTrabajoValues([
        { ...gruposCentroTrabajoValues[0], ...objTotal },
      ]);
    },
    [matutino_3, vespertino_3]
  );

  //cambio simple para quitar error de deploy
  const handleGruposValues = (e) => {
    const { name } = e.target;
    const value = parseInt(e.target.value);
    setGruposCentroTrabajoValues([
      { ...gruposCentroTrabajoValues[0], [name]: value },
    ]);
    setEditableGruposCentroTrabajo(true);
  };
  const handleIdentificacionValues = (e) => {
    const name = e.target.attributes.name.value;
    const value = e.target.value;
    setEditableIdentificacion(true);
    setidentificacionValues({ ...identificacionValues, [name]: value });
    setEditableIdentificacion(true);
  };
  const handleInfraestructuraValues = (e) => {
    const name = e.target.attributes.name.value;
    const value = e.target.value;
    setEditableInfraestructura(true);
    setinfraestructuraValues({ ...infraestructuraValues, [name]: name === "aulas" ? parseInt(value) : value });
    setEditableInfraestructura(true);
  };
  const handleNumeroAlumnosValues = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setEditableNumAlum(true);

    const value1 =
      (value && !isNaN(value) && value > 0 && parseInt(value)) || null;
    setNumeroAlumnosValues({ ...numeroAlumnosValues, [name]: value1 });
  };

  const handleartValues = (e) => {
    const name = e.target.name;
    const artValues2 = e.target.checked;
    setArtValues({ ...artValues, [name]: +artValues2 });
    setEditableArtisticas(true);
  };

  const notify = (info) => toast.dark(info);

  const onSubmitIdentificacion = (e) => {
    e.preventDefault();
    const result = JSON.stringify(identificacionValues);

    axios
      .post(
        `https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/updateIdentificacionCentroTrabajo?json=[${result}]`
      )
      .then(function (response) {
        // handle success
        notify("¡Datos Actualizados!");
        console.log(response);
        setEditableIdentificacion(false);
      })
      .catch(function (error) {
        // handle error
        notify("¡Datos no Actualizados!");
        console.log(error);
      });
  };
  const onSubmitInfraestructura = (e) => {
    e.preventDefault();
    // const key = "idClaveZonaCentroTrabajo";
    const newInfraestructuraValues = infraestructuraValues;
    //object keys   v = aula return ternary  parseInt  
    // const otros = 0
    // delete newInfraestructuraValues[key];

    const cct = clavesZonasCentroTrabajoById.claveCentroTrabajo;
    console.log('eInfra', { ...newInfraestructuraValues, cct })

    const result = JSON.stringify({ ...newInfraestructuraValues });


    axios
      .post(
        `https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/updateInfraestructura?json=[${result}]`
      )
      .then(function (response) {
        // handle success
        notify("¡Datos Actualizados!");
        console.log(response);
        setEditableInfraestructura(false);
      })
      .catch(function (error) {
        // handle error
        notify("¡Datos no Actualizados!");
        console.log(error);
      });
  };

  const onSubmitGradosCentroTrabajo = (e) => {
    e.preventDefault();
    const result = JSON.stringify(gruposCentroTrabajoValues);
    axios
      .post(
        `https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/updateGrupoCentroTrabajo?json=${result}`
      )
      .then(function (response) {
        // handle success
        notify("¡Datos Actualizados!");
        console.log(response);
        setEditableNumAlum(false);
      })
      .catch(function (error) {
        // handle error
        notify("!Datos no Actualizados!");
        console.log(error);
      });
    setEditableGruposCentroTrabajo(false);
  };
  const onSubmitNumeroAlumnos = (e) => {
    e.preventDefault();
    console.log("e", e);
    axios
      .post(
        `https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/updateNumeroAlumnosSexoGradoTurno?json=[{"idClaveZonaCentroTrabajo":${idClaveZonaCentroTrabajo},"matPrimerH":${matPrimerH},"matPrimerM":${matPrimerM},"matSegundoH":${matSegundoH},"matSegundoM":${matSegundoM},"matTercerH":${matTercerH},"matTercerM":${matTercerM},"vespPrimerH":${vespPrimerH},"vespPrimerM":${vespPrimerM},"vespSegundoH":${vespSegundoH},"vespSegundoM":${vespSegundoM},"vespTercerH":${vespTercerH},"vespTercerM":${vespTercerM}}]`
      )
      .then(function (response) {
        // handle success
        notify("¡Datos Actualizados!");
        console.log(response);
        setEditableNumAlum(false);
      })
      .catch(function (error) {
        // handle error
        notify("¡Datos no Actualizados!");
        console.log(error);
      });

  };
  const onSubmitArt = (e) => {
    e.preventDefault();
    const result = JSON.stringify(artValues);


    axios
      .post(
        `https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/updatetArtisticas?json=[${result}]`
      )
      .then(function (response) {
        // handle success
        notify("¡Datos Actualizados!");
        console.log(response);
        setEditableArtisticas(false);
      })
      .catch(function (error) {
        // handle error
        notify("¡Datos no Actualizados!");
        console.log(error);
      });

  };

  const { elaboro, autorizo, vistoBueno, dest } =
    props.datosProfesores?.identificacion || {};

  const agregaNumeroAlumnos = () => {
    const areAllSet = Object.values(numeroAlumnosValues).every((iv) => iv);

    return areAllSet
      ? insertaNumeroAlumnos({
        idClaveZonaCentroTrabajo: props.idClaveZonaCentroTrabajo.toString(),
        ...numeroAlumnosValues,
      })
      : toast.dark("Todos deben tener numero de horas...");
  };

  return (
    <>
      <div className="tabla-alta-centro" id="tabla">
        {/*comment here*/}
        <table className="tabla-alta-centro">
          <thead>
            <tr>
              <td colSpan={4}>
                <img
                  alt="plantillaPersonalEscuela"
                  // width="2215px"
                  // height="425px"
                  src={plantillaPersonalEscuela}
                />
              </td>
            </tr>
          </thead>
          <tr>
            <td className="wrapper">
              <form
                onSubmit={onSubmitIdentificacion}
                className="tabla-alta-centro-1 w-identificacion"
              >
                <table className="identificacion-alta-centro">
                  <thead>
                    <tr>
                      <th colspan="10">IDENTIFICACIÓN DEL CENTRO DE TRABAJO</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td width="380px">NOMBRE</td>
                      <td width="200px">C.C.T.</td>
                      <td width="300">DIRECCIÓN</td>
                      <td width="200">LOCALIDAD</td>
                      <td width="200">MUNICIPIO</td>
                      <td width="200px">C.P.</td>
                      <td width="200px">TELÉFONO</td>
                      <td width="200">FECHA DE FUNDACIÓN</td>
                      <td width="200px">INSPECCIÓN</td>
                      <td width="200px">E-MAIL</td>
                    </tr>
                    <tr

                    // height="50px"
                    >
                      <td>
                        <input
                          type="text"
                          name="nombreEscuela"
                          onChange={handleIdentificacionValues}
                          value={identificacionValues.nombreEscuela}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="idClaveZonaCentroTrabajo"
                          onChange={handleIdentificacionValues}
                          value={
                            clavesZonasCentroTrabajoById.claveCentroTrabajo
                          }
                        />
                      </td>
                      <td width="180px">
                        <textarea
                          type="text"
                          name="direccion"
                          onChange={handleIdentificacionValues}
                          value={identificacionValues.direccion}
                        />
                      </td>
                      <td>
                        <textarea
                          type="text"
                          name="localidad"
                          onChange={handleIdentificacionValues}
                          value={identificacionValues.localidad}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="municipio"
                          onChange={handleIdentificacionValues}
                          value={identificacionValues.municipio}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="codigoPostal"
                          onChange={handleIdentificacionValues}
                          value={identificacionValues.codigoPostal}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="telefono"
                          onChange={handleIdentificacionValues}
                          value={identificacionValues.telefono}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="fechaFundacion"
                          onChange={handleIdentificacionValues}
                          value={identificacionValues.fechaFundacion}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="supervision"
                          onChange={handleIdentificacionValues}
                          value={clavesZonasCentroTrabajoById.supervision}
                        />
                      </td>
                      <td>
                        <textarea
                          type="text"
                          name="correoElectronico"
                          onChange={handleIdentificacionValues}
                          value={identificacionValues.correoElectronico}
                        />
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    {identificacion && (
                      <button
                        style={
                          editableIdentificacion
                            ? { cursor: "pointer" }
                            : { pointerEvents: "none", opacity: "0.5" }
                        }
                        type="submit"
                      >
                        Actualizar
                      </button>
                    )}
                  </tfoot>
                </table>
              </form>
              <form
                onSubmit={onSubmitInfraestructura}
                className="tabla-alta-centro-1 w-infraestructura"
              >
                <table>
                  <thead>
                    <tr>
                      <th colspan="13">INFRAESTRUCTURA</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>AULAS</td>
                      <td>TALLERES</td>
                      <td>LABORATORIOS</td>
                      <td>RED ESCOLAR</td>
                      <td>AULA DE MEDIOS</td>
                      <td>SALA DE CÓMPUTO</td>
                      <td>EDUSAT</td>
                      <td>PREFECTURA</td>
                      <td>BIBLIOTECA</td>
                      <td>TRABAJO SOCIAL</td>
                      <td>ALMACÉN</td>
                      <td>CANCHAS</td>
                      <td>OTROS</td>
                    </tr>
                    <tr>
                      <td>
                        <input type="number" name="aulas" onChange={handleInfraestructuraValues} value={infraestructuraValues.aulas} />
                      </td>
                      <td>
                        <input type="text" name="talleres" onChange={handleInfraestructuraValues} value={infraestructuraValues.talleres} />
                      </td>
                      <td>
                        <input type="text" name="laboratorios" onChange={handleInfraestructuraValues} value={infraestructuraValues.laboratorios} />
                      </td>
                      <td>
                        <input type="text" name="redEscolar" onChange={handleInfraestructuraValues} value={infraestructuraValues.redEscolar} />
                      </td>
                      <td>
                        <input type="text" name="aulaMedios" onChange={handleInfraestructuraValues} value={infraestructuraValues.aulaMedios} />
                      </td>
                      <td>
                        <input type="text" name="salaComputo" onChange={handleInfraestructuraValues} value={infraestructuraValues.salaComputo} />
                      </td>
                      <td>
                        <input type="text" name="edusat" onChange={handleInfraestructuraValues} value={infraestructuraValues.edusat} />
                      </td>
                      <td>
                        <input type="text" name="prefectura" onChange={handleInfraestructuraValues} value={infraestructuraValues.prefectura} />
                      </td>
                      <td>
                        <input type="text" name="biblioteca" onChange={handleInfraestructuraValues} value={infraestructuraValues.biblioteca} />
                      </td>
                      <td>
                        <input type="text" name="trabajoSocial" onChange={handleInfraestructuraValues} value={infraestructuraValues.trabajoSocial} />
                      </td>
                      <td>
                        <input type="text" name="almacen" onChange={handleInfraestructuraValues} value={infraestructuraValues.almacen} />
                      </td>
                      <td>
                        <input type="text" name="canchas" onChange={handleInfraestructuraValues} value={infraestructuraValues.canchas} />
                      </td>
                      <td>
                        <input type="text" name="otros" onChange={handleInfraestructuraValues} value={infraestructuraValues.otros} />
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    {infraestructura && (
                      <button
                        style={
                          editableInfraestructura
                            ? { cursor: "pointer" }
                            : { pointerEvents: "none", opacity: "0.5" }
                        }
                        type="submit"
                      >
                        Actualizar
                      </button>
                    )}
                  </tfoot>
                </table>
              </form>

              <form className="tabla-alta-centro-1 w-estructura-ocupacional">
                <table>
                  <thead>
                    <tr>
                      <th colspan="10">ESTRUCTURA OCUPACIONAL</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>PUESTO</td>
                      <td>MAT</td>
                      <td>VES</td>
                      <td>DISC</td>
                      <td>HE</td>
                      <td>PUESTO</td>
                      <td>MAT</td>
                      <td>VES</td>
                      <td>DISC</td>
                      <td>HE</td>
                    </tr>
                    <tr>
                      <td>{director.nombre}</td>
                      <td>{director.totalM}</td>
                      <td>{director.totalV}</td>
                      <td>{director.totalD}</td>
                      <td>{director.totalE}</td>
                      {/* <td>{supervisor.nombre}</td>
                      <td>{supervisor.totalM}</td>
                      <td>{supervisor.totalV}</td> 
                      <td>{supervisor.totalE}</td>
                      */}

<td>{trabajadoraSocial.nombre}</td>
                     <td>{trabajadoraSocial.totalM}</td>
                     <td>{trabajadoraSocial.totalV}</td>
                     <td>{trabajadoraSocial.totalD}</td>
                     <td>{trabajadoraSocial.totalE}</td>

                    </tr>
                    <tr>
                      <td>{subDirectorDeGestion.nombre}</td>
                      <td>{subDirectorDeGestion.totalM}</td>
                      <td>{subDirectorDeGestion.totalV}</td>
                      <td>{subDirectorDeGestion.totalD}</td>
                      <td>{subDirectorDeGestion.totalE}</td>
                      {/* <td>{jefeDeEnsenanza.nombre}</td>
                      <td>{jefeDeEnsenanza.totalM}</td>
                      <td>{jefeDeEnsenanza.totalV}</td>
                      <td></td>
                      <td>{jefeDeEnsenanza.totalE}</td> */}
                      <td>{medicoEscolar.nombre}</td>
                      <td>{medicoEscolar.totalM}</td>
                      <td>{medicoEscolar.totalV}</td>
                      <td>{medicoEscolar.totalD}</td>
                      <td>{medicoEscolar.totalE}</td>
                    </tr>
                   
                   
                    <tr>


                    </tr>
                    <tr>
                      <td>{subdirectorAcademico.nombre}</td>
                      <td>{subdirectorAcademico.totalM}</td>
                      <td>{subdirectorAcademico.totalV}</td>
                      <td>{subdirectorAcademico.totalD}</td>
                      <td>{subdirectorAcademico.totalE}</td>
                    
                      <td>{prefecto.nombre}</td>
                     <td>{prefecto.totalM}</td>
                     <td>{prefecto.totalV}</td>
                     <td>{prefecto.totalD}</td>
                     <td>{prefecto.totalE}</td>
                    </tr>
                    <tr>
                      <td>{coordinadorAcademicas.nombre}</td>
                      <td>{coordinadorAcademicas.totalM}</td>
                      <td>{coordinadorAcademicas.totalV}</td>
                      <td>{coordinadorAcademicas.totalD}</td>
                      <td>{coordinadorAcademicas.totalE}</td>
                      <td>{ayudanteLab.nombre}</td>
                     <td>{ayudanteLab.totalM}</td>
                     <td>{ayudanteLab.totalV}</td>
                     <td>{ayudanteLab.totalD}</td>
                     <td>{ayudanteLab.totalE}</td>
                     
                    </tr>
                    <tr>
                      <td>{coordinadorTecnologicas.nombre}</td>
                      <td>{coordinadorTecnologicas.totalM}</td>
                      <td>{coordinadorTecnologicas.totalV}</td>
                      <td>{coordinadorTecnologicas.totalD}</td>
                      <td>{coordinadorTecnologicas.totalE}</td>
                      <td>{bibliotecario.nombre}</td>
                      <td>{bibliotecario.totalM}</td>
                      <td>{bibliotecario.totalV}</td>
                      <td>{bibliotecario.totalD}</td>
                      <td>{bibliotecario.totalE}</td>
                    </tr>
                    <tr>
                      <td>{maestroEnsenanza.nombre}</td>
                      <td>{maestroEnsenanza.totalM}</td>
                      <td>{maestroEnsenanza.totalV}</td>
                      <td>{maestroEnsenanza.totalD}</td>
                      <td>{maestroEnsenanza.totalE}</td>
                      <td>{contralor.nombre}</td>
                      <td>{contralor.totalM}</td>
                      <td>{contralor.totalV}</td>
                      <td>{contralor.totalD}</td>
                      <td>{contralor.totalE}</td>
                    
                    </tr>
                    <tr>
                      <td>{maestroIngles.nombre}</td>
                      <td>{maestroIngles.totalM}</td>
                      <td>{maestroIngles.totalV}</td>
                      <td>{maestroIngles.totalD}</td>
                      <td>{maestroIngles.totalE}</td>
                     
                      <td>{secretariaApoyo.nombre}</td>
                      <td>{secretariaApoyo.totalM}</td>
                      <td>{secretariaApoyo.totalV}</td>
                      <td>{secretariaApoyo.totalD}</td>
                      <td>{secretariaApoyo.totalE}</td>


                    </tr>
                    <tr>
                      <td>{maestroTecnologia.nombre}</td>
                      <td>{maestroTecnologia.totalM}</td>
                      <td>{maestroTecnologia.totalV}</td>
                      <td>{maestroTecnologia.totalD}</td>
                      <td>{maestroTecnologia.totalE}</td>

                      <td>{intendente.nombre}</td>
                      <td>{intendente.totalM}</td>
                      <td>{intendente.totalV}</td>
                      <td>{intendente.totalD}</td>
                      <td>{intendente.totalE}</td>
                     


                    </tr>
                    <tr>
                      <td>{maestroEduFisica.nombre}</td>
                      <td>{maestroEduFisica.totalM}</td>
                      <td>{maestroEduFisica.totalV}</td>
                      <td>{maestroEduFisica.totalD}</td>
                      <td>{maestroEduFisica.totalE}</td>
                      <td>{asistenteAlmacen.nombre}</td>
                      <td>{asistenteAlmacen.totalM}</td>
                      <td>{asistenteAlmacen.totalV}</td>
                      <td>{asistenteAlmacen.totalD}</td>
                      <td>{asistenteAlmacen.totalE}</td>
                     
                  

                    </tr>
                    <tr>
                      <td>{maestroArtistica.nombre}</td>
                      <td>{maestroArtistica.totalM}</td>
                      <td>{maestroArtistica.totalV}</td>
                      <td>{maestroArtistica.totalD}</td>
                      <td>{maestroArtistica.totalE}</td>
                     
                      <td>{tecnicoMedio.nombre}</td>
                      <td>{tecnicoMedio.totalM}</td>
                      <td>{tecnicoMedio.totalV}</td>
                      <td>{tecnicoMedio.totalD}</td>
                      <td>{tecnicoMedio.totalE}</td>
                      
                    </tr>
                    <tr>
                      <td>{maestroAula.nombre}</td>
                      <td>{maestroAula.totalM}</td>
                      <td>{maestroAula.totalV}</td>
                      <td>{maestroAula.totalD}</td>
                      <td>{maestroAula.totalE}</td>
                      <td>{velador.nombre}</td>
                      <td>{velador.totalM}</td>
                      <td>{velador.totalV}</td>
                      <td>{velador.totalD}</td>
                      <td>{velador.totalE}</td>
                     
                    
                    </tr>
                    <tr>
                    <td>{maestroTaller.nombre}</td>
                      <td>{maestroTaller.totalM}</td>
                      <td>{maestroTaller.totalV}</td>
                      <td>{maestroTaller.totalD}</td>
                      <td>{maestroTaller.totalE}</td>
                    
                     <td>{tecnicoMantenimiento.nombre}</td>
                      <td>{tecnicoMantenimiento.totalM}</td>
                      <td>{tecnicoMantenimiento.totalV}</td>
                      <td>{tecnicoMantenimiento.totalD}</td>
                      <td>{tecnicoMantenimiento.totalE}</td>
                   </tr>
                  </tbody>
                </table>
              </form>

              {/*NUMERO ALUMNOS*/}

              <div className="w-numero-alumnos">
                <form onSubmit={onSubmitNumeroAlumnos}>
                  <table
                    className="tabla-alta-centro-1 "
                  // style={
                  //   editable ? { pointerEvents: "none", opacity: "0.9" } : {}
                  // }
                  >
                    <thead>
                      <tr>
                        <th className="" rowSpan="3"></th>
                        <th className="" colSpan="16">
                          NÚMERO DE ALUMNOS POR SEXO, GRADO Y TURNO
                        </th>
                      </tr>
                      <tr className="tabla-alta-centro-1">
                        <th colSpan="4">PRIMER GRADO</th>
                        <th colSpan="4">SEGUNDO GRADO</th>
                        <th colSpan="4">TERCER GRADO</th>
                        <th colSpan="4">ALUMNOS ESCUELA</th>
                      </tr>
                      <tr>
                        <th className="">H</th>
                        <th className="">M</th>
                        <th className="" colSpan="2">
                          SUMA
                        </th>
                        <th className="">H</th>
                        <th className="">M</th>
                        <th className="" colSpan="2">
                          SUMA
                        </th>
                        <th className="">H</th>
                        <th className="">M</th>
                        <th className="" colSpan="2">
                          SUMA
                        </th>
                        <th className="">H</th>
                        <th className="">M</th>
                        <th className="" colSpan="2">
                          TOTAL
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="nombre">MATUTINO</td>
                        <td>
                          <input
                            name="matPrimerH"
                            onChange={handleNumeroAlumnosValues}
                            type="number"
                            value={matPrimerH}
                          />
                        </td>
                        <td>
                          <input
                            name="matPrimerM"
                            onChange={handleNumeroAlumnosValues}
                            type="number"
                            value={matPrimerM}
                          />
                        </td>
                        <td name="TotmatPrimerHmatPrimerM" colSpan="2">
                          {matPrimerH + matPrimerM}
                        </td>
                        <td>
                          <input
                            name="matSegundoH"
                            onChange={handleNumeroAlumnosValues}
                            type="number"
                            value={matSegundoH}
                          />
                        </td>
                        <td>
                          <input
                            name="matSegundoM"
                            onChange={handleNumeroAlumnosValues}
                            type="number"
                            value={matSegundoM}
                          />
                        </td>
                        <td name="TotmatSegundoHmatSegundoM" colSpan="2">
                          {matSegundoH + matSegundoM}
                        </td>
                        <td>
                          <input
                            name="matTercerH"
                            onChange={handleNumeroAlumnosValues}
                            type="number"
                            value={matTercerH}
                          />
                        </td>
                        <td>
                          <input
                            name="matTercerM"
                            onChange={handleNumeroAlumnosValues}
                            type="number"
                            value={matTercerM}
                          />
                        </td>
                        <td name="totmatTercerHmatTercerM" colSpan="2">
                          {matTercerH + matTercerM}
                        </td>
                        <td name="totMatHom">
                          {matPrimerH + matSegundoH + matTercerH}
                        </td>
                        <td name="totMatMuj">
                          {matPrimerM + matSegundoM + matTercerM}
                        </td>
                        <td name="totMatHomMuj" colSpan="2">
                          {matPrimerH +
                            matSegundoH +
                            matTercerH +
                            matPrimerM +
                            matSegundoM +
                            matTercerM}
                        </td>
                      </tr>
                      <tr>
                        <td className="nombre">VESPERTINO</td>
                        <td>
                          <input
                            name="vespPrimerH"
                            onChange={handleNumeroAlumnosValues}
                            type="number"
                            value={vespPrimerH}
                          />
                        </td>
                        <td>
                          <input
                            name="vespPrimerM"
                            onChange={handleNumeroAlumnosValues}
                            type="number"
                            value={vespPrimerM}
                          />
                        </td>
                        <td name="TotvespPrimerHvespPrimerM" colSpan="2">
                          {vespPrimerH + vespPrimerM}
                        </td>
                        <td>
                          <input
                            name="vespSegundoH"
                            onChange={handleNumeroAlumnosValues}
                            type="number"
                            value={vespSegundoH}
                          />
                        </td>
                        <td>
                          <input
                            name="vespSegundoM"
                            onChange={handleNumeroAlumnosValues}
                            type="number"
                            value={vespSegundoM}
                          />
                        </td>
                        <td name="TotvespSegundoHvespSegundoM" colSpan="2">
                          {vespSegundoH + vespSegundoM}
                        </td>
                        <td>
                          <input
                            name="vespTercerH"
                            onChange={handleNumeroAlumnosValues}
                            type="number"
                            value={vespTercerH}
                          />
                        </td>
                        <td>
                          <input
                            name="vespTercerM"
                            onChange={handleNumeroAlumnosValues}
                            type="number"
                            value={vespTercerM}
                          />
                        </td>
                        <td name="totvespTercerHvespTercerM" colSpan="2">
                          {vespTercerH + vespTercerM}
                        </td>
                        <td name="totVesHom">
                          {vespPrimerH + vespSegundoH + vespTercerH}
                        </td>
                        <td name="totVesMuj">
                          {vespPrimerM + vespSegundoM + vespTercerM}
                        </td>
                        <td name="totVesHomMuj" colSpan="2">
                          {vespPrimerH +
                            vespSegundoH +
                            vespTercerH +
                            vespPrimerM +
                            vespSegundoM +
                            vespTercerM}
                        </td>
                      </tr>
                      <tr>
                        <td className="nombre">TOTAL</td>
                        <td name="totPriMatVesHom">
                          {matPrimerH + vespPrimerH}
                        </td>
                        <td name="totPriMatVesMuj">
                          {matPrimerM + vespPrimerM}
                        </td>
                        <td name="totPriMatVesHomMuj" colSpan="2">
                          {matPrimerH + vespPrimerH + matPrimerM + vespPrimerM}
                        </td>
                        <td name="totSegMatVesHom">
                          {matSegundoH + vespSegundoH}
                        </td>
                        <td name="totSegMatVesMuj">
                          {matSegundoM + vespSegundoM}
                        </td>
                        <td name="totSegMatVesHomMuj" colSpan="2">
                          {matSegundoH +
                            vespSegundoH +
                            matSegundoM +
                            vespSegundoM}
                        </td>
                        <td name="totTerMatVesHom">
                          {matTercerH + vespTercerH}
                        </td>
                        <td name="totTerMatVesMuj">
                          {matTercerM + vespTercerM}
                        </td>
                        <td name="totTerMatVesHomMuj" colSpan="2">
                          {matTercerH + matTercerM + vespTercerH + vespTercerM}
                        </td>
                        <td name="totMatVesHom">
                          {matPrimerH +
                            vespPrimerH +
                            matSegundoH +
                            vespSegundoH +
                            matTercerH +
                            vespTercerH}
                        </td>
                        <td name="totMatVesMuj">
                          {matPrimerM +
                            matSegundoM +
                            matTercerM +
                            vespPrimerM +
                            vespSegundoM +
                            vespTercerM}
                        </td>
                        <td name="totHomMuj" colSpan="2">
                          {matPrimerH +
                            matSegundoH +
                            matTercerH +
                            matPrimerM +
                            matSegundoM +
                            matTercerM +
                            vespPrimerH +
                            vespSegundoH +
                            vespTercerH +
                            vespPrimerM +
                            vespSegundoM +
                            vespTercerM}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {numeroDeAlumnos.estatus && (
                    <button
                      style={
                        editableNumAlum
                          ? { cursor: "pointer" }
                          : { pointerEvents: "none", opacity: "0.5" }
                      }
                      type="submit"
                    >
                      Actualizar
                    </button>
                  )}
                </form>
              </div>
              <div className=" w-numero-grupos">
                <form onSubmit={onSubmitGradosCentroTrabajo}>
                  <table className="tabla-alta-centro-1 tabla-artisticas">
                    <thead>
                      <tr>
                        <th className="tg-0lax" rowSpan="2"></th>
                        <th colSpan={4} className="tg-0lax">
                          GRUPOS
                        </th>
                      </tr>
                      <tr>
                        <th>1ro</th>
                        <th>2do</th>
                        <th>3ro</th>
                        <th>TOTAL</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="nombre">MATUTINO</td>
                        <td>
                          {" "}
                          <select
                            value={matutino_1}
                            onChange={handleGruposValues}
                            name="matutino_1"
                            id="priMat"
                          >
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                          </select>
                        </td>
                        <td className="tg-0lax">
                          {" "}
                          <select
                            value={matutino_2}
                            onChange={handleGruposValues}
                            name="matutino_2"
                            id="2m"
                          >
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                          </select>
                        </td>
                        <td className="tg-0lax">
                          {" "}
                          <select
                            value={matutino_3}
                            onChange={handleGruposValues}
                            name="matutino_3"
                            id="3m"
                          >
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                          </select>
                        </td>
                        <td className="tg-0lax">
                          {matutino_1 + matutino_2 + matutino_3}
                        </td>
                      </tr>
                      <tr>
                        <td className="nombre">VESPERTINO</td>
                        <td className="tg-0lax">
                          {" "}
                          <select
                            value={vespertino_1}
                            onChange={handleGruposValues}
                            name="vespertino_1"
                            id="1v"
                          >
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                          </select>
                        </td>
                        <td className="tg-0lax">
                          {""}
                          <select
                            value={vespertino_2}
                            onChange={handleGruposValues}
                            name="vespertino_2"
                            id="2v"
                          >
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                          </select>
                        </td>
                        <td className="tg-0lax">
                          {" "}
                          <select
                            value={vespertino_3}
                            onChange={handleGruposValues}
                            name="vespertino_3"
                            id="3v"
                          >
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                          </select>
                        </td>
                        <td className="tg-0lax">
                          {vespertino_1 + vespertino_2 + vespertino_3}{" "}
                        </td>
                      </tr>
                      <tr>
                        <td className="nombre">TOTAL</td>
                        <td>{total_1}</td>
                        <td>{total_2}</td>
                        <td>{total_3}</td>
                        <td>{total_1 + total_2 + total_3}</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      {gruposCentroTrabajo && (
                        <button
                          style={
                            editableGruposCentroTrabajo
                              ? { cursor: "pointer" }
                              : { pointerEvents: "none", opacity: "0.5" }
                          }
                          type="submit"
                        >
                          Actualizar
                        </button>
                      )}
                    </tfoot>
                  </table>
                </form>
              </div>

              <form onSubmit={onSubmitArt} className=" w-artisticas">
                <table className="tabla-alta-centro-art">
                  <thead>
                    <tr>
                      <th colSpan="5">ARTÍSTICAS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>GRADO</td>
                      <td>DANZA</td>
                      <td>MÚSICA</td>
                      <td>TEATRO</td>
                      <td>VISUALES</td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>
                        <input
                          className="arte"
                          onChange={handleartValues}
                          type="checkbox"
                          id="danza1"
                          name="danza1"
                          checked={artValues.danza1}
                        />
                        <label htmlFor="artisticas"></label>
                      </td>
                      <td>
                        {" "}
                        <input
                          className="arte"
                          onChange={handleartValues}
                          type="checkbox"
                          id="musica1"
                          name="musica1"
                          checked={artValues.musica1}
                        />
                        <label htmlFor="artisticas"></label>
                      </td>
                      <td>
                        {" "}
                        <input
                          className="arte"
                          onChange={handleartValues}
                          type="checkbox"
                          id="teatro1"
                          name="teatro1"
                          checked={artValues.teatro1}
                        />
                        <label htmlFor="artisticas"></label>
                      </td>
                      <td>
                        <input
                          className="arte"
                          onChange={handleartValues}
                          type="checkbox"
                          id="visuales1"
                          name="visuales1"
                          checked={artValues.visuales1}
                        />
                        <label htmlFor="artisticas"></label>
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>
                        <input
                          className="arte"
                          onChange={handleartValues}
                          type="checkbox"
                          id="danza2"
                          name="danza2"
                          checked={artValues.danza2}
                        />
                        <label htmlFor="artisticas"></label>
                      </td>
                      <td>
                        <input
                          className="arte"
                          onChange={handleartValues}
                          type="checkbox"
                          id="musica2"
                          name="musica2"
                          checked={artValues.musica2}
                        />
                        <label htmlFor="artisticas"></label>
                      </td>
                      <td>
                        {" "}
                        <input
                          className="arte"
                          onChange={handleartValues}
                          type="checkbox"
                          id="teatro2"
                          name="teatro2"
                          checked={artValues.teatro2}
                        />
                        <label htmlFor="artisticas"></label>
                      </td>
                      <td>
                        {" "}
                        <input
                          className="arte"
                          onChange={handleartValues}
                          type="checkbox"
                          id="visuales2"
                          name="visuales2"
                          checked={artValues.visuales2}
                        />
                        <label htmlFor="artisticas"></label>
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>
                        <input
                          className="arte"
                          onChange={handleartValues}
                          type="checkbox"
                          id="danza3"
                          name="danza3"
                          checked={artValues.danza3}
                        />
                        <label htmlFor="artisticas"></label>
                      </td>
                      <td>
                        <input
                          className="arte"
                          onChange={handleartValues}
                          type="checkbox"
                          id="musica3"
                          name="musica3"
                          checked={artValues.musica3}
                        />
                        <label htmlFor="artisticas"></label>
                      </td>
                      <td>
                        {" "}
                        <input
                          className="arte"
                          onChange={handleartValues}
                          type="checkbox"
                          id="teatro3"
                          name="teatro3"
                          checked={artValues.teatro3}
                        />
                        <label htmlFor="artisticas"></label>
                      </td>
                      <td>
                        {" "}
                        <input
                          className="arte"
                          onChange={handleartValues}
                          type="checkbox"
                          id="visuales3"
                          name="visuales3"
                          checked={artValues.visuales3}
                        />
                        <label htmlFor="artisticas"></label>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    {artisticas && (
                      <button
                        style={
                          editableArtisticas
                            ? { cursor: "pointer" }
                            : { pointerEvents: "none", opacity: "0.5" }
                        }
                        type="submit"
                      >
                        Actualizar
                      </button>
                    )}
                  </tfoot>
                </table>
              </form>

              {/*TECNOLOGIAS*/}
              <div className="tabla-alta-centro-1 w-tecnologias">
                <form onSubmit={handleTalleresSubmit}>
                  <table>
                    <thead>
                      <tr>
                        <th>TECNOLOGÍAS</th>
                        <th>M</th>
                        <th>V</th>
                        <th>TOTAL</th>
                      </tr>
                    </thead>
                    <tbody>
                      {talleresValues.map((taller) => {
                        return (
                          <tr>
                            <td className="nombre">{taller.nombre}</td>
                            <td>
                              <input
                                name={taller.nombre}
                                regExpType="numbers"
                                onChange={handleWordInput}
                                type="text"
                                value={taller.matutino}
                                turno="matutino"
                              />
                            </td>
                            <td>
                              <input
                                name={taller.nombre}
                                regExpType="numbers"
                                onChange={handleWordInput}
                                type="text"
                                value={taller.vespertino}
                                turno="vespertino"
                              />
                            </td>
                            <td>{taller.matutino + taller.vespertino}</td>
                          </tr>
                        );
                      })}
                    </tbody>

                    <tfoot>
                      {clavesZonasCentroTrabajoById && (
                        <button
                          style={
                            editableTalleres
                              ? { cursor: "pointer" }
                              : { pointerEvents: "none", opacity: "0.5" }
                          }
                          type="submit"
                        >
                          Actualizar
                        </button>
                      )}
                    </tfoot>
                  </table>
                </form>
              </div>

              <form
                onSubmit={onSubmitIdentificacion}
                className=" tabla-alta-centro-1 w-firmas"
              >
                <table className="tabla-alta-centro-firmas">
                  <thead>
                    <tr>
                      <th colspan={4}>

                        Fecha de elaboración:  {moment().lang("es").format('LL').toString()}.
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr height="120 px">
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>

                    <tr>
                      <td>
                        <textarea
                          type="text"
                          name="elaboro"
                          onChange={handleIdentificacionValues}
                          value={identificacionValues.elaboro}
                        />
                      </td>
                      <td>
                        <textarea
                          type="text"
                          name="autorizo"
                          onChange={handleIdentificacionValues}
                          value={identificacionValues.autorizo}
                        />
                      </td>
                      <td>
                        <textarea
                          type="text"
                          name="vistoBueno"
                          onChange={handleIdentificacionValues}
                          value={identificacionValues.vistoBueno}
                        />
                      </td>
                      <td>
                        <textarea
                          type="text"
                          name="dest"
                          onChange={handleIdentificacionValues}
                          value={identificacionValues.dest}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>ELABORÓ</td>
                      <td>AUTORIZÓ</td>
                      <td>VO.BO.</td>
                      <td>DEPTO. DE SECUNDARIAS TÉCNICAS</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    {identificacion && (
                      <button
                        style={
                          editableIdentificacion
                            ? { cursor: "pointer" }
                            : { pointerEvents: "none", opacity: "0.5" }
                        }
                        type="submit"
                      >
                        Actualizar
                      </button>
                    )}
                  </tfoot>
                </table>
              </form>
            </td>
          </tr>
        </table>
      </div>
      <div>{/* <Supervision /> */}</div>
    </>
  );
};

export default AltaCentroTrabajo;
