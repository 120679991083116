import { Button, Modal } from 'antd';
import { useState } from 'react';

const ModalConfirm = ({modalVisible, handleOkMC,handleCancelMC, horasRestantes}) => {
 



  return (
    <>
     
      <Modal title="Advertencia : Horas restantes" visible={modalVisible} 
      onOk={handleOkMC} onCancel={handleCancelMC}
      >
        <p>Tienes {horasRestantes} horas restantes</p>
        <p>Las horas restantes serán consideradas como excedidas en la escuela.</p>
      </Modal>
    </>
  );
};

export default ModalConfirm;