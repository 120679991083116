import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import locale from "antd/lib/locale/es_ES";
import RGFSeleccionados from "./RGFSeleccionados";
import RGResultado from "./RGResultado";
import RGLicencias from "./RGLicencias";
import RepGenTablaDistDoc from "./RepGenTablaDistDoc";
import {
  getCentroTrabajoData,
  getInspeccionData,
  getProfesoresByZonasCentroTrabajo,
  getSubZonas,
  getZonas,
  getPuestos,
  getElaboracionCentroTrabajo,
  altaNumeroAlumnos,
} from "../../actions/escuela-action";
import {
  Layout,
  Menu,
  Select,
  Divider,
  Checkbox,
  DatePicker,
  Space,
  Card,
  Radio,
  Row,
  Col,
  Form,
  ConfigProvider,
  Tooltip,
} from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Header, Footer, Sider, Content } = Layout;
const _ = require("lodash");

const ReporteGeneral = (props) => {
  const [asignaturasIds, setasignaturasIds] = useState([]);

  const { materiasMap, gruposMap, tipoLicencia,clavesCentroTrabajo } = props;
console.log('clavesCentroTrabajo', clavesCentroTrabajo)
  // const [puestos, setPuestos] = useState([]);
  const [motivos, setMotivos] = useState([]);
  const [sexo, setSexo] = useState("");
  const [situacion, setSituacion] = useState("");
  const [filteredDataObj, setFilteredDataObj] = useState({});
  const [filteredDataArr, setFilteredDataArr] = useState(null);
  const [reportData, setReportData] = useState([]);
  const [filtersObj, setFiltersObj] = useState(null);
  const [tipoLicenciaSelect, settipoLicenciaSelect] = useState("");
  const [goceSueldo, setGoceSueldo] = useState([]);
  const [noDataFound, setNoDataFound] = useState(false);
  const [spin, setSpin] = useState(false);
  const [muniValue, setMuniValue] = useState(null);
  const [sexoValue, setSexoValue] = useState(null);
  const [masculinoRadio, setMasculinoRadio] = useState(false);
  const [puestoValue, setpuestoValue] = useState(null);
  const [motivoValue, setMotivoValue] = useState(null);
  const [newSelectValue, setNewSelectValue] = useState("");
  const [idClaveZonaCentroTrabajo, setIdClaveZonaCentroTrabajo] = useState("");
  const [numeroEscuela, setNumeroEscuela] = useState(null);
  const [datosProfesores, setDatosProfesores] = useState([]);
  const [profsConAsigsArr, setprofsConAsigsArr] = useState([]);
  const [listaProfsConAsignaturas, setListaProfsConAsignaturas] = useState([]);
  const [asignaturasNombramiento, setAsignaturasNombramiento] = useState([])
  const [TransformedObject, setTransformedObject] = useState({})
  const [claveCentroTrabajoSelected, setclaveCentroTrabajoSelected] = useState(null)
  const [profsSinHorasResult, setProfsSinHorasResult] = useState([])
  const zonas = useSelector((state) => state.escuela.zonas);
  const puestos = useSelector((state) => state.escuela.puestos);
  const dispatch = useDispatch();
  // const idClaveZona = zonas.idClaveZona;
  const [subZonas, setsubZonas] = useState([]);
  //! working
  const [userAccessSubZonas, setuserAccessSubZonas] = useState([])
const [userSubZonasPermited, setUserSubZonasPermited] = useState();

  const userData = sessionStorage.getItem("userAccessData");

const userAccessDataObj = JSON.parse(userData)
  console.log("datosProfesoresRG", datosProfesores);
  console.log("listaProfsConAsignaturas", listaProfsConAsignaturas);
  console.log('userAccessDataObj', userAccessDataObj)
  const {idClaveZona,escuela} = userAccessDataObj;
  const userIdClaveZonasCentroTrabajo = userAccessDataObj.idClaveZonaCentroTrabajo;
  console.log('userIdClaveZonasCentroTrabajo', userIdClaveZonasCentroTrabajo);
  const userIdClaveZonasCentroTrabajoLenght=!userIdClaveZonasCentroTrabajo ? 0 : userIdClaveZonasCentroTrabajo.split(',').length;
console.log('[...userIdClaveZonasCentroTrabajo]', [...userIdClaveZonasCentroTrabajo])
  console.log('userIdClaveZonasCentroTrabajoLenght', userIdClaveZonasCentroTrabajoLenght);
  const zonasCentroTrabajoArr = userIdClaveZonasCentroTrabajo.split(',');
  console.log('zonasCentroTrabajoArr', zonasCentroTrabajoArr)
  //! First useEffect
  useEffect(() => {
    props.init();
    if(idClaveZona === "0"){
      dispatch(getZonas());} else if (userIdClaveZonasCentroTrabajo.length === 1){
        // console.log('oneSchool')
        // console.log('userIdClaveZonasCentroTrabajoInsideuE', userIdClaveZonasCentroTrabajo,escuela)
        // handleSingleSubZona(userIdClaveZonasCentroTrabajo,escuela)

        
        dispatch(getSubZonas(idClaveZona)).then((subZonas) => {
          console.log("subZonas1", subZonas);
          const filteredData = subZonas.filter((item) =>
            zonasCentroTrabajoArr.includes(
              item.idClaveZonaCentroTrabajo.toString()
            )
          );
          handleSingleSubZona(
            filteredData[0].idClaveZonaCentroTrabajo,
            filteredData[0].numeroEscuela
          );
        });
      }else { dispatch(getSubZonas(idClaveZona)).then((subZonas) => {
        console.log('subZonas', subZonas)
        // const res =[...userIdClaveZonasCentroTrabajo].filter((zona)=> subZonas.includes(idClaveZonaCentroTrabajo === parseInt(zona) ))

        const filteredData = subZonas.filter(item => zonasCentroTrabajoArr.includes(item.idClaveZonaCentroTrabajo.toString()));



console.log('resultRG', filteredData)
setuserAccessSubZonas(filteredData); });}
        dispatch(getPuestos());
        setsubZonas([]);
        fetchNombramientoAsigsData();
        // setDatosProfesores([]);
        console.log("primer useEffect");
      }, []);


      //? Second useEffect
      useEffect(
        (puestos) => {
      console.log("segundo useEffect");
    },
    [datosProfesores]
    );
    console.log('subZonasRG', subZonas)
    console.log("puestos", puestos);
    
//? Third useEffect 
useEffect(() => {
  if (userIdClaveZonasCentroTrabajo.length === 1) {
    dispatch(getSubZonas(idClaveZona)).then((subZonas) => {
      console.log("subZonas1", subZonas);
      const filteredData = subZonas.filter((item) =>
        [...userIdClaveZonasCentroTrabajo].includes(
          item.idClaveZonaCentroTrabajo.toString()
        )
      );
      handleSingleSubZona(
        filteredData[0].idClaveZonaCentroTrabajo,
        filteredData[0].numeroEscuela
      );
    });
  }
  // setsubZonas([]);
  // setDatosProfesores([]);
}, [puestos]);




    // Define a function that fetches data from the web service
    async function fetchNombramientoAsigsData() {
      // Use fetch API to make a GET request to the web service URL
      const response = await fetch(
        "https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/getAsignaturaNombramiento"
        );
        
    // Parse the response as JSON
    const data = await response.json();
    setAsignaturasNombramiento(data)
    console.log('data', data)
  }
  



  const idLicenciaHandler = (e) => {
    setFiltersObj({ situacionLaboral: e });
  };
  const onSituacionLaboralChange = async (e) => {
    const { value } = e.target;
    //  || tipoLicencia;
    //getLicencias
    // situacionLaboral
    // :
    // "3,0"
    let licenseArr = _.filter(tipoLicencia, { descripcion: value.toString() });
    const licenseSelected = licenseArr[0].idTipoLicencia;
    settipoLicenciaSelect(licenseSelected);
    if (licenseSelected === 3) {
      setFiltersObj({ ...filtersObj, situacionLaboral: "3,0" });
    }
    const json = JSON.stringify([{ idTipoLicencia: licenseSelected }]);
    const menuGoce = await axios.get(
      `https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/getLicencias?json=${json}`
    );
    setGoceSueldo(menuGoce.data);
  };

  const handleMunicipioChange = (value) => {
    // const filtered = { ...filteredData, Municipio: value };
    setMuniValue(value);
    setFiltersObj({ ...filtersObj, municipio: value.toUpperCase() });
    // setReportData(filtered_array)
  };

  const onSexoChange = (e) => {
    const { value } = e.target;
    setSexoValue(value);
    setFiltersObj({ ...filtersObj, sexo: value });
  };

  const onPuestoChange = (value) => {
    setpuestoValue(value);
    setFiltersObj({ ...filtersObj, cargo: value });
  };

  const onMotivoChange = (value) => {
    setMotivoValue(value);
    const motivoObj = _.filter(motivos, { idMotivo: value });
    setFiltersObj({ ...filtersObj, motivo: motivoObj[0].idMotivo.toString() });
  };

  const handleResetFields = () => {
    setMuniValue(null);
    setSexoValue(null);
    // setMasculinoRadio(false)
    setpuestoValue(null);
    setMotivoValue(null);
    setFiltersObj({});
  };

 // Define the function that takes a number as an argument
const getClave=(number) =>{
  // Loop through the array of objects
  for (var i = 0; i < clavesCentroTrabajo.length; i++) {
      // Check if the idClave matches the number
      if (clavesCentroTrabajo[i].idClave === number) {
          // Return the value in the clave key
          return clavesCentroTrabajo[i].clave;
      }
  }
  // If no match is found, return null
  return null;
}
  const handleArrWithAsignaturas = (materias) => {
    console.log("materiasArr", materias);
    const turnos = ["1oMat", "2oMat", "3oMat", "1oVesp", "2oVesp", "3oVesp"];
    const misFilas = {
      1: {
        "1oMat": [null, null, null, null, null, null, null, null, null, null, null, null],
        "1oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
        "2oMat": [null, null, null, null, null, null, null, null, null, null, null, null],
        "2oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
        "3oMat":[null, null, null, null, null, null, null, null, null, null, null, null],
        "3oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
      },
      2: {
        "1oMat": [null, null, null, null, null, null, null, null, null, null, null, null],
        "1oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
        "2oMat": [null, null, null, null, null, null, null, null, null, null, null, null],
        "2oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
        "3oMat":[null, null, null, null, null, null, null, null, null, null, null, null],
        "3oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
      },
      3:  {
        "1oMat": [null, null, null, null, null, null, null, null, null, null, null, null],
        "1oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
        "2oMat": [null, null, null, null, null, null, null, null, null, null, null, null],
        "2oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
        "3oMat":[null, null, null, null, null, null, null, null, null, null, null, null],
        "3oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
      },
      4:  {
        "1oMat": [null, null, null, null, null, null, null, null, null, null, null, null],
        "1oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
        "2oMat": [null, null, null, null, null, null, null, null, null, null, null, null],
        "2oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
        "3oMat":[null, null, null, null, null, null, null, null, null, null, null, null],
        "3oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
      },
      5:  {
        "1oMat": [null, null, null, null, null, null, null, null, null, null, null, null],
        "1oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
        "2oMat": [null, null, null, null, null, null, null, null, null, null, null, null],
        "2oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
        "3oMat":[null, null, null, null, null, null, null, null, null, null, null, null],
        "3oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
      },
      1: {
        "1oMat": [null, null, null, null, null, null, null, null, null, null, null, null],
        "1oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
        "2oMat": [null, null, null, null, null, null, null, null, null, null, null, null],
        "2oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
        "3oMat":[null, null, null, null, null, null, null, null, null, null, null, null],
        "3oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
      },
      2: {
        "1oMat": [null, null, null, null, null, null, null, null, null, null, null, null],
        "1oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
        "2oMat": [null, null, null, null, null, null, null, null, null, null, null, null],
        "2oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
        "3oMat":[null, null, null, null, null, null, null, null, null, null, null, null],
        "3oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
      },
      3:  {
        "1oMat": [null, null, null, null, null, null, null, null, null, null, null, null],
        "1oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
        "2oMat": [null, null, null, null, null, null, null, null, null, null, null, null],
        "2oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
        "3oMat":[null, null, null, null, null, null, null, null, null, null, null, null],
        "3oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
      },
      4:  {
        "1oMat": [null, null, null, null, null, null, null, null, null, null, null, null],
        "1oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
        "2oMat": [null, null, null, null, null, null, null, null, null, null, null, null],
        "2oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
        "3oMat":[null, null, null, null, null, null, null, null, null, null, null, null],
        "3oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
      },
      5:  {
        "1oMat": [null, null, null, null, null, null, null, null, null, null, null, null],
        "1oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
        "2oMat": [null, null, null, null, null, null, null, null, null, null, null, null],
        "2oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
        "3oMat":[null, null, null, null, null, null, null, null, null, null, null, null],
        "3oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
      },
      6: {
        "1oMat": [null, null, null, null, null, null, null, null, null, null, null, null],
        "1oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
        "2oMat": [null, null, null, null, null, null, null, null, null, null, null, null],
        "2oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
        "3oMat":[null, null, null, null, null, null, null, null, null, null, null, null],
        "3oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
      },
      7: {
        "1oMat": [null, null, null, null, null, null, null, null, null, null, null, null],
        "1oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
        "2oMat": [null, null, null, null, null, null, null, null, null, null, null, null],
        "2oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
        "3oMat":[null, null, null, null, null, null, null, null, null, null, null, null],
        "3oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
      },
      8:  {
        "1oMat": [null, null, null, null, null, null, null, null, null, null, null, null],
        "1oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
        "2oMat": [null, null, null, null, null, null, null, null, null, null, null, null],
        "2oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
        "3oMat":[null, null, null, null, null, null, null, null, null, null, null, null],
        "3oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
      },
      9:  {
        "1oMat": [null, null, null, null, null, null, null, null, null, null, null, null],
        "1oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
        "2oMat": [null, null, null, null, null, null, null, null, null, null, null, null],
        "2oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
        "3oMat":[null, null, null, null, null, null, null, null, null, null, null, null],
        "3oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
      },
      10:  {
        "1oMat": [null, null, null, null, null, null, null, null, null, null, null, null],
        "1oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
        "2oMat": [null, null, null, null, null, null, null, null, null, null, null, null],
        "2oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
        "3oMat":[null, null, null, null, null, null, null, null, null, null, null, null],
        "3oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
      },
      11: {
        "1oMat": [null, null, null, null, null, null, null, null, null, null, null, null],
        "1oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
        "2oMat": [null, null, null, null, null, null, null, null, null, null, null, null],
        "2oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
        "3oMat":[null, null, null, null, null, null, null, null, null, null, null, null],
        "3oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
      },
      12: {
        "1oMat": [null, null, null, null, null, null, null, null, null, null, null, null],
        "1oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
        "2oMat": [null, null, null, null, null, null, null, null, null, null, null, null],
        "2oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
        "3oMat":[null, null, null, null, null, null, null, null, null, null, null, null],
        "3oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
      },
      13:  {
        "1oMat": [null, null, null, null, null, null, null, null, null, null, null, null],
        "1oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
        "2oMat": [null, null, null, null, null, null, null, null, null, null, null, null],
        "2oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
        "3oMat":[null, null, null, null, null, null, null, null, null, null, null, null],
        "3oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
      },
      14:  {
        "1oMat": [null, null, null, null, null, null, null, null, null, null, null, null],
        "1oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
        "2oMat": [null, null, null, null, null, null, null, null, null, null, null, null],
        "2oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
        "3oMat":[null, null, null, null, null, null, null, null, null, null, null, null],
        "3oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
      },
      15:  {
        "1oMat": [null, null, null, null, null, null, null, null, null, null, null, null],
        "1oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
        "2oMat": [null, null, null, null, null, null, null, null, null, null, null, null],
        "2oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
        "3oMat":[null, null, null, null, null, null, null, null, null, null, null, null],
        "3oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
      },
      16:  {
        "1oMat": [null, null, null, null, null, null, null, null, null, null, null, null],
        "1oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
        "2oMat": [null, null, null, null, null, null, null, null, null, null, null, null],
        "2oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
        "3oMat":[null, null, null, null, null, null, null, null, null, null, null, null],
        "3oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
      },
      17:  {
        "1oMat": [null, null, null, null, null, null, null, null, null, null, null, null],
        "1oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
        "2oMat": [null, null, null, null, null, null, null, null, null, null, null, null],
        "2oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
        "3oMat":[null, null, null, null, null, null, null, null, null, null, null, null],
        "3oVesp": [null, null, null, null, null, null, null, null, null, null, null, null],
      }, 
    };

    // const {materias} = maestrosData

    const resultado = materias.reduce((acc, nv, indx, arr) => {
      let asignaturaSelected = acc[nv.asignatura];
      console.log("asignaturaSelected", asignaturaSelected);
      _.forEach(turnos, (turno) => {
        if (nv[turno] && _.isArray(nv[turno])) {
          // solo entra si la materia tiene ese turno
          const indexes = nv[turno];
          const asignaturas = asignaturaSelected[turno];
          const idProf = nv.idProfesor;

          const asignaturaActualizada = asignaturas.map((a, i) =>
            indexes.includes(i + 1) ? idProf : a
          );
          asignaturaSelected = {
            ...asignaturaSelected,
            [turno]: asignaturaActualizada,
          };
        }
      });
      return { ...acc, [nv.asignatura]: asignaturaSelected };
    }, misFilas);

    console.log("resultado", resultado);
   setasignaturasIds(resultado)

  };
  //!Zonas
  const handleZonas = (idClaveZona) => {
    setsubZonas([]);
    setNewSelectValue(idClaveZona);
    setTimeout(() => {
      dispatch(getSubZonas(idClaveZona)).then((subZonas) => {
        setsubZonas(subZonas);
      });
    }, 50);
  };


  const puestosConAsigs = puestos.filter(
    (puesto) => puesto.llevaAsignaturas === 1
  );

  const profsSinHorasArr = [7,8,14,15];
  console.log("puestosConAsigs", puestosConAsigs);
  const idPuestos = puestosConAsigs.map((puesto) => puesto.idPuesto);
  console.log("idPuestosFuera", idPuestos);
console.log('datosProfesores', datosProfesores)
//! sub Zonas
  const handleSubZonas = async (idClaveZonaCentroTrabajo, b) => {
    console.log("soy subzona", idClaveZonaCentroTrabajo, b);
    setclaveCentroTrabajoSelected(getClave(b.numeroEscuela));
    
    setIdClaveZonaCentroTrabajo(idClaveZonaCentroTrabajo);
    setNumeroEscuela(b.numeroEscuela);
    setDatosProfesores([]);
    const datosProfesores = await dispatch(
      getProfesoresByZonasCentroTrabajo(idClaveZonaCentroTrabajo)
    );
    console.log("datosProfesores", datosProfesores);
    const profsSinhoras = datosProfesores.filter(
      (datoProfesor) => profsSinHorasArr.includes(datoProfesor.cargo)
    );

    setProfsSinHorasResult(profsSinhoras);
    console.log('profsSinHorasResult', profsSinhoras)
    const profsConAsigsArr = datosProfesores.filter(
      (datoProfesor) => idPuestos.includes(datoProfesor.cargo)
      // console.log('datoProfesor', datoProfesor)
      // console.log('puestos', puestos)
    );
    setprofsConAsigsArr(profsConAsigsArr);
    
    const result = profsConAsigsArr.map(async (profConAsigsObj) => {
      console.log("profConAsigs", profConAsigsObj);
      const { idProfesor } = profConAsigsObj;

      return await axios.get(
        `https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/getAsignaturaProfesoresByIdProfesor?json=[{"idProfesor":${idProfesor}}]`
      );
    });
console.log('profsConAsigsArr', profsConAsigsArr)
    const resultado = Promise.all(result).then((asignaturas) => {
      console.log('asignaturasInPromise',asignaturas);
      let materiasObjs = [];

      const promise = new Promise((resolve, reject) => {
        asignaturas.forEach((asignatura, index, array) => {
          let parsedMaterias = JSON.parse(asignatura.data.materias);
          if (parsedMaterias.length >= 1) {

for (let i = 0; i < parsedMaterias.length; i++) {
  const idProfesor = asignatura.data.idProfesor
  const newAsignaturasObj ={ ...parsedMaterias[i], idProfesor};
  console.log("newAsignaturasObj", newAsignaturasObj);
  materiasObjs.push(newAsignaturasObj);
}

           
          }

          if (index === array.length - 1) {
            resolve();
          }
        });
      });
      console.log("materiasObjs", materiasObjs);
      return materiasObjs;
    });

    console.log("algo", resultado);
    Promise.resolve(resultado).then((materiaIds) => {
      console.log("materiaIds", materiaIds);
      handleArrWithAsignaturas(materiaIds);
    });
  };
//! Single SubZona
  const handleSingleSubZona = async (idClaveZonaCentroTrabajo, numeroEscuela) => {
    console.log('idPuestosDentroSubzona', idPuestos)
    console.log("soy subzonaSingle", idClaveZonaCentroTrabajo, numeroEscuela);
    setclaveCentroTrabajoSelected(getClave(numeroEscuela));
    
    setIdClaveZonaCentroTrabajo(idClaveZonaCentroTrabajo);
    setNumeroEscuela(numeroEscuela);
    setDatosProfesores([]);
    const datosProfesores = await dispatch(
      getProfesoresByZonasCentroTrabajo(idClaveZonaCentroTrabajo)
    );
    console.log("datosProfesoresSingle", datosProfesores);
    const profsSinhoras = datosProfesores.filter(
      (datoProfesor) => profsSinHorasArr.includes(datoProfesor.cargo)
    );
    console.log('profsSinhorasSingle', profsSinhoras)
console.log('datosProfesoresSingle', datosProfesores)
    setProfsSinHorasResult(profsSinhoras);
    console.log('profsSinHorasResultSingle', profsSinhoras);
    console.log('idPuestosSingle', idPuestos);
    
    const profsConAsigsArr = datosProfesores.filter(
      (datoProfesor) => idPuestos.includes(datoProfesor.cargo)
      // console.log('datoProfesor', datoProfesor)
      // console.log('puestos', puestos)
    );
    // setprofsConAsigsArr(profsConAsigsArr); //! volver a poner 
console.log('profsConAsigsArrSingle', profsConAsigsArr)
    const result = profsConAsigsArr.map(async (profConAsigsObj) => {
      console.log("profConAsigsSingle", profConAsigsObj);
      const { idProfesor } = profConAsigsObj;

      return await axios.get(
        `https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/getAsignaturaProfesoresByIdProfesor?json=[{"idProfesor":${idProfesor}}]`
      );
    });
console.log('profsConAsigsArrSingle', profsConAsigsArr)
    const resultado = Promise.all(result).then((asignaturas) => {
      console.log('asignaturasInPromise',asignaturas);
      let materiasObjs = [];

      const promise = new Promise((resolve, reject) => {
        asignaturas.forEach((asignatura, index, array) => {
          let parsedMaterias = JSON.parse(asignatura.data.materias);
          if (parsedMaterias.length >= 1) {

for (let i = 0; i < parsedMaterias.length; i++) {
  const idProfesor = asignatura.data.idProfesor
  const newAsignaturasObj ={ ...parsedMaterias[i], idProfesor};
  console.log("newAsignaturasObjSingle", newAsignaturasObj);
  materiasObjs.push(newAsignaturasObj);
}

           
          }

          if (index === array.length - 1) {
            resolve();
          }
        });
      });
      console.log("materiasObjs", materiasObjs);
      return materiasObjs;
    });

    console.log("algoSingle", resultado);
    Promise.resolve(resultado).then((materiaIds) => {
      console.log("materiaIdsSingle", materiaIds);
      handleArrWithAsignaturas(materiaIds);
    });
  };

  return (
    <>
      {/* <Form> */}
        {" "}
        <Layout>
          {/* <Header>    */}
          <Divider>BÚSQUEDA</Divider>
          {/* <Space 
          size={16} 
    direction="horizontal"
    // size='large'
    style={{
      display: 'flex',
    }}
    align='center'
  > */}

          {/* <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={['4']}
       
        /> */}

          
          <Form>
            <Row>{idClaveZona === '0' ?(<>
              <Col flex="170px"> 
                <Form.Item label="Elige zona">
                  <Select onChange={handleZonas} value={newSelectValue}>
                    {zonas.map(({ idClaveZona }) => {
                      return (
                        <Select.Option key={idClaveZona} value={idClaveZona}>
                          Zona {idClaveZona}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col flex="170px">
                {!!subZonas.length > 0 && (
                  <Form.Item label="Escuela">
                    <Select onChange={handleSubZonas}>
                      {subZonas.map(
                        ({
                          idClaveZonaCentroTrabajo,
                          numeroEscuela,
                          etiqueta,
                        }) => (
                            <Select.Option
                              key={idClaveZonaCentroTrabajo}
                              value={idClaveZonaCentroTrabajo}
                              numeroEscuela={numeroEscuela}
                            >
                              {etiqueta}
                            </Select.Option>
                          )
                      )}
                    </Select>
                  </Form.Item>
                )}
              </Col></>):null}
             
              <Col flex="170px">
                {!!userAccessSubZonas.length > 0 && (
                  <Form.Item label="Escuela">
                    <Select onChange={handleSubZonas}>
                      {userAccessSubZonas.map(
                        ({
                          idClaveZonaCentroTrabajo,
                          numeroEscuela,
                          etiqueta,
                        }) => (
                            <Select.Option
                              key={idClaveZonaCentroTrabajo}
                              value={idClaveZonaCentroTrabajo}
                              numeroEscuela={numeroEscuela}
                            >
                              {etiqueta}
                            </Select.Option>
                          )
                      )}
                    </Select>
                  </Form.Item>
                )}
              </Col>
            </Row>
          </Form>
          <Row>
            <Col></Col>
          </Row>
          <Layout>

            <RepGenTablaDistDoc datosProfesores={datosProfesores} asignaturasIds={asignaturasIds} materiasMap={materiasMap} numeroEscuela={numeroEscuela} profsConAsigsArr={profsConAsigsArr} claveCentroTrabajoSelected={claveCentroTrabajoSelected} profsSinHorasResult={profsSinHorasResult} puestos={puestos} profsSinHorasArr={profsSinHorasArr}/>
            <Content>
              {/* <RGFSeleccionados filteredDataObj={filteredDataObj} handleResetFields={handleResetFields} /> */}
              {/* <RGResultado
                filteredDataArr={filteredDataArr}
                materiasMap={materiasMap}
                gruposMap={gruposMap}
                spin={spin}
              /> */}
            </Content>
          </Layout>
        </Layout>
      {/* </Form> */}
    </>
  );
};

export default ReporteGeneral;
