export const SET_USER_PROFILE = 'SET_USER_PROFILE';

export const SET_MATERIAS = 'SET_MATERIAS';

export const SET_PUESTOS = 'SET_PUESTOS';

export const SET_ASIGNATURA_NOMBRAMIENTO = 'SET_ASIGNATURA_NOMBRAMIENTO';

export const SET_ARTES = 'SET_ARTES';

export const SET_TALLERES = 'SET_TALLERES';

export const SET_TURNOS_LIST = 'SET_TURNOS_LIST';

export const SET_TURNOS = 'SET_TURNOS';

export const SET_GRUPOS = 'SET_GRUPOS';

export const SET_MOTIVOS = 'SET_MOTIVOS';

export const SET_NUMERO_ESCUELA = 'SET_NUMERO_ESCUELA';

export const SET_CARRERA_MAGISTERIAL = 'SET_CARRERA_MAGISTERIAL';

export const SET_HORAS = 'SET_HORAS';

export const SET_TIPO_LICENCIA = 'SET_TIPO_LICENCIA';

export const SET_ESTADO_CIVIL = 'SET_ESTADO_CIVIL';

export const SET_CATEGORIAS = 'SET_CATEGORIAS';

export const SET_CLAVESCENTROTRABAJO = 'SET_CLAVESCENTROTRABAJO';

export const SET_ZONAS = 'SET_ZONAS';

export const SET_SUB_ZONAS = 'SET_SUB_ZONAS';

export const SET_ = 'SET_';

export const SET_TITULO = 'SET_TITULO';

export const SET_FORTE = 'SET_FORTE';
export const SET_ASIGNATURA_HORAS = 'SET_ASIGNATURA_HORAS';
export const SET_QUINQUENIO = 'SET_QUINQUENIO';
export const SET_INCENTIVO = 'SET_INCENTIVO';
export const GET_IDENTIFICACION_CENTRO_TRABAJO = "GET_IDENTIFICACION_CENTRO_TRABAJO";





