import AltaPersonal from "../../containers/altaContainer";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { procesaDatosProfesor } from "./Actualizaciones";

const ListaImprimir = (props) => {
  const [listaImprimir, setlistaImprimir] = useState([]);
  useEffect(() => {
    (async () => {


      for (let index = 0; index < props.datosProfesores.length; index++) {
        const { data } = await axios.get(
          `https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/getAsignaturaProfesoresByIdProfesor?json=[{"idProfesor":${props.datosProfesores[index].idProfesor}}]`
        );
        const newAltaPersonal = (
          <div
            className="alta-personal-imprimir"
          >

            <AltaPersonal
              datosProfesor={procesaDatosProfesor(props.datosProfesores[index])}
              path={props.path}
              asignaturas={data}
              pageNum={index + 1}
            />
          </div>
        );
        setlistaImprimir((prev) => [...prev, newAltaPersonal]);
      }
      props.setisLoading(false);
      props.setIsImpresionButtonGrayedOut(false);
    })();
  }, [props.datosProfesores]);
  //props,datosProfesores fue agregado en este stash
  return listaImprimir;
};

export default ListaImprimir;
