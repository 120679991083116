import moment from 'moment';

const fechas = ['fecha', 'ingresoCentroTrabajo', 'ingresoSep', 'ingresoTecnicas'];

export const validateFechas = (data) => {
  const resp = Object.keys(data).reduce((a, nv) => {

    return { ...a, [nv]: fechas.includes(nv) ? moment(data[nv], 'DD/MM/YYYY') : data[nv] }
  }, {});
  return resp;
}
export const validateFechas2 = (data) => {
  const resp = Object.keys(data).reduce((a, nv) => {


    return { ...a, [nv]: fechas.includes(nv) ? moment(data[nv]).format('DD/MM/YYYY') : data[nv] }
  }, {});
  return resp;
}

