import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

import { Form, Input, Button, Row, Column } from "antd";
const BusquedaMenu = (props) => {
  const { setDatosBusqueda } = props;

  
  const userData = sessionStorage.getItem("userAccessData");

const userAccessDataObj = JSON.parse(userData)
  // console.log("datosProfesoresRG", datosProfesores);
  // console.log("listaProfsConAsignaturas", listaProfsConAsignaturas);
  // console.log('userAccessDataRG', userAccessDataObj)
  // const {idClaveZona,escuela} = userAccessDataObj;
   const userIdClaveZonasCentroTrabajo = userAccessDataObj.idClaveZonaCentroTrabajo;
  // console.log('userIdClaveZonasCentroTrabajo', userIdClaveZonasCentroTrabajo);
  // const userIdClaveZonasCentroTrabajo = "1";

  const [form] = Form.useForm();

  const notify = (info) => toast.dark(info);

  const onBusquedaFinish = (e) => {
    const maestro = form.getFieldsValue();
    console.log('maestro', maestro);
    maestro.idClaveZonaCentroTrabajo=userIdClaveZonasCentroTrabajo;
    const maestroArray = [];
    maestroArray.push(maestro);
    const maestroJson = JSON.stringify(maestroArray);
//  ,"idClaveZonaCentroTrabajo":"1"
console.log('maestroJson', maestroJson)
    const options = {
      method: "GET",
      url: "https://servicios.plantillasdestmorelos.gob.mx/PlantillaPersonalWS/webresources/Maestros/getProfesoresByNombreApellido",
      params: {
        json: maestroJson,
      },
    };

    axios
      .request(options)
      .then(function (response) {
        response.data.length >= 1 ?
          setDatosBusqueda(response.data) :
          notify("Registro no encontrado, verifica los datos")

      })
      .catch(function (error) {
        console.error('error', error);
      });


  };

  const validateMessages = {
    required: '${label} es obligatorio!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!'
    }
  }
  return (
    <>
      <Form form={form} onFinish={onBusquedaFinish} validateMessages={validateMessages}>
        <Row>
          <Form.Item
            label="Nombre(s)"
            name="nombre"
            rules={[
              { required: true },
              { type: "string", warningOnly: false },
              // { type: "string", min: 6 },
            ]}
          >
            <Input placeholder="Nombre(s)"
              //  onChange={handleWordInput}
              regExpType='alphabet' />
          </Form.Item>
          <Form.Item
            label="Apellido Paterno"
            name="apellidoPaterno"
            rules={[
              { required: true },
              { type: "string", warningOnly: true }
              // { type: "string", min: 6 },
            ]}
          >
            <Input placeholder="Apellido Materno"
              //  onChange={handleWordInput}
              regExpType='alphabet' />
          </Form.Item>
          <Form.Item
            label="Apellido Materno"
            name="apellidoMaterno"
            rules={[
              { required: true },
              { type: "string", warningOnly: true },
              // { type: "string", min: 6 },
            ]}
          >
            <Input placeholder="Apellido Paterno"
              //  onChange={handleWordInput}
              regExpType='alphabet' />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Buscar Profesor
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </>
  );
};

export default BusquedaMenu;
