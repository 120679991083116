import React from 'react'
import { Select, Row, Col } from 'antd'
// import Form from 'antd/lib/form/Form'
import { CheckOutlined } from '@ant-design/icons';


const Asignaturas = (props) => {
  const { materias, asignatura, handleSetAsignaturas, matutinoOptions, isAsignaturasButtonDisable, agregaEditaAsignatura, datosProfesor, Form, materiasIdsAgregadas } = props;

  const { primerGrado, segundoGrado, tercerGrado } = materias.find(materia => materia.idMateria === asignatura.asignatura) || {};
  return (
    <Row justify="space-between" align="middle">
      <Col span={6}>
        <Form.Item
          label="Asignatura"
        >
          <Select placeholder="Selecciona Asignatura" value={asignatura.asignatura} onChange={(a, b) => handleSetAsignaturas('asignatura', a)} >
            {materias.map(({ idMateria, nombre }) => <Select.Option key={idMateria} disabled={materiasIdsAgregadas.includes(idMateria)} value={idMateria}>{nombre}</Select.Option>)}
          </Select>
        </Form.Item>
      </Col>
      <Col span={17}>
        <Row justify="space-between" >
          <Col span={7}>
            <Form.Item
              label="1o Mat"
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Selecciona Grupo/s"
                value={asignatura['1oMat']}
                onChange={(a) => handleSetAsignaturas('1oMat', a)}
                disabled={!primerGrado}
              >
                {matutinoOptions('1oMat')}
              </Select>
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              label="2o Mat"
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Selecciona Grupo/s"
                value={asignatura['2oMat']}
                onChange={(a) => handleSetAsignaturas('2oMat', a)}
                disabled={!segundoGrado}
              >
                {matutinoOptions('2oMat')}
              </Select>
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              label="3o Mat"
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Selecciona Grupo/s"
                value={asignatura['3oMat']}
                onChange={(a) => handleSetAsignaturas('3oMat', a)}
                disabled={!tercerGrado}

              >
                {matutinoOptions('3oMat')}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col span={7}>
            <Form.Item
              label="1o Vesp"
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Selecciona Grupo/s"
                value={asignatura['1oVesp']}
                onChange={(a) => handleSetAsignaturas('1oVesp', a)}
                disabled={!primerGrado}
              >
                {matutinoOptions('1oVesp')}
              </Select>
            </Form.Item>
          </Col>

          <Col span={7}>
            <Form.Item
              label="2o Vesp"
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Selecciona Grupo/s"
                value={asignatura['2oVesp']}
                onChange={(a, b) => handleSetAsignaturas('2oVesp', a)}
                disabled={!segundoGrado}
              >
                {matutinoOptions('2oVesp')}
              </Select>
            </Form.Item>
          </Col>

          <Col span={7}>
            <Form.Item
              label="3o Vesp"
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Selecciona Grupo/s"
                value={asignatura['3oVesp']}
                onChange={(a) => handleSetAsignaturas('3oVesp', a)}
                disabled={!tercerGrado}
              >
                {matutinoOptions('3oVesp')}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Col>
      <Col span={1}>

        <span className={isAsignaturasButtonDisable ? 'iconDisable' : ' iconEnable'} onClick={isAsignaturasButtonDisable ? () => { } : agregaEditaAsignatura} >
          {datosProfesor?.idProfesor ? <CheckOutlined /> : '+'}
        </span>

      </Col>
    </Row>
  )
}

export default Asignaturas;

